import React from "react";
import { isNonEmptyArray } from "../../../../../../utils";
import CustomAccordion from "../../../../../accordion/CustomAccordion";
import styles from "./DeliverablesPhasesList.module.css";
import { DeliverablesList } from "..";
import { CustomTooltip } from "../../../../../tooltip";

const DeliverablesPhasesList = ({ hasFilters, onChange, onExpandPhase, phases, readOnly, selection }) =>
	isNonEmptyArray(phases) &&
	[...phases].map((phase) => {
		const isExpanded = phase?.deliverablesCount > 0 || phase?.deliverables?.length > 0;
		return (
			<CustomAccordion
				key={phase.phaseId}
				className={styles.accordionContent}
				defaultOpen={hasFilters || phase.defaultExpanded}
				header={
					<CustomTooltip showWhenOverflow title={phase?.phaseName}>
						<div className={styles.line}>{phase?.phaseName}</div>
					</CustomTooltip>
				}
				iconColor="var(--color-light-grey-1)"
				onExpand={() => onExpandPhase({ expandablePhase: phase })}
			>
				{(isExpanded && (
					<DeliverablesList
						deliverables={phase.deliverables}
						readOnly={readOnly}
						selection={selection}
						onChange={onChange}
					/>
				)) ||
					null}
			</CustomAccordion>
		);
	});

export default DeliverablesPhasesList;
