import React, { useReducer, useMemo, useRef, useEffect, useState } from "react";
import {
	CustomButton,
	CustomTooltip,
	I18nTranslate,
	icon,
	IconComponent,
} from "../../../../../../../../../common/components";
import { POSITIONS_STATUSES } from "../../../../../../../../../common/constants/positions-statuses";
import {
	translate,
	translateDate,
	translateElapsed,
	translateEnumParam,
	translateEnumStatus,
} from "../../../../../../../../../common/providers";
import { SENTENCE_TYPE } from "../../../../../../../constants/constants";
import { HISTORY_ITEM_TYPE } from "../../constants/constants";
import styles from "./HistoryRow.module.css";
import CommentHighlightedText from "../../../../../../../../../common/components/comment-highlighted-text/CommentHighlightedText";
import { linkConversion, translateEnumLinks } from "../../../../../../../utils/utils";

const STATUS_ICONS = {
	[POSITIONS_STATUSES.YES]: icon.faCheckCircle,
	[POSITIONS_STATUSES.NO]: icon.faTimesCircle,
	[POSITIONS_STATUSES.PENDING]: icon.faClock,
	[POSITIONS_STATUSES.NOT_ME]: icon.faNotEqual,
	[POSITIONS_STATUSES.UNREVIEWED]: icon.faHourglassHalf,
};

const ICONS_COLORS = {
	[POSITIONS_STATUSES.YES]: "var(--color-green)",
	[POSITIONS_STATUSES.NO]: "var(--color-red)",
	[POSITIONS_STATUSES.PENDING]: "var(--color-orange)",
	[POSITIONS_STATUSES.NOT_ME]: "var(--color-black)",
	[POSITIONS_STATUSES.UNREVIEWED]: "var(--color-light-grey-3)",
};

const HistoryRow = ({ history, informationId, isRequirement }) => {
	const [showAll, setShowAll] = useReducer((prev) => !prev, false);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const commentContentRef = useRef();
	const contentIdentifierText = useMemo(() => {
		if (
			history.fieldChanged === HISTORY_ITEM_TYPE.COMMENT ||
			history.fieldChanged === HISTORY_ITEM_TYPE.REV_COMMENT ||
			history.fieldChanged === HISTORY_ITEM_TYPE.VERSION_STATUS ||
			history.fieldChanged === HISTORY_ITEM_TYPE.CATEGORY ||
			history.fieldChanged === HISTORY_ITEM_TYPE.LINK
		) {
			return `smart-review.cmp-details.option-panel.history.row.${history.fieldChanged
				.toLowerCase()
				.replaceAll("_", "-")}.${history.actionTaken.toLowerCase()}`;
		}

		return `smart-review.cmp-details.option-panel.history.row.${history.fieldChanged
			.toLowerCase()
			.replaceAll("_", "-")}`;
	}, [history.fieldChanged, history.actionTaken]);
	const contentIdentifierIcon = useMemo(() => {
		switch (history.fieldChanged) {
			case HISTORY_ITEM_TYPE.REQ_STATUS:
				return icon.faCheck;
			case HISTORY_ITEM_TYPE.REV_STATUS:
			case HISTORY_ITEM_TYPE.REV_COMMENT:
				return icon.faCheckDouble;
			case HISTORY_ITEM_TYPE.COMMENT:
				return icon.faComments;
			case HISTORY_ITEM_TYPE.CATEGORY:
				return icon.faTag;
			case HISTORY_ITEM_TYPE.VERSION_STATUS:
				return icon.faFileSignature;
			case HISTORY_ITEM_TYPE.CONTENT_TYPE:
				return icon.faFlag;
			case HISTORY_ITEM_TYPE.NEGOTIABILITY:
				return icon.faHandsHelping;
			case HISTORY_ITEM_TYPE.CRITICALITY:
				return icon.faExclamationTriangle;
			case HISTORY_ITEM_TYPE.TYPE:
				return icon.faLayerGroup;
			case HISTORY_ITEM_TYPE.LINK:
				return icon.faLink;
			default:
				return icon.faCheck;
		}
	}, [history.fieldChanged]);
	const handleIsOverFlowing = () => {
		setIsOverflowing(commentContentRef.current?.clientHeight < commentContentRef.current?.scrollHeight);
	};
	useEffect(() => {
		handleIsOverFlowing();
	}, []);
	useEffect(() => {
		window.addEventListener("resize", handleIsOverFlowing);
		return () => {
			window.removeEventListener("resize", handleIsOverFlowing);
		};
	}, []);
	const getVersionStatusIcon = (content) => {
		if (content === "MODIFIED_MINOR") {
			return <IconComponent color="var(--color-keppel)" icon={icon.faExclamationTriangle} size="xs" />;
		}
		if (content === "MODIFIED") {
			return <IconComponent color="var(--color-red)" icon={icon.faExclamationTriangle} size="xs" />;
		}
		if (content === "NEW") {
			return <IconComponent color="var(--color-blue)" icon={icon.faPlusCircle} size="xs" />;
		}
		if (content === "IDENTICAL") {
			return <IconComponent color="var(--color-dark-grey-1)" icon={icon.faEquals} size="xs" />;
		}
		return <IconComponent color="var(--color-red)" icon={icon.faExclamationTriangle} size="xs" />;
	};
	const getContent = (field, newContent, oldContent) => {
		switch (field) {
			case HISTORY_ITEM_TYPE.REQ_STATUS:
			case HISTORY_ITEM_TYPE.REV_STATUS:
				return (
					<div className={styles.historyRow__iconContent}>
						<IconComponent color={ICONS_COLORS[newContent]} icon={STATUS_ICONS[newContent]} size="xs" />
						<span className={styles.historyRow__statusIconText}>
							{translateEnumStatus(newContent?.toLowerCase().replaceAll("_", "-"))}{" "}
						</span>
					</div>
				);
			case HISTORY_ITEM_TYPE.COMMENT:
			case HISTORY_ITEM_TYPE.REV_COMMENT:
				return (
					<div>
						<div ref={commentContentRef} className={showAll ? "" : styles.historyRow__commentContainer}>
							{newContent.split("\n").map((com) => (
								<CommentHighlightedText
									key={com}
									textClassName={styles.historyRow__commentLine}
									value={com}
								/>
							))}
						</div>
						{isOverflowing && (
							<CustomButton className={styles.historyRow__showMoreBtn} size="sm" onClick={setShowAll}>
								{showAll ? translate("common:btn.show-less") : translate("common:btn.show-more")}
							</CustomButton>
						)}
					</div>
				);
			case HISTORY_ITEM_TYPE.TYPE:
				return (
					<div className={styles.historyRow__iconContent}>
						{oldContent ? translateEnumParam("type", oldContent) : translate("common:empty-state.none")}
						<IconComponent
							className={styles.historyRow__newOldIcon}
							color="var(--color-dark-grey-1)"
							icon={icon.faArrowRight}
							size="xs"
						/>
						{newContent ? translateEnumParam("type", newContent) : translate("common:empty-state.none")}
					</div>
				);
			case HISTORY_ITEM_TYPE.CRITICALITY:
				return (
					<div className={styles.historyRow__iconContent}>
						{oldContent
							? translateEnumParam("criticality", oldContent)
							: translate("common:empty-state.none")}
						<IconComponent
							className={styles.historyRow__newOldIcon}
							color="var(--color-dark-grey-1)"
							icon={icon.faArrowRight}
							size="xs"
						/>
						{newContent
							? translateEnumParam("criticality", newContent)
							: translate("common:empty-state.none")}
					</div>
				);
			case HISTORY_ITEM_TYPE.NEGOTIABILITY:
				return (
					<div className={styles.historyRow__iconContent}>
						{oldContent
							? translateEnumParam("negotiability", oldContent)
							: translate("common:empty-state.none")}
						<IconComponent
							className={styles.historyRow__newOldIcon}
							color="var(--color-dark-grey-1)"
							icon={icon.faArrowRight}
							size="xs"
						/>
						{newContent
							? translateEnumParam("negotiability", newContent)
							: translate("common:empty-state.none")}
					</div>
				);
			case HISTORY_ITEM_TYPE.CONTENT_TYPE:
				return (
					<div className={styles.historyRow__iconContent}>
						<IconComponent
							className={styles.historyRow__contentTypeIcon}
							color={
								newContent === SENTENCE_TYPE.REQUIREMENT
									? "var(--color-dark-grey-1)"
									: "var(--color-red)"
							}
							icon={newContent === SENTENCE_TYPE.REQUIREMENT ? icon.faBarsStaggered : icon.faStarOfLife}
							size="xs"
						/>
						{newContent === SENTENCE_TYPE.INFORMATION
							? translate("common:enum.filters.content-type.requirement")
							: translate("common:enum.filters.content-type.information")}
						<IconComponent
							className={styles.historyRow__newOldIcon}
							color="var(--color-dark-grey-1)"
							icon={icon.faArrowRight}
							size="xs"
						/>
						<IconComponent
							className={styles.historyRow__contentTypeIcon}
							color={
								newContent === SENTENCE_TYPE.INFORMATION
									? "var(--color-dark-grey-1)"
									: "var(--color-red)"
							}
							icon={newContent === SENTENCE_TYPE.INFORMATION ? icon.faBarsStaggered : icon.faStarOfLife}
							size="xs"
						/>
						{translate(`common:enum.filters.content-type.${newContent.toLowerCase()}`)}
					</div>
				);
			case HISTORY_ITEM_TYPE.VERSION_STATUS:
				return (
					<div className={styles.historyRow__iconContent}>
						{getVersionStatusIcon(newContent)}
						<span className={styles.historyRow__statusIconText}>
							{translate(
								`smart-review.cmp-details.option-panel.history.row.version.${newContent
									.toLowerCase()
									.replaceAll("_", "-")}`
							)}
						</span>
					</div>
				);
			case HISTORY_ITEM_TYPE.CATEGORY:
				return <div className={styles.historyRow__iconContent}>{newContent}</div>;
			case HISTORY_ITEM_TYPE.LINK:
				return (
					<div className={styles.historyRow__iconContent}>
						{(isRequirement && "REQ_") || "INFO_"}
						{informationId}
						<IconComponent
							className={`${styles.historyRow__newOldIcon} ${styles["historyRow__newOldIcon--margin-top"]}`}
							color={linkConversion(history.linkType)?.color}
							icon={icon.faLink}
							size="2xs"
						/>
						<span className={styles["historyRow__text--bold"]}>
							{translateEnumLinks(history.linkType).toLowerCase().replaceAll(".", "")}
						</span>
						&nbsp;
						{(history.newContentType && "REQ_") || "INFO_"}
						{newContent}
					</div>
				);
			default:
				return null;
		}
	};
	return (
		<div className={styles.historyRow__container}>
			<div className={styles.historyRow__header}>
				<div className={styles.historyRow__identifierContainer}>
					<IconComponent
						className={styles.historyRow__identifierIcon}
						color="var(--color-dark-grey-1)"
						icon={contentIdentifierIcon}
						size="xs"
					/>
					<div className={styles.historyRow__identifier}>
						<b>{history.username || translate("common:company-name.aitender")} </b>
						<span className={styles.historyRow__identifierText}>
							<I18nTranslate
								param={{
									companyName: history.companyName,
									className: styles["historyRow__identifierText--companyName"],
								}}
								translationKey={contentIdentifierText}
							/>
						</span>
					</div>
				</div>
				<CustomTooltip title={translateDate(history.date)}>
					<div className={styles.historyRow__timePassed}>
						{translateElapsed({ value: history.elapsed.value, unitKey: history.elapsed.unit })}
					</div>
				</CustomTooltip>
			</div>
			<div>{getContent(history.fieldChanged, history.newContent, history.oldContent)}</div>
		</div>
	);
};

export default HistoryRow;
