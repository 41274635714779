import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { translate } from "../../../../common/providers";
import { BlockTitle, CustomButton } from "../../../../common/components";
import AdminCompanyTable from "./admin-company-table/AdminCompanyTable";
import { COMPANY_TYPES } from "../../../my-review/constants/constants";

export default function AdminCompany({ companies = [], onCreate }) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [payload, setPayload] = useState({ shortName: "", name: "" });
	const [errors, setErrors] = useState({ shortName: false, name: false });
	const loggedIncompanyId = useSelector(({ context }) => context.company?.id);

	const handleSelectCompany = useCallback(
		(company) => {
			if (!selectedCompany || company?.id !== selectedCompany?.id) {
				setSelectedCompany(company);
			}
		},
		[selectedCompany]
	);

	const findCompanyWithAdminUserRole = useCallback(
		(dataTree) => {
			for (const company of dataTree) {
				if (company.userRole === "ADMIN" && company.id === loggedIncompanyId) {
					return company;
				}
				if (company.children) {
					const innerCompanyId = findCompanyWithAdminUserRole(company.children);
					if (innerCompanyId) {
						return innerCompanyId;
					}
				}
			}
			return null;
		},
		[loggedIncompanyId]
	);

	useEffect(() => {
		if (!selectedCompany && Array.isArray(companies) && companies.length > 0) {
			handleSelectCompany(findCompanyWithAdminUserRole(companies));
		}
	}, [selectedCompany, companies, handleSelectCompany, findCompanyWithAdminUserRole]);
	const reset = () => {
		setPayload({ shortName: "", name: "" });
		setErrors({ shortName: false, name: false });
	};

	const handleChangeCompany = (company) => {
		if (company !== selectedCompany) {
			setSelectedCompany(company);
			reset();
		}
	};

	const onChange = (value, field) => {
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	const handleSubmit = () => {
		const errs = {
			name: !payload.name,
			shortName: !payload.shortName,
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (!hasError && selectedCompany) {
			onCreate({ company: selectedCompany, payload });
			reset();
		}
		setErrors(errs);
	};
	return (
		<>
			<div
				style={{
					width: `${selectedCompany ? "50%" : "100%"}`,
					borderRight: "1px solid var(--color-light-grey-3)",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<BlockTitle>{translate("admin.admin-company.title")}</BlockTitle>
				<div style={{ overflowY: "auto", height: "inherit" }}>
					<AdminCompanyTable
						companies={companies}
						selectedCompany={selectedCompany}
						onSelectRow={handleChangeCompany}
					/>
				</div>
			</div>
			{selectedCompany ? (
				<div style={{ width: "50%", borderLeft: "1px solid var(--color-light-grey-3)" }}>
					<BlockTitle>{translate("admin.admin-company.titleBu")}</BlockTitle>
					<div style={{ display: "flex", flexDirection: "column", margin: "16px" }}>
						<span
							style={{
								textAlign: "center",
								fontStyle: "italic",
								margin: "16px 0",
								fontSize: "var(--font-size-sm)",
							}}
						>
							{translate("admin.admin-company.block-content")}
						</span>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								flexGrow: 1,
								margin: "16px auto",
								width: "80%",
							}}
						>
							<TextField
								required
								disabled={selectedCompany.type === COMPANY_TYPES.CONSORTIUM}
								error={errors && errors.shortName}
								helperText={errors && errors.shortName && translate("common:error.required")}
								label={translate("admin.admin-company.th.shortname")}
								translate="no"
								value={payload.shortName || ""}
								variant="outlined"
								onChange={(e) => onChange(e.target.value, "shortName")}
							/>
							<span style={{ margin: "8px" }} />
							<TextField
								required
								disabled={selectedCompany.type === COMPANY_TYPES.CONSORTIUM}
								error={errors && errors.name}
								helperText={errors && errors.name && translate("common:error.required")}
								label={translate("admin.admin-company.th.name")}
								value={payload.name || ""}
								variant="outlined"
								onChange={(e) => onChange(e.target.value, "name")}
							/>
							<span
								style={{
									fontSize: "var(--font-size-sm)",
									padding: "10px",
									fontStyle: "italic",
									textAlign: "end",
								}}
								translate="no"
							>
								{translate("admin.admin-company.under", { company: selectedCompany.companyName })}
							</span>
							<CustomButton
								fullWidth
								color="primary"
								disabled={selectedCompany.type === COMPANY_TYPES.CONSORTIUM}
								variant="contained"
								onClick={handleSubmit}
							>
								{translate("common:btn.submit")}
							</CustomButton>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}
