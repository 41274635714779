import client from "../client/client";

class CompanyService {
	createBu({ companyId }, { shortName, name }, cancelToken) {
		return client.post(`/companies/${companyId}`, { payload: { shortName, name }, config: { cancelToken } });
	}

	getAllUser({ companyId }, cancelToken) {
		return client.get(`/companies/${companyId}/users`, { config: { cancelToken } });
	}
}

export default new CompanyService();
