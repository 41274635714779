import React, { Component } from "react";
import { Tabs, Tab } from "@mui/material";
import { connect } from "react-redux";
import { BackOfficeService, RoleService, ApiService } from "../../api";
import { IconComponent, createNotification, CustomTooltip } from "../../common/components";
import { AnalyticsProvider, Flags, Permissions, translate } from "../../common/providers";
import tabs from "./tabs/tabs";

import { exportView } from "../../navigation";
import styles from "./DSI.module.css";
import { alphaSort } from "../../common/utils";
import { setIsDsi } from "../search-template/company-search-template/slice/company-search-template-slice";

function StyledTabs({ children, onChange, value }) {
	return (
		<Tabs
			classes={{
				root: styles.StyledTabs__root,
				indicator: styles.StyledTabs__indicator,
			}}
			scrollButtons="auto"
			textColor="primary"
			value={value}
			variant="scrollable"
			onChange={onChange}
		>
			{children}
		</Tabs>
	);
}

function TabPanel(props) {
	const { children, value, index } = props;
	return (
		<div hidden={value !== index} style={{ display: "flex", height: "100%", overflow: "hidden" }}>
			{value === index && children}
		</div>
	);
}

function formatEnumData(d) {
	const formatted = d?.toLowerCase().split("_").join(" ");
	return {
		name: d,
		displayName: formatted.substring(0, 1).toUpperCase() + formatted.substring(1, formatted.length),
	};
}

const mapDispatchToProps = (dispatch) => ({
	onSetIsDsi: (boolean) => dispatch(setIsDsi(boolean)),
});

class DSI extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			companies: [],
			userRolesInCompany: [],
			userRolesInProject: [],
			companyRolesInProject: [],
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const { onSetIsDsi } = this.props;
		const documentTitle = translate("dsi.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "DSI" });
		this.getUserRolesInCompany();
		this.getUserRolesInProject();
		this.getCompanyRolesInProject();
		this.getAllCompanies();
		onSetIsDsi(true);
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	handleChange = (event, newValue) => {
		this.setState({ value: newValue });
	};

	getUserRolesInCompany() {
		RoleService.listUserInCompany(this.cancelTokenSource.token)
			.then((data) => {
				if (Array.isArray(data)) {
					const formattedData = data.map((d) => formatEnumData(d));
					this.setState({ userRolesInCompany: formattedData });
				}
			})
			.catch((err) => console.error(err));
	}

	getUserRolesInProject() {
		RoleService.listUserInProject(this.cancelTokenSource.token)
			.then((data) => {
				if (Array.isArray(data)) {
					const formattedData = data.map((d) => formatEnumData(d));
					this.setState({ userRolesInProject: formattedData });
				}
			})
			.catch((err) => console.error(err));
	}

	getCompanyRolesInProject() {
		RoleService.listCompanyInProject(this.cancelTokenSource.token)
			.then((data) => {
				if (Array.isArray(data)) {
					const formattedData = data.map((d) => formatEnumData(d));
					this.setState({ companyRolesInProject: formattedData });
				}
			})
			.catch((err) => console.error(err));
	}

	getAllCompanies() {
		BackOfficeService.getAllCompanies(this.cancelTokenSource.token)
			.then((data) => {
				data.sort((d1, d2) => alphaSort(d1.companyName, d2.companyName));
				this.setState({ companies: data });
			})
			.catch((err) => console.error(err));
	}

	createCompany(payload) {
		BackOfficeService.createCompany(payload, this.cancelTokenSource.token)
			.then((data) => {
				const { companies } = this.state;
				this.setState({ companies: [...companies, data] });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	updateCompany(companyId, payload) {
		BackOfficeService.updateCompany({ companyId }, payload, this.cancelTokenSource.token)
			.then((data) => {
				const { companies } = this.state;
				const filteredCompanies = companies.filter((c) => c.id !== companyId);
				this.setState({ companies: [data, ...filteredCompanies] });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	changeBlockForUser(user) {
		if (!user) {
			return;
		}
		BackOfficeService.changeBlockUserOnPlatform({ userId: user.id }, this.cancelTokenSource.token)
			.then(() => {
				const { companies } = this.state;
				companies.forEach((cny) => {
					const cnyUser = cny.users && cny.users.find((u) => u.id === user.id);
					if (!cnyUser) {
						return;
					}
					cnyUser.locked = !cnyUser.locked;
				});
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	getCompanyUsers(company) {
		if (!company) {
			return;
		}
		const companyId = company.id;
		const { companies } = this.state;
		if (Array.isArray(companies.find((c) => c.id === company.id).users)) {
			return;
		}
		BackOfficeService.getAllUsersByCompany({ companyId }, this.cancelTokenSource.token)
			.then((data) => {
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				cny.users = data;
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	createUser(company, payload) {
		if (!company) {
			return;
		}
		const companyId = company.id;
		BackOfficeService.createUser({ companyId }, payload, this.cancelTokenSource.token)
			.then((data) => {
				const { companies } = this.state;
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				cny.users.push(data);
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	regeneratePwdForUser(user) {
		if (!user) {
			return;
		}
		const userId = user.id;
		BackOfficeService.generateNewPasswordForUser({ userId }, this.cancelTokenSource.token)
			.then((data) =>
				createNotification({
					title: translate("dsi.notification.success.click-me-to-copy"),
					timeout: 20000,
					callback: () => {
						this.copyToClipboard(data?.password?.trim() || "");
					},
				})
			)
			.catch((err) => {
				console.error(err);
			});
	}

	copyToClipboard(str) {
		navigator.clipboard
			.writeText(str)
			.then(() =>
				createNotification({
					type: "success",
					message: translate("dsi.notification.success.copied-to-clipboard"),
				})
			)
			.catch((err) => {
				console.error(err);
			});
	}

	updateUserRoleInProject({ company, user, project, role }) {
		if (!company || !user || !project || !role) {
			return;
		}
		const companyId = company.id;
		const userId = user.id;
		const projectId = project.id;
		BackOfficeService.updateUserRoleInProject({ companyId, userId, projectId }, role, this.cancelTokenSource.token)
			.then(() => {
				const { companies } = this.state;
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				const cnyUser = cny.users.find((u) => u.id === userId);
				const cnyProject = cnyUser.projects.find((p) => p.id === projectId);
				cnyProject.roles = [role];
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	updateUserRoleInCompany({ company, user, role }) {
		if (!company || !user || !role) {
			return;
		}
		const companyId = company.id;
		const userId = user.id;
		BackOfficeService.updateUserRoleInCompany({ companyId, userId }, role, this.cancelTokenSource.token)
			.then((data) => {
				const { companies } = this.state;
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				const indexUser = cny.users.findIndex((u) => u.id === userId);
				cny.users[indexUser].companyRole = data.companyRole;
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	getCompanyProjects(company) {
		if (!company) {
			return;
		}
		const companyId = company.id;
		const { value } = this.state;
		if (value !== 3) {
			this.getCompanyUsers(company);
		}
		BackOfficeService.getAllProjectsDetails({ companyId }, this.cancelTokenSource.token)
			.then((data) => {
				const { companies } = this.state;
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				cny.projects = data;
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	updateUser(user, companyId) {
		this.setState((prev) => ({
			...prev,
			companies: prev.companies.map((cp) =>
				cp.id === companyId
					? {
							...cp,
							users: cp.users.map((u) =>
								user.id === u.id
									? {
											...u,
											displayName: user.displayName,
											email: user.email,
											firstName: user.firstname,
											lastName: user.lastname,
									  }
									: u
							),
					  }
					: cp
			),
		}));
	}

	upateCompanyRoleInProject({ company, project, role }) {
		if (!company || !project || !role) {
			return;
		}
		const companyId = company.id;
		const projectId = project.id;
		BackOfficeService.updateCompanyRoleInProject({ companyId, projectId }, role, this.cancelTokenSource.token)
			.then(() => {
				const { companies } = project;

				if (!companies.some((c) => c.id === companyId)) {
					throw new Error();
				}

				const cny = companies.find((c) => c.id === companyId);
				cny.role = role;
				const { companies: cnies } = this.state;
				this.setState({ companies: cnies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	addExistingPartnerToProject({ selectedCompany, project, company }) {
		if (!company || !project) {
			return;
		}
		const companyId = selectedCompany.id;
		const projectId = project.id;
		BackOfficeService.addExistingPartnerToProject(
			{ companyId: company.id, projectId },
			this.cancelTokenSource.token
		)
			.then(() => {
				const { companies } = this.state;
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				const cnyProject = cny.projects.find((u) => u.id === projectId);
				cnyProject.companies.push(company);
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	addExistingUserToProject({ company, user, project }) {
		if (!company || !project || !user) {
			return;
		}
		const companyId = company.id;
		const projectId = project.id;
		const userId = user.id;
		BackOfficeService.addExistingUserToProject({ companyId, projectId, userId }, this.cancelTokenSource.token)
			.then((data) => {
				const { companies } = this.state;
				if (!companies.some((c) => c.id === companyId)) {
					throw Error();
				}
				const cny = companies.find((c) => c.id === companyId);
				const cnyProject = cny.projects.find((u) => u.id === projectId);
				cnyProject.users.push(data);
				this.setState({ companies });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	updateUserStatusInProject({ company, selectedUser, project, selectedStatus }) {
		const companyId = company.id;
		const projectId = project.id;
		const userId = selectedUser.id;
		const status = JSON.stringify(selectedStatus);
		BackOfficeService.updateUserStatusInProject(
			{ companyId, userId, projectId, status },
			this.cancelTokenSource.token
		)
			.then(() => {
				const { users } = project;
				const isProject = Array.isArray(users);
				if (!isProject) {
					const cnyUser = company.users.find((u) => u.id === userId);
					const cnyProject = cnyUser.projects.find((p) => p.id === projectId);
					cnyProject.statusInProject = selectedStatus;
					const { companies: c } = this.state;
					this.setState({ companies: c });
				}
				if (isProject) {
					const user = users.find((us) => us.id === userId);
					user.statusInProject = selectedStatus;
					const { users: u } = this.state;
					this.setState({ users: u });
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		const { value, companies, userRolesInCompany, userRolesInProject, companyRolesInProject } = this.state;
		return (
			<div className={styles.container}>
				<StyledTabs value={value} onChange={this.handleChange}>
					{tabs.map((i) => (
						<Tab
							key={i.tab.title}
							label={
								<CustomTooltip title={translate(i.tab.title)}>
									<IconComponent color="var(--color-dark-grey-1)" icon={i.tab.icon} size="lg" />
								</CustomTooltip>
							}
						/>
					))}
				</StyledTabs>
				{tabs.map((i, index) => (
					<TabPanel key={i.tab.title} index={index} value={value}>
						{i.panel({
							companies,
							userRolesInCompany,
							userRolesInProject,
							companyRolesInProject,
							createCompany: this.createCompany.bind(this),
							updateCompany: this.updateCompany.bind(this),
							changeBlockUser: this.changeBlockForUser.bind(this),
							getCompanyUsers: this.getCompanyUsers.bind(this),
							createUser: this.createUser.bind(this),
							regeneratePwdForUser: this.regeneratePwdForUser.bind(this),
							updateUserRoleInProject: this.updateUserRoleInProject.bind(this),
							updateUserRoleInCompany: this.updateUserRoleInCompany.bind(this),
							getCompanyProjects: this.getCompanyProjects.bind(this),
							updateUser: this.updateUser.bind(this),
							upateCompanyRoleInProject: this.upateCompanyRoleInProject.bind(this),
							addExistingPartnerToProject: this.addExistingPartnerToProject.bind(this),
							addExistingUserToProject: this.addExistingUserToProject.bind(this),
							updateUserStatusInProject: this.updateUserStatusInProject.bind(this),
						})}
					</TabPanel>
				))}
			</div>
		);
	}
}

export { default as dsiSlice } from "./slice/dsi-slice";
export default exportView({
	path: "/dsi",
	localesPath: "/dsi/locales",
	component: connect(null, mapDispatchToProps)(DSI),
	flag: Flags.BACK_OFFICE,
	role: Permissions.PLATFORM_ADMIN,
});
