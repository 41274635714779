import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import RequirementVersionGrouper from "./requirement-version-grouper/RequirementVersionGrouper";

export default function VersionLayer({ metadata, height, priority, onSetPriority }) {
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const showVersioning = useSelector(({ srDocument }) => srDocument.showVersioning);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const [hoveredWarning, setHoveredWarning] = useState(null);
	const [openedVersionGroup, setOpenedVersionGroup] = useState(0);

	const filteredMetaData = useMemo(() => {
		if (Array.isArray(metadata) && metadata.length > 0) {
			return metadata.filter(
				(sentence) => sentence?.infoVersionStatus && sentence.infoVersionStatus !== "IDENTICAL"
			);
		}
		return [];
	}, [metadata]);
	let exludedIds = [];
	const handleOpen = (id) => {
		setOpenedVersionGroup(id);
	};
	const handleClose = () => {
		setOpenedVersionGroup(0);
	};
	return (
		<>
			{showVersioning &&
				!isSelecting &&
				!redressingInformation &&
				metadata &&
				Array.isArray(metadata) &&
				filteredMetaData.map((x, index) => {
					if (exludedIds.includes(x.informationId)) {
						return null;
					}
					let includedInfo = [];
					let i = index + 1;
					while (
						filteredMetaData.length > 1 &&
						i <= filteredMetaData.length - 1 &&
						height * ((filteredMetaData[i].rectangle.y1 + filteredMetaData[i].rectangle.y2) / 2) -
							height * ((x.rectangle.y1 + x.rectangle.y2) / 2) <
							40
					) {
						exludedIds = [...exludedIds, filteredMetaData[i].informationId];
						includedInfo = [...includedInfo, filteredMetaData[i]];
						i++;
					}
					return (
						<RequirementVersionGrouper
							key={x.informationId}
							height={height}
							hoveredWarning={hoveredWarning}
							metadata={[x, ...includedInfo]}
							opennedId={openedVersionGroup}
							priority={priority}
							setHoveredWarning={setHoveredWarning}
							onClose={handleClose}
							onOpen={handleOpen}
							onSetPriority={onSetPriority}
						/>
					);
				})}
		</>
	);
}
