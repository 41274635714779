import React, { useState } from "react";
import styles from "./KnowledgeBasePopOver.module.css";
import CustomPopOver from "./CustomPopOver";
import { translate } from "../../providers";
import { CustomButton } from "../buttons";
import { IconComponent, icon } from "../icon-component";

const KnowledgeBasePopOver = ({ label, link, linkLabel }) => {
	const [open, setOpen] = useState(false);
	const handleOpenInformationBox = () => {
		setOpen(true);
	};
	const handleCloseInformationBox = () => {
		setOpen(false);
	};
	return (
		<div role="presentation" onMouseEnter={handleOpenInformationBox} onMouseLeave={handleCloseInformationBox}>
			<CustomPopOver
				className={styles.information__content}
				content={
					<div>
						<div>{label}</div>
						{link && (
							<div className={styles["information__content--footer"]}>
								<CustomButton
									startIcon={icon.faUpRightFromSquare}
									variant="contained"
									onClick={() => {
										window.open(link, "_blank");
									}}
								>
									{linkLabel || translate("common:knowledge-base:access")}
								</CustomButton>
							</div>
						)}
					</div>
				}
				open={open}
				placement="bottom"
				onClose={handleCloseInformationBox}
			>
				<span role="presentation">
					<IconComponent color="var(--color-dark-grey-1" icon={icon.faInfoCircle} size="md" />
				</span>
			</CustomPopOver>
		</div>
	);
};

export default KnowledgeBasePopOver;
