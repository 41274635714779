import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconComponent, DropDown, icon, HorizontalDivider, CustomButton } from "../../../../../../../common/components";
import Option from "../../buttons/SwitchOption";
import SmartReviewContext from "../../../../../context/smart-review-context";
import { setPartners, setShowVersioning } from "../../../../../slice/document/document-slice";
import { translate } from "../../../../../../../common/providers";
import styles from "./OptionsDropDown.module.css";

const OptionsDropDown = React.forwardRef(({ onClose = null, open }, ref) => {
	const [extend, setExtend] = useState(false);
	const partners = useSelector(({ srDocument }) => srDocument.partners);
	const showVersioning = useSelector(({ srDocument }) => srDocument.showVersioning);
	const dispatch = useDispatch();

	useEffect(() => {
		const savedPreferences = SmartReviewContext.getContext()?.preferences;
		dispatch(setShowVersioning(savedPreferences?.versioning));
	}, [dispatch]);

	const handleCheckPartners = (partner) => {
		SmartReviewContext.setPartners({
			partners: partners.map((x) =>
				x.id === partner.id ? { id: x.id, display: !x.display } : { id: x.id, display: x.display }
			),
		});
		dispatch(setPartners(partners.map((x) => (x.id === partner.id ? { ...x, display: !x.display } : x))));
	};
	const handleCheckVersioning = () => {
		SmartReviewContext.setPreferences({
			preferences: {
				versioning: !showVersioning,
			},
		});
		dispatch(setShowVersioning(!showVersioning));
	};
	return (
		<DropDown ref={ref} open={open} onClose={onClose}>
			<div className={styles.dropDownContainer}>
				<Option
					isChecked={showVersioning}
					text={translate("smart-review.options-drop-down.show-versioning")}
					onChange={handleCheckVersioning}
				/>
				{Array.isArray(partners) && partners.length > 0 && (
					<CustomButton
						className={styles.dropDownContainer__optionsExtender}
						onClick={() => setExtend(!extend)}
					>
						<span className={styles.option__text}>
							{translate("smart-review.options-drop-down.show-partner")}
						</span>
						<span className={styles.option__control}>
							<IconComponent icon={extend ? icon.faChevronDown : icon.faChevronRight} />
						</span>
					</CustomButton>
				)}
				{extend && (
					<>
						<HorizontalDivider />
						{partners.map((partner) => (
							<Option
								key={partner.id}
								isChecked={partner.display}
								text={partner.name}
								onChange={() => handleCheckPartners(partner)}
							/>
						))}
					</>
				)}
			</div>
		</DropDown>
	);
});

export default OptionsDropDown;
