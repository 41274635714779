import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer, TablePagination } from "@mui/material";
import {
	getContent,
	getTree,
	ROOT_DIR,
	setLoadingLocation,
	setSort,
	setPage,
	setLimit,
	getStatusCounters,
} from "../../slice/document-center-slice";
import { ApiService, ProjectDirectoryService, ProjectDocumentService } from "../../../../api";
import styles from "./DocumentCenterTable.module.css";
import {
	CircularLoader,
	CustomDialog,
	CustomTable,
	CustomTableBody,
	CustomTableHead,
	createNotification,
} from "../../../../common/components";
import { compareInsensitiveCaseString, debounce, downloadFile, isNonEmptyArray } from "../../../../common/utils";
import MoveDialog from "../move-dialog/MoveDialog";
import DocumentCenterSidePanel from "../document-center-side-panel/DocumentCenterSidePanel";
import ConfirmDeleteDialog from "../confirm-delete-dialog/ConfirmDeleteDialog";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../common/providers";
import DeleteLastVersionDialog from "../delete-last-version-dialog/DeleteLastVersionDialog";
import DocumentTableHeader from "./DocumentTableHeader";
import DocumentTableRow from "./DocumentTableRow";
import ChangeTypeDialog from "../change-type-dialog/ChangeTypeDialog";
import { FILE_TYPES } from "../../../../common/constants/file-types";
import { DOCUMENT_STATUS } from "../../constants/constants";
import DocumentDragAndDropInfo from "../../../../common/components/drag-and-drop/document-drag-and-drop-info/DocumentDragAndDropInfo";

const debouncedFunction = debounce((func) => func(), 500);

export default function DocumentCenterTable({ docStatusInfoBannerDisplayed = true, onImportFile, onNavigateToFolder }) {
	const dispatch = useDispatch();
	const cancelTokenRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const isLoadingContent = useSelector(({ documentCenter }) => documentCenter.isLoadingContent);
	const isLoadingLocation = useSelector(({ documentCenter }) => documentCenter.isLoadingLocation);
	const currentDirectoryId = useSelector(({ documentCenter }) => documentCenter.currentDirectoryId);
	const hasActionPermission = useSelector(({ documentCenter }) => documentCenter.hasActionPermission);
	const reduxLocation = useSelector(({ documentCenter }) => documentCenter.location);
	const searchDocumentId = useSelector(({ documentCenter }) => documentCenter.searchDocumentId);
	const page = useSelector(({ documentCenter }) => documentCenter.page);
	const limit = useSelector(({ documentCenter }) => documentCenter.limit);
	const sort = useSelector(({ documentCenter }) => documentCenter.sort);
	const direction = useSelector(({ documentCenter }) => documentCenter.direction);
	const documents = useSelector(({ documentCenter }) => documentCenter.currentDirectoryContent.contents);
	const hasReceivedModifications = useSelector(({ documentCenter }) => documentCenter.hasReceivedModifications);
	const { totalElements } = useSelector(({ documentCenter }) => documentCenter.currentDirectoryContent);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [countUnsupported, setCountUnsupported] = useState(0);
	const [countInProgress, setCountInProgress] = useState(0);
	const [dragOver, setDragOver] = useState(false);
	const [selectedDirectories, setSelectedDirectories] = useState([]);
	const [selectedVersionedDocumentName, setSelectedVersionedDocumentName] = useState("");
	const [directoryHasVersionedDoc, setDirectoryHasVersionedDoc] = useState("");
	const [deleteContent, setDeleteContent] = useState(true);
	const [downloading, setDownloading] = useState(false);
	const [openMoveDialog, dispatchOpenMoveDialog] = useReducer((prev) => !prev, false);
	const [openConfirmDeleteDialog, dispatchOpenConfirmDeleteDialog] = useReducer((prev) => !prev, false);
	const [openDeleteLastVersionDialog, dispatchOpenDeleteLastVersionDialog] = useReducer((prev) => !prev, false);
	const [openChangeTypenDialog, dispatchOpenChangeTypenDialog] = useReducer((prev) => !prev, false);
	const [deletingAllVersions, setDeletingAllVersions] = useState(false);
	const [openSP, setOpenSP] = useState({ open: false, id: 0, type: "folder" });

	const resetSelections = useCallback(() => {
		setSelectedDocuments([]);
		setCountUnsupported(0);
		setCountInProgress(0);
		setSelectedDirectories([]);
	}, []);

	useEffect(() => {
		cancelTokenRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenRef.current);
		};
	}, []);

	useEffect(() => {
		if (!hasReceivedModifications) {
			return;
		}
		setOpenSP({ open: false, id: 0, type: "folder" });
		if (openMoveDialog) {
			dispatchOpenMoveDialog();
		}
	}, [hasReceivedModifications, openMoveDialog]);

	const location = useLocation().search;

	useEffect(() => {
		if (isLoadingLocation) {
			dispatch(setLoadingLocation(false));
		} else {
			const urlDirectory = new URLSearchParams(location).get("directory");
			if (Array.isArray(reduxLocation) && reduxLocation.length > 0) {
				debouncedFunction(() => {
					dispatch(getStatusCounters({ projectId, token: cancelTokenRef.current.token }));
				});
				dispatch(
					getContent({
						projectId,
						directoryId: urlDirectory || "root",
						page,
						limit,
						sort,
						direction,
						searchDocumentId,
						token: cancelTokenRef.current.token,
					})
				);
			}
		}
		return resetSelections;
	}, [
		location,
		dispatch,
		resetSelections,
		isLoadingLocation,
		projectId,
		searchDocumentId,
		sort,
		reduxLocation,
		direction,
		page,
		limit,
	]);

	useEffect(() => {
		if (!Array.isArray(documents) || documents.length === 0) {
			return;
		}
		let names = "";
		documents
			.filter((document) => selectedDocuments.includes(document.id) && document.version > 1)
			.forEach((document) => {
				names = ` ${names} ${document.label}`;
			});

		setSelectedVersionedDocumentName(names);
	}, [documents, selectedDocuments]);

	useEffect(() => {
		if (!Array.isArray(documents) || documents.length === 0) {
			return;
		}
		let names = "";
		documents
			.filter((document) => selectedDirectories.includes(document.id) && document.previousV >= 1)
			.forEach((document) => {
				names = ` ${names} ${document.label}`;
			});

		setDirectoryHasVersionedDoc(!!names);
	}, [documents, selectedDirectories]);

	const handleOpenSP = (id, type, status) => setOpenSP({ open: true, id, type, status });
	const handleCloseSP = () => setOpenSP({ open: false, id: 0 });

	const handleClickRow = ({ type, id, status }) => {
		if (compareInsensitiveCaseString(type, FILE_TYPES.FOLDER)) {
			dispatch(setPage(0));
			onNavigateToFolder({ id });
		} else if (status !== DOCUMENT_STATUS.IN_PROGRESS) {
			handleOpenSP(id, type, status);
		}
	};

	const handleDownload = () => {
		setDownloading(true);
		ProjectDirectoryService.download(
			{ projectId },
			{ fromDirectoryId: currentDirectoryId, directoryIds: selectedDirectories, documentIds: selectedDocuments },
			cancelTokenRef.current.token
		)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
			.catch((err) => console.error(err))
			.finally(() => setDownloading(false));
	};
	const resetTreeAndContent = () => {
		debouncedFunction(() => {
			dispatch(getStatusCounters({ projectId, token: cancelTokenRef.current.token }));
		});
		dispatch(getTree({ projectId, token: cancelTokenRef.current.token }));
		dispatch(
			getContent({
				projectId,
				directoryId: currentDirectoryId,
				page,
				limit,
				sort,
				direction,
				token: cancelTokenRef.current.token,
			})
		);
		setDeleteContent(true);
		resetSelections();
	};
	const handleDelete = (id) => {
		setDeletingAllVersions(false);
		const deletePayload = { directoryIds: selectedDirectories, documentIds: selectedDocuments };
		if (id) {
			deletePayload.directoryIds = [];
			deletePayload.documentIds = [id];
		}
		ProjectDirectoryService.deleteSelection(
			{ projectId, fromDirectoryId: currentDirectoryId },
			{
				fromDirectoryId: currentDirectoryId,
				...deletePayload,
				deleteContent,
			},
			cancelTokenRef.current.token
		)
			.then(resetTreeAndContent)
			.catch(console.error);
	};
	const handleOpenDeleteDialog = () => {
		if (selectedDirectories.length > 0) {
			dispatchOpenConfirmDeleteDialog();
		} else if (selectedVersionedDocumentName.length > 0) {
			dispatchOpenDeleteLastVersionDialog();
		} else {
			handleDelete();
		}
	};
	const handleSetDeleteContent = () => {
		setDeleteContent((prev) => !prev);
	};
	const handleConfirmDeleteDialog = () => {
		dispatchOpenConfirmDeleteDialog();
		if ((deleteContent && directoryHasVersionedDoc) || selectedVersionedDocumentName) {
			dispatchOpenDeleteLastVersionDialog();
			setDeletingAllVersions(true);
		} else {
			handleDelete();
		}
	};
	const handleOpenTypeDialog = () => {
		dispatchOpenChangeTypenDialog();
	};
	const handleSubmitChangeType = (submitedType) => {
		ProjectDocumentService.updateDocumentTypes(
			{ projectId },
			{
				docIds: isNonEmptyArray(selectedDocuments) ? selectedDocuments : undefined,
				dirIds: isNonEmptyArray(selectedDirectories) ? selectedDirectories : undefined,
				type: submitedType,
			},
			cancelTokenRef.current.token
		)
			.then(resetTreeAndContent)
			.catch(console.error)
			.finally(() => dispatchOpenChangeTypenDialog());
	};
	const handleOpenMoveDialog = () => dispatchOpenMoveDialog();
	const handleMove = (directoryId) => {
		handleOpenMoveDialog();
		const toDirectoryId =
			typeof directoryId === "string" && directoryId?.toLowerCase() === ROOT_DIR?.toLowerCase()
				? null
				: directoryId;
		ProjectDirectoryService.moveDirectoryContent(
			{ projectId },
			{
				toDirectoryId,
				directoryIds: selectedDirectories,
				documentIds: selectedDocuments,
				fromDirectoryId: currentDirectoryId,
			},
			cancelTokenRef.current.token
		)
			.then(resetTreeAndContent)
			.catch(console.error);
	};
	const handleSort = (sortBy) => {
		if (sort === sortBy) {
			dispatch(setSort({ sort: sortBy, direction: direction === "ASC" ? "DESC" : "ASC" }));
		} else {
			dispatch(setSort({ sort: sortBy, direction: "ASC" }));
		}
		resetSelections();
	};
	const handleChangePage = (_, p) => {
		if (totalElements > 0) {
			dispatch(setPage(p));
		}
		resetSelections();
	};
	const handleChangeLimitPerPage = (e) => {
		if (totalElements > 0) {
			dispatch(setLimit(e.target?.value || 25));
			dispatch(setPage(0));
		}
		resetSelections();
	};
	const handleSelect = (id, type) => {
		if (compareInsensitiveCaseString(type, FILE_TYPES.FOLDER)) {
			let tempSelectedDirectories = [...selectedDirectories];
			const index = tempSelectedDirectories.indexOf(id);
			if (index === -1) {
				tempSelectedDirectories = [id, ...tempSelectedDirectories];
			} else {
				tempSelectedDirectories.splice(index, 1);
			}
			setSelectedDirectories(tempSelectedDirectories);
		} else {
			let tempSelectedDocuments = [...selectedDocuments];
			const index = tempSelectedDocuments.indexOf(id);
			if (index === -1) {
				tempSelectedDocuments = [id, ...tempSelectedDocuments];
				if (compareInsensitiveCaseString(type, FILE_TYPES.UNSUPPORTED)) {
					setCountUnsupported((prev) => prev + 1);
				}
				if (documents.find((doc) => doc.id === id).status === DOCUMENT_STATUS.IN_PROGRESS) {
					setCountInProgress((prev) => prev + 1);
				}
			} else {
				tempSelectedDocuments.splice(index, 1);
				if (compareInsensitiveCaseString(type, FILE_TYPES.UNSUPPORTED)) {
					setCountUnsupported((prev) => prev - 1);
				}
				if (documents.find((doc) => doc.id === id).status === DOCUMENT_STATUS.IN_PROGRESS) {
					setCountInProgress((prev) => prev - 1);
				}
			}
			setSelectedDocuments(tempSelectedDocuments);
		}
	};
	const handleSelectAll = () => {
		if (selectedDirectories.length > 0 || selectedDocuments.length > 0) {
			resetSelections();
			setCountUnsupported(0);
			setCountInProgress(0);
		} else {
			let tempSelectedDocuments = [];
			let tempSelectedDirectories = [];
			documents.forEach((document) => {
				if (compareInsensitiveCaseString(document.type, FILE_TYPES.FOLDER)) {
					tempSelectedDirectories = [document.id, ...tempSelectedDirectories];
				} else {
					tempSelectedDocuments = [document.id, ...tempSelectedDocuments];
					if (compareInsensitiveCaseString(document.type, FILE_TYPES.UNSUPPORTED)) {
						setCountUnsupported((prev) => prev + 1);
					}
					if (documents.find((doc) => doc.id === document.id).status === DOCUMENT_STATUS.IN_PROGRESS) {
						setCountInProgress((prev) => prev + 1);
					}
				}
			});
			setSelectedDirectories(tempSelectedDirectories);
			setSelectedDocuments(tempSelectedDocuments);
		}
	};
	const handleDragOver = (event) => {
		if (!hasActionPermission && !dragOver) {
			return;
		}
		event.preventDefault();
		setDragOver(true);
	};
	const handleDragLeave = () => {
		if (!hasActionPermission && dragOver) {
			return;
		}
		setDragOver(false);
	};
	const handleDropUpload = (event) => {
		if (!hasActionPermission) {
			return;
		}
		event.preventDefault();
		setDragOver(false);
		const allowedFiles = Object.values(event.dataTransfer.files).filter((file) => typeof file !== "number");
		if (Object.values(event.dataTransfer.files).length > allowedFiles.length) {
			createNotification({
				type: "error",
				message: translate("common:upload-file.bad-format"),
			});
		}
		if (allowedFiles.length > 0) {
			onImportFile(allowedFiles);
		}
	};

	if ((isLoadingContent && page === 0) || isLoadingLocation) {
		return (
			<div className={styles.table__loader}>
				<CircularLoader color="primary" size={75} />
			</div>
		);
	}
	const handleCloseLastVersionDialog = () => {
		dispatchOpenDeleteLastVersionDialog();
		setDeletingAllVersions(false);
	};
	return (
		<>
			<TableContainer
				className={styles.tableContainer}
				data-docStatusInfoBannerDisplayed={docStatusInfoBannerDisplayed}
				data-fullheight={
					cautionBannerDisabled ||
					!isSegFeatureEnabled(SegFlags.TEAM) ||
					!isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS)
				}
			>
				<span
					className={styles.dragover}
					data-dragover={dragOver}
					onDragLeave={handleDragLeave}
					onDragOver={handleDragOver}
					onDrop={handleDropUpload}
				>
					{(Array.isArray(documents) && documents.length > 0 && (
						<CustomTable stickyHeader padding="none">
							<CustomTableHead>
								<DocumentTableHeader
									activeSort={sort}
									direction={direction}
									documentInPageCount={documents.length}
									dragOver={dragOver}
									hasActionPermission={hasActionPermission}
									hasInProgress={countInProgress > 0}
									hasUnsupported={countUnsupported > 0}
									selectionCount={selectedDirectories.length + selectedDocuments.length}
									totalElements={totalElements}
									onDownload={handleDownload}
									onOpenDeleteDialog={handleOpenDeleteDialog}
									onOpenMoveDialog={handleOpenMoveDialog}
									onOpenTypeDialog={handleOpenTypeDialog}
									onSelectAll={handleSelectAll}
									onSort={handleSort}
								/>
							</CustomTableHead>
							<CustomTableBody>
								{Array.isArray(documents) &&
									documents.map((document) => (
										<DocumentTableRow
											key={document.id + document.type}
											document={document}
											hasActionPermission={hasActionPermission}
											isChecked={
												compareInsensitiveCaseString(document.type, FILE_TYPES.FOLDER)
													? selectedDirectories.includes(document.id)
													: selectedDocuments.includes(document.id)
											}
											onClickRow={handleClickRow}
											onDelete={handleDelete}
											onOpenSP={handleOpenSP}
											onSelect={handleSelect}
										/>
									))}
							</CustomTableBody>
						</CustomTable>
					)) || <span className={styles.table_empty}>{translate("document-center.table.empty")}</span>}
				</span>
			</TableContainer>
			{dragOver && <DocumentDragAndDropInfo className={styles.dragDropInfo} />}
			<TablePagination
				classes={{ root: styles.table__pagination }}
				component="div"
				count={totalElements || 0}
				page={page}
				rowsPerPage={limit}
				rowsPerPageOptions={[10, 25, 50]}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeLimitPerPage}
			/>
			<MoveDialog
				numberOfElements={selectedDirectories.length + selectedDocuments.length}
				open={openMoveDialog}
				selectedFolders={selectedDirectories}
				onClose={handleOpenMoveDialog}
				onMove={handleMove}
			/>
			<ChangeTypeDialog
				open={openChangeTypenDialog}
				onClose={handleOpenTypeDialog}
				onSubmit={handleSubmitChangeType}
			/>
			<ConfirmDeleteDialog
				isFolder
				deleteContent={deleteContent}
				open={openConfirmDeleteDialog}
				text={translate("document-center.table.confirm-delete.text")}
				textSubDir={translate("document-center.table.confirm-delete.sub-text")}
				onClose={dispatchOpenConfirmDeleteDialog}
				onConfirm={handleConfirmDeleteDialog}
				onSetDeleteContent={handleSetDeleteContent}
			/>
			<DeleteLastVersionDialog
				deletingAllVersions={deletingAllVersions}
				open={openDeleteLastVersionDialog}
				onClose={handleCloseLastVersionDialog}
				onConfirm={handleDelete}
			/>
			<DocumentCenterSidePanel
				id={openSP.id}
				open={openSP.open}
				status={openSP.status}
				type={openSP.type}
				onClose={handleCloseSP}
			/>
			<CustomDialog
				loader
				maxWidth="xs"
				open={downloading}
				subTitle={translate("document-center.download-process-dialog.file.helper-text")}
				title={translate("document-center.download-process-dialog.file.main-text")}
			/>
		</>
	);
}
