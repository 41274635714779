import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FILTERS, debouncedMethod, generateDocumentFilterName, generateFilterTitle } from "../../../../utils/utils";
import { Filter, icon, CustomIconButton, FilterSearchList, CircularLoader } from "../../../../../../common/components";
import { isDeepEqual } from "../../../../../../common/utils";
import { translate } from "../../../../../../common/providers";
import { setFilters, setPage, setTempFilters } from "../../../../slice/dsi-slice";

export default function UserFilters({ companies = [], projects = [], onSearch }) {
	const [content, setContent] = useState("");
	const [filterCompany, setFilterCompany] = useState("");
	const [filterProject, setFilterProject] = useState("");
	const dispatch = useDispatch();
	const page = useSelector(({ dsi }) => dsi.page);
	const limit = useSelector(({ dsi }) => dsi.limit);
	const tempFilters = useSelector(({ dsi }) => dsi.tempFilters);
	const tempTotalElements = useSelector(({ dsi }) => dsi.tempTotalElements);
	const IsLoadingUsers = useSelector(({ dsi }) => dsi.IsLoadingUsers);
	const filters = useSelector(({ dsi }) => dsi.filters);
	const needApply = (filterKey) => !isDeepEqual(tempFilters[filterKey], filters[filterKey]);
	useEffect(() => {
		setContent(filters[FILTERS.SEARCH]);
	}, [filters]);
	const resetTempFilters = () => {
		dispatch(setTempFilters(filters));
	};
	const resetTempFilter = (key, display) => {
		const newTempFilters = { ...filters, [key]: [] };
		onSearch({ page: 0, limit, filters: newTempFilters, display });
		dispatch(setTempFilters({ ...filters, [key]: [] }));
		if (display) {
			dispatch(setPage(0));
			dispatch(setFilters({ ...filters, [key]: [] }));
		}
	};
	const handleSearch = (searchFilter) => {
		if (needApply(searchFilter)) {
			dispatch(setFilters(tempFilters));
		}
	};
	const handleCheckItem = (list, key) => {
		const newTempFilters = { ...filters, [key]: list };
		onSearch({ page, limit, filters: newTempFilters, display: false });
	};
	const handleContentChange = (e) => {
		const newValue = e.target.value;
		setContent(newValue);
		debouncedMethod(onSearch, { filters: { ...filters, search: newValue }, page: 0, limit, display: true });
	};
	const handleResetContent = () => {
		setContent("");
		debouncedMethod(onSearch, { filters: { ...filters, search: "" }, page: 0, limit, display: true });
		dispatch(setPage(0));
	};
	const handleSearchCompanies = (e) => setFilterCompany(e?.target?.value || "");
	const handleClearSearchCompanies = () => setFilterCompany("");
	const handleSearchProjects = (e) => setFilterProject(e?.target?.value || "");
	const handleClearSearchProjects = () => setFilterProject("");
	const renderCloseLabel = (searchFilter) => {
		if (IsLoadingUsers) {
			return <CircularLoader color="inherit" size={25} />;
		}
		if (needApply(searchFilter)) {
			return translate("header.filter.apply-button", { resultsNb: tempTotalElements });
		}
		return translate("common:btn.close");
	};
	return (
		<>
			<Filter
				buttonColor={needApply(FILTERS.COMPANIES) ? "primary" : "secondary"}
				closeLabel={renderCloseLabel(FILTERS.COMPANIES)}
				disableClose={IsLoadingUsers}
				isActive={Object.keys(filters[FILTERS.COMPANIES]).length > 0}
				label={generateDocumentFilterName(filters[FILTERS.COMPANIES], translate("header.filter.companies"))}
				title={generateFilterTitle(
					translate("header.filter.companies"),
					(tempFilters[FILTERS.COMPANIES] || []).length
				)}
				onClear={() => resetTempFilter(FILTERS.COMPANIES, true)}
				onClose={() => handleSearch(FILTERS.COMPANIES)}
				onOpen={resetTempFilters}
			>
				<FilterSearchList
					initChecked={tempFilters[FILTERS.COMPANIES]}
					rowKey="id"
					rowLabelKey="companyName"
					rows={companies.filter((c) =>
						(c.companyName?.toLowerCase() || c.shortName?.toLowerCase() || "").includes(
							filterCompany?.toLowerCase()
						)
					)}
					searchPlaceholder={translate("header.filter.companies")}
					searchResultCount={tempTotalElements}
					onChangeSearch={handleSearchCompanies}
					onClear={() => resetTempFilter(FILTERS.COMPANIES, false)}
					onClearSearch={handleClearSearchCompanies}
					onClickRow={(list) => handleCheckItem(list, FILTERS.COMPANIES)}
				/>
			</Filter>
			<Filter
				buttonColor={needApply(FILTERS.PROJECTS) ? "primary" : "secondary"}
				closeLabel={renderCloseLabel(FILTERS.PROJECTS)}
				disableClose={IsLoadingUsers}
				isActive={Object.keys(filters[FILTERS.PROJECTS]).length > 0}
				label={generateDocumentFilterName(filters[FILTERS.PROJECTS], translate("header.filter.projects"))}
				title={generateFilterTitle(
					translate("header.filter.projects"),
					(tempFilters[FILTERS.PROJECTS] || []).length
				)}
				onClear={() => resetTempFilter(FILTERS.PROJECTS, true)}
				onClose={() => handleSearch(FILTERS.PROJECTS)}
				onOpen={resetTempFilters}
			>
				<FilterSearchList
					initChecked={tempFilters[FILTERS.PROJECTS]}
					rowKey="id"
					rowLabelKey="name"
					rows={projects.filter((c) => (c.name?.toLowerCase() || "").includes(filterProject?.toLowerCase()))}
					searchPlaceholder={translate("header.filter.projects")}
					searchResultCount={tempTotalElements}
					onChangeSearch={handleSearchProjects}
					onClear={() => resetTempFilter(FILTERS.PROJECTS, false)}
					onClearSearch={handleClearSearchProjects}
					onClickRow={(list) => handleCheckItem(list, FILTERS.PROJECTS)}
				/>
			</Filter>
			<TextField
				InputProps={
					content
						? {
								endAdornment: (
									<InputAdornment position="end">
										<CustomIconButton
											icon={icon.faTimesCircleRegular}
											onClick={handleResetContent}
										/>
									</InputAdornment>
								),
						  }
						: {}
				}
				placeholder={translate("header.input.placeholder.search-text-comment")}
				size="small"
				value={content}
				variant="outlined"
				onChange={handleContentChange}
			/>
		</>
	);
}
