import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { CustomButton, createNotification } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { ApiService, BackOfficeService } from "../../../../../api";
import styles from "./ProjectUpdater.module.css";

export default function ProjectUpdater({
	project: { id, name, currentStatus, type, plan, language },
	onChangeDetails,
}) {
	const [newName, setName] = useState(name || "");
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleChange = (e) => {
		setName(e?.target?.value);
	};
	const handleSubmit = () =>
		BackOfficeService.updateProjectDetails(
			{ projectId: id },
			{ name: newName, currentStatus, type, plan, language },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				onChangeDetails(data);
				createNotification({ type: "success", message: translate("dsi.project-details.name-update.success") });
			})
			.catch((err) => console.error(err));
	return (
		<div className={styles.name}>
			<TextField
				fullWidth
				label={translate("dsi.project-details.details.name")}
				value={newName || ""}
				variant="outlined"
				onChange={handleChange}
			/>
			<CustomButton color="primary" onClick={handleSubmit}>
				{translate("common:btn.submit")}
			</CustomButton>
		</div>
	);
}
