import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { ApiService, CategoryService, CategoryTemplateService } from "../../../../../api";
import { translate } from "../../../../providers";
import { isNonEmptyObject, updateTreeBranch } from "../../../../utils";
import { HorizontalDivider } from "../../../dividers";
import { icon, IconComponent } from "../../../icon-component";
import { CircularLoader } from "../../../loaders";
import ExpandableCategoryRow from "./ExpandableCategoryRow";
import styles from "./ProjectCategoriesFilter.module.css";

const ProjectCategoriesFilter = ({
	disableFavoriteCategories = false,
	isMultiProject = false,
	onChange,
	request,
	search,
	value,
	readOnly,
}) => {
	const favoriteCategories = useSelector(({ context }) => context.favoriteCategories.categories);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [isTreeLoading, setIsTreeLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const [favoriteCategoriesExpanded, setFavoriteCategoriesExpanded] = useState(true);
	const [filteredFavoriteCategories, setFilteredFavoriteCategories] = useState([]);
	const cancelTokenSourceRef = useRef();
	const checkedCount = useMemo(() => value?.FAVORITE?.length, [value]);
	const disabledFavCat = useMemo(
		() => isMultiProject || disableFavoriteCategories,
		[isMultiProject, disableFavoriteCategories]
	);

	const getTree = useCallback(
		(searchText = "") => {
			if (cancelTokenSourceRef?.current?.token) {
				if (typeof request === "function") {
					request()
						.then((data) => {
							setCategories(data || []);
						})
						.catch((err) => console.error(err))
						.finally(() => setIsTreeLoading(false));
				} else {
					const catTreeRequest = isMultiProject
						? CategoryTemplateService.getTreeForCompany
						: CategoryService.getTreeForProject;

					setIsTreeLoading(true);
					if (searchText) {
						catTreeRequest({ projectId, search: searchText }, cancelTokenSourceRef.current.token)
							.then((data) => {
								setCategories(data || []);
							})
							.catch((err) => console.error(err))
							.finally(() => setIsTreeLoading(false));
					} else if (isMultiProject) {
						CategoryTemplateService.getTreeParentsForCompany(cancelTokenSourceRef.current.token)
							.then((data) => {
								setCategories(data || []);
							})
							.catch((err) => console.error(err))
							.finally(() => setIsTreeLoading(false));
					} else {
						CategoryService.getTreeParentsForProject(
							{ projectId, details: false },
							cancelTokenSourceRef.current.token
						)
							.then((data) => {
								setCategories(data || []);
							})
							.catch((err) => console.error(err))
							.finally(() => setIsTreeLoading(false));
					}
				}
			}
		},
		[projectId, isMultiProject, request]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		getTree(search);
	}, [getTree, search]);
	useEffect(() => {
		setFilteredFavoriteCategories(
			favoriteCategories.filter((favoriteCategory) =>
				favoriteCategory.name.toLowerCase().includes(search.toLocaleLowerCase())
			)
		);
	}, [search, favoriteCategories]);

	const handleExpandFavoriteCategories = () => {
		setFavoriteCategoriesExpanded((prev) => !prev);
	};
	const handleCheckFavoriteCategory = (category, isChecked) => {
		if (readOnly) {
			return;
		}
		const tempCheckedCategories =
			isNonEmptyObject(value) && Object.keys(value)?.some((v) => v !== "is" && v !== "matchAll")
				? { ...value }
				: { CATEGORY: [], FAVORITE: [], is: value?.is || true, matchAll: value?.matchAll };
		if (isChecked) {
			tempCheckedCategories.FAVORITE = tempCheckedCategories.FAVORITE.filter(
				(checkedCategory) => checkedCategory !== category.id
			);
			tempCheckedCategories.CATEGORY = tempCheckedCategories.CATEGORY.filter(
				(checkedCategory) => checkedCategory !== category.id
			);
		} else {
			tempCheckedCategories.FAVORITE = [...tempCheckedCategories.FAVORITE, category.id];
		}
		onChange(tempCheckedCategories);
	};
	const handleCheckCategory = (category, isChecked) => {
		if (readOnly) {
			return;
		}
		const tempCheckedCategories =
			isNonEmptyObject(value) && Object.keys(value)?.some((v) => v !== "is" && v !== "matchAll")
				? { ...value }
				: { CATEGORY: [], FAVORITE: [], is: value?.is, matchAll: value?.matchAll };
		if (isChecked) {
			tempCheckedCategories.CATEGORY = tempCheckedCategories.CATEGORY.filter(
				(checkedCategory) => checkedCategory !== category.id
			);
			tempCheckedCategories.FAVORITE = tempCheckedCategories.FAVORITE.filter(
				(checkedCategory) => checkedCategory !== category.id
			);
		} else {
			tempCheckedCategories.CATEGORY = [...tempCheckedCategories.CATEGORY, category.id];
			tempCheckedCategories.CATEGORY = tempCheckedCategories.CATEGORY.filter(
				(val, index) => tempCheckedCategories.CATEGORY.indexOf(val) === index
			);
		}
		onChange(tempCheckedCategories);
	};
	const handleToggleExpand = (category) => {
		if (!category.childrenCount && !category.subThematic?.length) {
			return;
		}
		if (!Array.isArray(category.subThematic) || category.subThematic?.length === 0) {
			const catChildRequest = isMultiProject
				? CategoryTemplateService.getTreeChildrenForCompany
				: CategoryService.getTreeChildrenForProject;
			catChildRequest({ parentId: category.id, details: false }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setCategories((prev) =>
						updateTreeBranch({
							tree: prev,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
								expanded: !category.expanded,
							},
							iterativeBranchName: "subThematic",
						})
					);
				})
				.catch((err) => console.error(err));
		} else {
			setCategories((prev) =>
				updateTreeBranch({
					tree: prev,
					idToReplace: category.id,
					newBranch: {
						...category,
						expanded: category.filtered !== undefined ? false : !category.expanded,
						filtered: category.prevFilteredState,
						prevFilteredState: category.filtered,
					},
					iterativeBranchName: "subThematic",
				})
			);
		}
	};
	const handleIsChecked = (catId) => {
		if (value?.FAVORITE?.includes(catId) || value?.CATEGORY?.includes(catId)) {
			return true;
		}
		return false;
	};
	return (
		<>
			{!disabledFavCat && Array.isArray(favoriteCategories) && favoriteCategories.length > 0 && (
				<>
					<Accordion
						classes={{
							root: styles.accordion__root,
						}}
						expanded={favoriteCategoriesExpanded}
						onChange={handleExpandFavoriteCategories}
					>
						<AccordionSummary
							classes={{
								root: styles.accordionSummary__root,
								content: styles.accordionSummary__content,
							}}
						>
							<div className={styles.summary__content}>
								<IconComponent
									className={styles.expandIcon}
									color="var(--shades-grey-600)"
									icon={(favoriteCategoriesExpanded && icon.faChevronDown) || icon.faChevronRight}
									size="lg"
								/>
								{translate("common:filters.project-categories-filter.my-followed-categories", {
									selected: checkedCount || 0,
									total: filteredFavoriteCategories?.length,
								})}
							</div>
						</AccordionSummary>
						<AccordionDetails>
							{filteredFavoriteCategories.map((favoriteCategory) => {
								const isChecked = handleIsChecked(favoriteCategory.id);
								return (
									<div key={favoriteCategory.id} className={styles.favoriteCategory}>
										<Checkbox
											checked={isChecked}
											size="small"
											onChange={() => handleCheckFavoriteCategory(favoriteCategory, isChecked)}
										/>
										<b>{favoriteCategory.root}</b>
										{favoriteCategory.name}
									</div>
								);
							})}
						</AccordionDetails>
					</Accordion>
					<HorizontalDivider className={styles.horizontalDivider} />
					<div className={styles.projectCategories__title}>
						{translate("common:header.filter.project-categories")}
					</div>
				</>
			)}
			<div>
				{(isTreeLoading && (
					<div className={styles.loaderContainer}>
						<CircularLoader />
					</div>
				)) || (
					<div className={styles.projectCategories__container}>
						{(Array.isArray(categories) &&
							categories.length > 0 &&
							categories.map((category) => (
								<ExpandableCategoryRow
									key={category.id}
									category={category}
									checkedCategories={value?.CATEGORY}
									readOnly={readOnly}
									onCheck={handleCheckCategory}
									onIsChecked={handleIsChecked}
									onToggleExpand={handleToggleExpand}
								/>
							))) || (
							<div className={styles.emptyState}>
								<IconComponent
									className={styles.emptyIcon}
									color="var(--color-blue)"
									icon={icon.faInfoCircle}
									size="lg"
								/>
								{translate("common:filters.project-categories-filter.no-categories")}
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default ProjectCategoriesFilter;
