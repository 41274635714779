import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import styles from "./DeliverableRow.module.css";
import { DeliverablesService } from "../../../../../../../../../api";
import { CustomTooltip } from "../../../../../../../../../common/components";
import { useApi } from "../../../../../../../../../common/hooks";

export default function DeliverableRow({ deliverable, reqId, setSelectedCount, showSelected = false }) {
	const { call: selectDeliverables } = useApi(DeliverablesService.selectDeliverables);
	const { call: unselectDeliverables } = useApi(DeliverablesService.unselectDeliverables);
	const [localChecked, setLocalChecked] = useState(
		deliverable.isCovered === true || deliverable.isCovered === false || false
	);

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		if (isChecked) {
			selectDeliverables({ reqId, deliverableId: deliverable.id }).then(() => {
				setSelectedCount((prev) => prev + 1);
				setLocalChecked(true);
			});
		} else {
			unselectDeliverables({ reqId, deliverableId: deliverable.id }).then(() => {
				setSelectedCount((prev) => prev - 1);
				setLocalChecked(false);
			});
		}
	};

	return !showSelected || (showSelected && localChecked) ? (
		<div className={styles.row}>
			<Checkbox checked={localChecked} onChange={handleCheckboxChange} />
			<div className={styles.row__content} translate="no">
				<CustomTooltip showWhenOverflow title={deliverable.name}>
					<div className={styles.row__documentName}>{deliverable.name}</div>
				</CustomTooltip>
				<CustomTooltip showWhenOverflow title={deliverable.ref}>
					<div className={styles.row__documentRef}>{deliverable.ref}</div>
				</CustomTooltip>
			</div>
		</div>
	) : null;
}
