import client from "../client/client";

class CategoryService {
	getTreeForProject({ projectId, search = "", details = true }, cancelToken) {
		return client.get(`/thematic/project/${projectId}`, {
			params: { search, details },
			config: { cancelToken },
		});
	}

	getTreeParentsForProject({ projectId, details = true }, cancelToken) {
		return client.get(`/thematic/project/${projectId}/parents`, {
			params: { details },
			config: { cancelToken },
		});
	}

	getTreeParentsForWithFilters({ projectId }, { filters }, cancelToken) {
		return client.post(`/thematic/project/${projectId}/details`, {
			payload: filters,
			config: { cancelToken },
		});
	}

	getTreeChildrenForProject({ parentId, details = true }, cancelToken) {
		return client.get(`/thematic/${parentId}/children`, {
			params: { details },
			config: { cancelToken },
		});
	}

	getAllChildrenOfParentForProject({ parentId }, cancelToken) {
		return client.get(`/thematic/${parentId}/children/all`, {
			config: { cancelToken },
		});
	}

	getCountOfAllTreeForProject({ projectId }, cancelToken) {
		return client.get(`/thematic/project/${projectId}/count`, {
			config: { cancelToken },
		});
	}

	getByInformation({ informationId }, { search }, cancelToken) {
		return client.get(`thematic/information/${informationId}`, {
			params: { search },
			config: { cancelToken },
		});
	}

	patchByInformation({ informationId }, payload, cancelToken) {
		return client.patch(`thematic/information/${informationId}`, {
			payload,
			config: { cancelToken },
		});
	}

	createFastTrackAutomation({ projectId }, payload, cancelToken) {
		return client.post(`/thematic/project/${projectId}`, { payload, config: { cancelToken } });
	}

	getThematicAutomations({ thematicId }, cancelToken) {
		return client.get(`/thematic/${thematicId}`, { config: { cancelToken } });
	}

	updateFastTrackAutomation({ thematicId }, payload, cancelToken) {
		return client.patch(`/thematic/${thematicId}`, { payload, config: { cancelToken } });
	}

	patchProjectTreeUpdate({ projectId }, payload, cancelToken) {
		return client.patch(`/thematic/project/${projectId}`, { payload, config: { cancelToken } });
	}

	getRoots({ projectId }, cancelToken) {
		return client.get(`thematic/project/${projectId}/rootTypes`, { config: { cancelToken } });
	}

	export({ projectId }, cancelToken) {
		return client.download(`/thematic/project/${projectId}/export`, {
			config: { cancelToken },
		});
	}

	import({ projectId }, { file }, cancelToken) {
		return client.upload(`/thematic/project/${projectId}/import`, file, {
			config: { cancelToken },
		});
	}

	transferAllProjectThematics({ projectId }, cancelToken) {
		return client.post(`/thematic/project/${projectId}/transfer`, {
			config: { cancelToken },
		});
	}

	transferProjectThematicSelection({ thematicList }, cancelToken) {
		return client.post("/thematic/transfer", {
			payload: thematicList,
			config: { cancelToken },
		});
	}

	getAllIds({ projectId }, cancelToken) {
		return client.get(`/thematic/project/${projectId}/all`, { config: { cancelToken } });
	}
}
export default new CategoryService();
