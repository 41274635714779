import React from "react";
import { DeliverableRow } from "..";
import styles from "./DeliverablesList.module.css";
import { isNonEmptyArray } from "../../../../../../../../../common/utils";

export default function DeliverablesList({ deliverables, reqId, setSelectedCount, showSelected }) {
	return (
		<div className={styles.container}>
			{isNonEmptyArray(deliverables) &&
				deliverables.map((deliverable) => (
					<DeliverableRow
						key={deliverable.id}
						deliverable={deliverable}
						reqId={reqId}
						setSelectedCount={setSelectedCount}
						showSelected={showSelected}
					/>
				))}
		</div>
	);
}
