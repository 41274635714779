export const updateTreeBranch = (
	{ idToReplace, iterativeBranchName, newBranch, tree },
	{ deepUpdate = false, sortKey = "" } = {}
) => {
	let updatedTree = [];
	if (Array.isArray(tree)) {
		if (deepUpdate) {
			updatedTree = JSON.parse(JSON.stringify(tree));
		} else {
			updatedTree = [...tree];
		}
	}
	if (!Array.isArray(updatedTree) || updatedTree.length === 0) {
		return null;
	}
	let found = false;
	updatedTree.forEach((branch, index) => {
		if (found === false) {
			if (branch.id === idToReplace) {
				found = true;
				updatedTree[index] = newBranch;
			} else if (Array.isArray(branch[iterativeBranchName]) && branch[iterativeBranchName].length > 0) {
				updatedTree[index][iterativeBranchName] = updateTreeBranch(
					{
						tree: updatedTree[index][iterativeBranchName],
						iterativeBranchName,
						idToReplace,
						newBranch,
					},
					{ deepUpdate, sortKey }
				);
			}
		}
	});
	if (sortKey) {
		return updatedTree.sort((a, b) => (a[sortKey].toLowerCase() > b[sortKey].toLowerCase() ? 1 : -1));
	}
	return updatedTree;
};
