import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PdfViewer } from "../../../../common/components";
import { useCurrentHeight, useEffectOnce } from "../../../../common/hooks";
import { getPage, getMetadata, resetPagesUpdated, setPage, getDeletedData } from "../../slice/pdf/pdf-slice";
import {
	getVersionStatuses,
	setIsSelecting,
	setSelectedRectangles,
	setSelectionBarCoordinates,
	setSelectionSidePanelOpen,
	setSelectionType,
	setShowVersioning,
	setSidePanel,
} from "../../slice/document/document-slice";
import { ApiService } from "../../../../api";
import { NavUtils } from "../../../../navigation";
import SmartReviewContext from "../../context/smart-review-context";
import Layers from "./components/layers/Layers";
import { checkReqValMode, computeAbsoluteTop } from "../../utils/utils";
import GlobalLayers from "./components/global-layers/GlobalLayers";
import REVIEW_PERMISSIONS from "../../permissions/review-permissions";
import { hasReviewPermissions } from "../../permissions/review-permissions-provider";
import { isNonEmptyArray } from "../../../../common/utils";
import {
	containsClassNames,
	currentPageFromMouse,
	findMatchingImgElement,
	getImageKeyFromAlt,
	isOverlap,
} from "./utils/utils";
import { SyncedDetailsRightPanel } from "../right-panels";
import { CLICK_TYPES } from "../../constants/constants";
import ShorcutEffect from "../effects/shorcut-effect/ShorcutEffect";

const SELECTION_DEAD_ZONE = 10;

export default function DocumentViewer({
	isSelectionPanelOn = false,
	pdfScroll = null,
	selectedMenu = null,
	showScrollbar = true,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cancelTokenSourceRef = useRef(null);
	const firstUpdateMode = useRef(true);
	const firstUpdateHistory = useRef(true);
	const selectionRectangleRef = useRef(null);
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const location = useLocation();
	const [metadataHistory, setMetadataHistory] = useState([]);
	const [additionalData, setAdditionalData] = useState({});
	const [hoveredContent, setHoveredContent] = useState(null);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const roles = useSelector(({ srDocument }) => srDocument?.roles);
	const content = useSelector(({ srPdf }) => srPdf.content);
	const pagesUpdated = useSelector(({ srPdf }) => srPdf.pagesUpdated);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const numberOfPages = useSelector(({ srDocument }) => srDocument.numberOfPages);
	const projectId = useSelector(({ context }) => context.project.id);
	const srPage = useSelector(({ srPdf }) => srPdf.page);
	const zoom = useSelector(({ srPdf }) => srPdf.zoom);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const versionStatuses = useSelector(({ srDocument }) => srDocument.versionStatuses);
	const reloadingPages = useSelector(({ srPdf }) => srPdf.reloadingPages);
	const pageDimensions = useSelector(({ srDocument }) => srDocument.pageDimensions);
	const clauseId = useSelector(({ srProjectDocument }) => srProjectDocument.clauseId);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const selectionSidePanelOpen = useSelector(({ srDocument }) => srDocument.selectionSidePanelOpen);
	const selectionType = useSelector(({ srDocument }) => srDocument.selectionType);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const sidePanelInformationId = useSelector(({ srDocument }) => srDocument.sidePanelInformationId);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const sidePanelOpen = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const sidePanelPage = useSelector(({ srDocument }) => srDocument.sidePanelPage);
	const tabToOpen = useSelector(({ srDocument }) => srDocument.tabToOpen);
	const subTabToOpen = useSelector(({ srDocument }) => srDocument.subTabToOpen);
	const sidePanelIsRequirement = useSelector(({ srDocument }) => srDocument.sidePanelIsRequirement);
	const selectedBlock = useSelector(({ srDocument }) => srDocument.selectedBlock);
	const previousVersion = useSelector(
		({ srDocument }) => srDocument.previousVersionId || srDocument.previousVersionName
	);
	const disableMultiSelection = useMemo(
		() =>
			!hasReviewPermissions(REVIEW_PERMISSIONS.CATEGORY_UPDATE, { roles }) &&
			!hasReviewPermissions(REVIEW_PERMISSIONS.CHANGE_TYPE_NO_REVIEW, { roles }),
		[roles]
	);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffectOnce(
		() => {
			const savedPreferences = SmartReviewContext.getContext()?.preferences;
			if (savedPreferences?.versioning) {
				dispatch(setShowVersioning(savedPreferences.versioning));
			}
			dispatch(getVersionStatuses(cancelTokenSourceRef.current.token));
		},
		[dispatch, versionStatuses],
		() => versionStatuses.length === 0
	);

	/** Updates hovered parameters on parameter change */

	useEffect(
		() => {
			if (
				hoveredContent &&
				hoveredContent?.informationId === selectedBlock?.informationId &&
				hoveredContent?.clientRequirementId !== selectedBlock?.clientRequirementId
			) {
				setHoveredContent({
					...hoveredContent,
					parameters: selectedBlock.parameters,
					clientRequirementId: selectedBlock.clientRequirementId,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedBlock]
	);

	/** Page metadata and image handlers */

	const handleGetMetadata = useCallback(
		(page) => {
			if (!Number.isNaN(page) && !metadataHistory.some((mh) => mh === page)) {
				const foundPageContent = content.find((c) => c.page === page);
				if (!foundPageContent || !foundPageContent[mode]) {
					setMetadataHistory((prev) => [...prev, page]);
					if (cancelTokenSourceRef?.current) {
						dispatch(getMetadata({ mode, page, token: cancelTokenSourceRef.current.token }));
						if (previousVersion) {
							dispatch(getDeletedData({ docId, page, token: cancelTokenSourceRef.current.token }));
						}
					}
				}
			}
		},
		[dispatch, docId, content, mode, metadataHistory, previousVersion]
	);
	useEffect(() => {
		pagesUpdated.forEach((page) => {
			setMetadataHistory((prev) => [...prev, page]);
			dispatch(getMetadata({ mode, page, token: cancelTokenSourceRef.current.token }));
			if (previousVersion) {
				dispatch(getDeletedData({ docId, page, token: cancelTokenSourceRef.current.token }));
			}
		});
		dispatch(resetPagesUpdated());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);
	useEffect(
		() => {
			if (Array.isArray(reloadingPages) && reloadingPages.length > 0) {
				reloadingPages.forEach((page) => {
					if (page > 0 && page <= numberOfPages) {
						dispatch(getMetadata({ mode, page, token: cancelTokenSourceRef.current.token }));
						if (previousVersion) {
							dispatch(getDeletedData({ docId, page, token: cancelTokenSourceRef.current.token }));
						}
						const pageContent = content.find((c) => c.page === page);
						if (pageContent.val) {
							dispatch(getMetadata({ mode: "val", page, token: cancelTokenSourceRef.current.token }));
						}
						if (pageContent.req) {
							dispatch(getMetadata({ mode: "req", page, token: cancelTokenSourceRef.current.token }));
						}
					}
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reloadingPages, numberOfPages, mode, dispatch]
	);
	useLayoutEffect(
		() => {
			if (firstUpdateMode.current) {
				firstUpdateMode.current = false;
				return;
			}
			setMetadataHistory([]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mode]
	);
	useLayoutEffect(
		() => {
			if (firstUpdateHistory.current) {
				firstUpdateHistory.current = false;
				return;
			}
			if (metadataHistory.length === 0) {
				for (let i = srPage - 2; i <= srPage + 2; i++) {
					if (i > 0 && i <= numberOfPages) {
						handleGetMetadata(i);
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[metadataHistory]
	);
	const handleGetPage = useCallback(
		(page) => {
			if (!content.some((c) => c.page === page) && cancelTokenSourceRef?.current) {
				dispatch(getPage({ docId, page, token: cancelTokenSourceRef.current.token }));
			}
		},
		[content, dispatch, docId]
	);
	const handleChangePage = useCallback(
		(page) => {
			dispatch(setPage(page));
			SmartReviewContext.setContext({
				project: projectId,
				document: docId,
				clause: clauseId,
				page: page + 1,
				role: mode,
			});
			navigate(
				NavUtils.goToSmartReview({
					projectId,
					documentId: docId,
					pageStart: page + 1,
					role: mode,
				}),
				{ state: location?.state }
			);
		},
		[clauseId, dispatch, docId, mode, navigate, location, projectId]
	);
	const restrictedContent = useMemo(
		() =>
			(content.filter((c) => c.page - 1 >= srPage - 5 && c.page - 1 < srPage + 5) || []).map((cf) => ({
				...cf,
				top: computeAbsoluteTop(pageDimensions, cf.page, 0, zoom),
			})),
		[content, srPage, pageDimensions, zoom]
	);
	const handleMouseEnterBlock = useCallback((rec) => {
		setHoveredContent(rec);
	}, []);
	const handleRenderLayers = useCallback(
		(props) => (
			<Layers
				{...props}
				additionalData={additionalData}
				hoveredContent={hoveredContent}
				selectionRectangleRef={selectionRectangleRef}
				onMouseEnterBlock={handleMouseEnterBlock}
			/>
		),
		[additionalData, handleMouseEnterBlock, hoveredContent]
	);

	/* ***************** */
	/** CLICK AND SELECT */
	/* ***************** */
	const isClicked = useRef(false);
	const isMoving = useRef(false);
	useEffect(
		() => {
			const handleMouseDown = (e) => {
				e.stopPropagation();
				if (disableMultiSelection) {
					return;
				}
				const isNeutralClick = containsClassNames(e.target, [
					"RequirementLayer_",
					"RequirementVersionOverlay_",
					"SelectionLayer_actionBar_",
				]);
				if (checkReqValMode(mode) && e.button === 0 && !isNeutralClick && !redressingInformation) {
					if (e.ctrlKey || e.metaKey || e.shiftKey) {
						/** Starts ctrl/meta or shiftkey key selection and deselect current focus */
						/** Both methods can be used for the same selection */
						dispatch(setSelectionType((e.shiftKey && CLICK_TYPES.SHIFT) || CLICK_TYPES.CTRL));
					}
					if (!e.ctrlKey && !e.metaKey && !e.shiftKey) {
						if (selectedRectangles.length > 0) {
							/** If there's a selection before click, we reset the selection */
							dispatch(setSelectedRectangles(null));
							dispatch(setSelectionBarCoordinates(null));
						}
						if (isNonEmptyArray(additionalData?.inSelection)) {
							setAdditionalData({});
						}
						/** Signal click for mouseDown  */
						isClicked.current = true;
						/** Save coordinates regardless of future action */
						const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
						/** page below is the page found under the mouse */
						setStart({
							left: e.clientX - parentRect.left,
							top: e.clientY - parentRect.top,
							page:
								parseInt(
									getImageKeyFromAlt(findMatchingImgElement(e.target, "IMG", "alt", "image_")),
									10
								) + 1,
						});
					}
				}
			};
			/** Click and select available if simple click has been initialized and in req or val mode */
			const availableSimpleClick = (e) =>
				checkReqValMode(mode) &&
				isClicked?.current &&
				!e.shiftKey &&
				!e.ctrlKey &&
				!e.metaKey &&
				!redressingInformation;
			const handleMouseMove = (e) => {
				const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
				const newLeft = e.clientX - parentRect.left;
				const newTop = e.clientY - parentRect.top;
				if (
					disableMultiSelection ||
					(start &&
						Math.abs(start.left - newLeft) < SELECTION_DEAD_ZONE &&
						Math.abs(start.top - newTop) < SELECTION_DEAD_ZONE)
				) {
					return;
				}
				if (availableSimpleClick(e) && start && e.button === 0) {
					/** Initialization of mouse moving for click and select and resetters */
					if (!isMoving?.current) {
						isMoving.current = true;
					}
					if (selectionType !== CLICK_TYPES.CLICK) {
						dispatch(setSelectionType(CLICK_TYPES.CLICK));
					}
					if (!isSelecting) {
						dispatch(setIsSelecting(true));
					}
					if (selectedSearch) {
						dispatch(
							setSidePanel({
								sidePanelOpen: false,
								informationId: selectedSearch.informationId,
								page: sidePanelPage,
								tabToOpen,
								subTabToOpen,
								isRequirement: sidePanelIsRequirement,
							})
						);
					}
					/** Save end coordinates only, no further process here */
					setEnd({
						left: newLeft,
						top: newTop,
					});
				}
			};
			const handleMouseUp = (e) => {
				if (disableMultiSelection) {
					return;
				}
				setStart(null);
				setEnd(null);
				if (availableSimpleClick(e) && start && end && isMoving?.current) {
					/** Click and Select Mouse up process if click and move before */
					dispatch(setIsSelecting(false));
					if (additionalData?.inSelection?.length > 0) {
						/** Coordinates where selection options should display */
						const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
						const x = e.clientX - parentRect.left;
						const y = e.clientY - parentRect.top;
						dispatch(setSelectionBarCoordinates({ x, y }));
						/** Dispatch final selection */
						dispatch(setSelectedRectangles(additionalData?.inSelection));
					}
					if (hoveredContent) {
						setHoveredContent(null);
					}
				}
				if (isMoving?.current) {
					isMoving.current = false;
				}
				if (isClicked?.current) {
					isClicked.current = false;
				}
			};
			const handleKeydown = (e) => {
				if (disableMultiSelection) {
					return;
				}
				if (
					checkReqValMode(mode) &&
					((e.shiftKey && selectionType !== CLICK_TYPES.SHIFT) ||
						((e.ctrlKey || e.metaKey) && selectionType === CLICK_TYPES.CTRL))
				) {
					if (selectedRectangles.length > 0) {
						dispatch(setSelectedRectangles(null));
					}
					if (additionalData?.inSelection?.length > 0) {
						const { inSelection, ...rest } = additionalData;
						setAdditionalData(rest);
					}
				}
			};
			const handleDocumentKeydown = (e) => {
				if (disableMultiSelection) {
					return;
				}
				if (checkReqValMode(mode) && e.key === "Escape") {
					if (selectedRectangles.length > 0) {
						dispatch(setSelectedRectangles(null));
					}
					if (additionalData?.inSelection?.length > 0) {
						const { inSelection, ...rest } = additionalData;
						setAdditionalData(rest);
					}
					if (isSelecting) {
						dispatch(setIsSelecting(false));
						setStart(null);
						setEnd(null);
					}
				}
				const shortcut = /^[a-zA-Z]$/.test(e.key);
				if ((e.ctrlKey || e.metaKey || e.shiftKey) && shortcut) {
					if (isSelecting) {
						dispatch(setIsSelecting(false));
					}
					dispatch(setSelectedRectangles(null));
				}
			};
			if (selectedRectangles.length === 0 && selectionSidePanelOpen) {
				dispatch(setSelectionSidePanelOpen(false));
			}
			const pdfViewerList = document.getElementById("pdfViewerList_id");
			pdfViewerList.addEventListener("mousedown", handleMouseDown);
			pdfViewerList.addEventListener("mousemove", handleMouseMove);
			pdfViewerList.addEventListener("mouseup", handleMouseUp);
			pdfViewerList.addEventListener("keydown", handleKeydown);
			document.addEventListener("keydown", handleDocumentKeydown);
			return () => {
				pdfViewerList.removeEventListener("mousedown", handleMouseDown);
				pdfViewerList.removeEventListener("mousemove", handleMouseMove);
				pdfViewerList.removeEventListener("mouseup", handleMouseUp);
				pdfViewerList.removeEventListener("keydown", handleKeydown);
				document.removeEventListener("keydown", handleDocumentKeydown);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, isSelecting, selectionType, mode, start, end, selectedRectangles, redressingInformation]
	);

	/** Rectangle in Selection computation */
	useEffect(
		() => {
			if (end && start?.page) {
				/** Determines last page where mouse currently is */
				const lastHoveredPage = currentPageFromMouse(pageDimensions, end?.top || 0, zoom);
				const targetRect = selectionRectangleRef.current?.getBoundingClientRect();
				const minP = Math.min(start.page, lastHoveredPage);
				const maxP = Math.max(start.page, lastHoveredPage);
				/** Processing each page between start and end only */
				const selectedAreas = [];
				for (let i = minP; i <= maxP; i++) {
					// Construct the ID in the "row_${number}_pdf_viewer" pattern
					const divId = `row_${i}_pdf_viewer`;
					// Find the <div> element with the specified ID
					const div = document.getElementById(divId);
					// If the <div> element exists, find <area> elements
					// with class names that start with "RenderRectangle_area__"
					// and check overlapping ones
					if (div) {
						// eslint-disable-next-line
						const areasInDiv = div.querySelectorAll(`area[class^="RenderRectangle_area__"]`);
						areasInDiv.forEach((area) => {
							const areaRect = area.getBoundingClientRect();
							if (isOverlap(areaRect, targetRect)) {
								selectedAreas.push(area);
							}
						});
					}
				}
				// Construction of needed selected data
				setAdditionalData({
					inSelection: selectedAreas.map((ardi) => ({
						informationId: parseInt(ardi.getAttribute("data-information-id"), 10),
						rectangles: JSON.parse(ardi.getAttribute("data-rectangles")).map((item) => {
							const [order, contentId] = item.split(":");
							return { order: parseInt(order, 10), contentId: parseInt(contentId, 10) };
						}),
						pageStart: parseInt(ardi.getAttribute("data-page"), 10),
						isReq: JSON.parse(ardi.getAttribute("data-req-content")),
						contentId: parseInt(ardi.getAttribute("alt"), 10),
						lastStatus: ardi.getAttribute("data-last-status"),
						validatedStatus: ardi.getAttribute("data-last-validated-status"),
					})),
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[start, end]
	);

	/* ******* */
	/* DISPLAY */
	/* ******* */
	const handleRenderAdditionalPanel = useCallback(() => {
		if (selectedMenu === 0 && !isSelectionPanelOn && !(sidePanelOpen && sidePanelInformationId)) {
			return <SyncedDetailsRightPanel hoveredBlock={hoveredContent} onMouseEnterBlock={handleMouseEnterBlock} />;
		}
		return null;
	}, [
		selectedMenu,
		isSelectionPanelOn,
		sidePanelInformationId,
		sidePanelOpen,
		handleMouseEnterBlock,
		hoveredContent,
	]);

	const handleRenderGlobalLayers = useCallback(
		() => <GlobalLayers end={end} selectionRectangleRef={selectionRectangleRef} start={start} />,
		[end, start]
	);

	return (
		<>
			<ShorcutEffect hoveredContent={hoveredContent} onResetHovered={() => setHoveredContent(null)} />
			<PdfViewer
				content={restrictedContent}
				height={useCurrentHeight({ difference: (cautionBannerDisabled && -193) || -233 })}
				numberOfPages={numberOfPages}
				pageDimensions={pageDimensions}
				scrollRef={pdfScroll}
				showScrollbar={showScrollbar}
				zoom={zoom}
				onGetMetadata={handleGetMetadata}
				onGetPage={handleGetPage}
				onRenderAdditionalPanel={handleRenderAdditionalPanel}
				onRenderGlobalOverlay={handleRenderGlobalLayers}
				onRenderLayers={handleRenderLayers}
				onSetPage={handleChangePage}
			/>
		</>
	);
}
