import { hasProperty } from "../utils";

let flags = null;

export const FLAGS = {
	ACTION_AUTO: "ACTION_AUTO",
	ADMIN: "ADMIN",
	ADMIN_COMPANY: "ADMIN_COMPANY",
	ANALYTICALAXE: "ANALYTICALAXE",
	BACK_OFFICE: "BACK_OFFICE",
	CONTROL: "CONTROL",
	COVERAGE: "COVERAGE",
	DOCUMENT_CENTER: "DOCUMENT_CENTER",
	NORMS: "NORMS",
	NOT_IN_PRODUCTION: "NOT_IN_PRODUCTION",
	OPERATION: "OPERATION",
	OPPORTUNITY: "OPPORTUNITY",
	PARTNERS: "PARTNERS",
	PROJECT_CREATION_ONBOARDING: "PROJECT_CREATION_ONBOARDING",
	QA: "QA",
	REDRESSMENT: "REDRESSMENT",
	REQUIREMENT_MATRIX: "REQUIREMENT_MATRIX",
	REQ_REVIEW: "REQ_REVIEW",
	REQ_VALIDATION: "REQ_VALIDATION",
	SEARCHCARD: "SEARCHCARD",
	SEARCHCARD_ACTIONS: "SEARCHCARD_ACTIONS",
	SEARCHTEMPLATE: "SEARCHTEMPLATE",
	SEARCH_BAR: "SEARCH_BAR",
	SELECT_SYNTHESIS: "SELECT_SYNTHESIS",
	SR_COMMENTS: "SR_COMMENTS",
	SR_PANEL_POSITIONS: "SR_PANEL_POSITIONS",
	SR_QUESTIONS: "SR_QUESTIONS",
	TASK: "TASK",
	TEMPLATE_ACTION_AUTO: "TEMPLATE_ACTION_AUTO",
	THEMATIC: "THEMATIC",
	THEMATIC_TEMPLATE: "THEMATIC_TEMPLATE",
	MENTIONS: "MENTIONS",
	SR_HISTORY: "SR_HISTORY",
	SIMILAR_CONTENTS: "SIMILAR_CONTENTS",
	SPP: "SPP",
	INFORMATION_LINKS: "INFORMATION_LINKS",
	PROJECT_CHAT: "PROJECT_CHAT",
};
export const setFlags = (flgs) => {
	flags = flgs;
};
export const getFlags = () => flags;
export function isFeatureEnabled(flag) {
	if (!flag || !flags) {
		return false;
	}
	if (Array.isArray(flag) && flag.length > 0) {
		return flag.every((f) => !!isFeatureEnabled(f));
	}
	return hasProperty(flags, flag) && flags[flag];
}
