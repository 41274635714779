import React, { useEffect, useRef, useState } from "react";
import { CompaniesList, ProjectsList, ProjectDetails, AddUserDialog, AddPartnerDialog } from "../../components";
import { BackOfficeService, ApiService } from "../../../../api";
import styles from "./DSIProjects.module.css";
import { CustomDialog, createNotification } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { downloadFile, isNonEmptyArray } from "../../../../common/utils";
import { useEffectOnce } from "../../../../common/hooks";

export default function DSIProjects({
	companies,
	companyRolesInProject,
	userRolesInProject,
	onSelectCompany,
	onUpateCompanyRoleInProject,
	onAddExistingPartnerToProject,
	onAddExistingUserToProject,
	onUpdateUserStatusInProject,
}) {
	const [downloading, setDownloading] = useState(false);
	const [projectAction, setProjectAction] = useState(null);
	const [projectReqDetection, setProjectReqDetection] = useState(null);
	const [projects, setProjects] = useState([]);
	const cancelTokenSourceRef = useRef(null);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
	const [openAddPartnerDialog, setOpenAddPartnerDialog] = useState(false);
	const [openRelaunchDialog, setOpenRelaunchDialog] = useState(false);
	const [openReqDetectDialog, setOpenReqDetectDialog] = useState(false);
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		BackOfficeService.getAllProjects(cancelTokenSourceRef.current.token)
			.then((data) => setProjects(data))
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleSelectCompany = (company) => {
		if (company !== selectedCompany) {
			onSelectCompany(company);
			setSelectedCompany(company);
			setSelectedProject(null);
		}
	};

	useEffectOnce(
		() => {
			handleSelectCompany(companies[0]);
		},
		[],
		() => selectedCompany === null && isNonEmptyArray(companies)
	);

	const handleSelectProject = (project) => {
		if (project !== selectedProject) {
			const foundProject = selectedCompany.projects.find((p) => p.id === project.id) || {};
			setSelectedProject({ ...project, ...foundProject });
			setProjectReqDetection(project.reqDetection);
		}
	};
	const handleChangeCompanyRole = ({ role, company }) => {
		if (!role || !company || !selectedProject) {
			return;
		}
		onUpateCompanyRoleInProject({ company, project: selectedProject, role });
	};
	const handleOnCloseReqDetectionDialog = () => setOpenReqDetectDialog(false);
	const handleReqDetection = () => {
		setProjectReqDetection(!projectReqDetection);
		BackOfficeService.updateProjectReqDetection(
			{ projectId: projectAction.id },
			cancelTokenSourceRef.current.token
		);
		handleOnCloseReqDetectionDialog();
	};
	const onReqDetection = (project) => {
		setProjectAction(project);
		setOpenReqDetectDialog(true);
	};
	const handleAddUser = (user) => {
		if (!projectAction || !user) {
			return;
		}
		onAddExistingUserToProject({ company: selectedCompany, user, project: projectAction });
	};
	const handleUpdateUserStatusInProject = ({ selectedUser, selectedStatus }) => {
		onUpdateUserStatusInProject({
			company: selectedCompany,
			selectedUser,
			project: selectedProject,
			selectedStatus,
		});
	};
	const onAddUser = (project) => {
		setProjectAction(project);
		setOpenAddUserDialog(true);
	};
	const handleAddPartner = (partner) => {
		if (!projectAction || !partner) {
			return;
		}
		onAddExistingPartnerToProject({ selectedCompany, project: projectAction, company: partner });
	};
	const onAddPartner = (project) => {
		setProjectAction(project);
		setOpenAddPartnerDialog(true);
	};
	const handleDownload = (project) => {
		setDownloading(true);
		BackOfficeService.downloadProject({ projectId: project.id }, cancelTokenSourceRef.current.token)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
			.catch((err) => console.error(err))
			.finally(() => setDownloading(false));
	};
	const handleOnCloseRelaunchDialog = () => setOpenRelaunchDialog(false);
	const handleRelaunchDocs = () => {
		if (!projectAction) {
			return;
		}
		BackOfficeService.restartProjectFailedDocuments(
			{ projectId: projectAction.id },
			cancelTokenSourceRef.current.token
		).then((data) => {
			handleOnCloseRelaunchDialog();
			createNotification({
				message: translate("dsi.platform-management.restart-document-parsing.success", {
					docs: data.value,
				}),
			});
		});
	};
	const onRelaunchDocs = (project) => {
		setProjectAction(project);
		setOpenRelaunchDialog(true);
	};
	const handleOnCloseAddUserDialog = () => setOpenAddUserDialog(false);
	const handleOnCloseAddPartnerDialog = () => setOpenAddPartnerDialog(false);
	const handleChangeDetails = (details) => {
		setProjects((prev) => prev.map((p) => (p.id === details.id && { ...p, ...details }) || p));
	};
	return (
		<>
			<div className={styles["left-panel"]}>
				<CompaniesList
					companies={companies || []}
					selectedCompany={selectedCompany}
					onClickRow={handleSelectCompany}
				/>
			</div>
			<div className={styles["middle-panel"]}>
				<ProjectsList
					projects={
						(Array.isArray(projects) &&
							projects.length > 0 &&
							Array.isArray(selectedCompany?.projects) &&
							selectedCompany?.projects?.length > 0 &&
							projects.filter((p) => selectedCompany.projects.some((sp) => sp.id === p.id))) ||
						[]
					}
					onAddPartner={onAddPartner}
					onAddUser={onAddUser}
					onClickRow={handleSelectProject}
					onDownload={handleDownload}
					onRelaunchDocs={onRelaunchDocs}
				/>
			</div>
			<ProjectDetails
				companyId={selectedCompany?.id}
				project={selectedProject}
				projectReqDetection={projectReqDetection}
				roles={companyRolesInProject}
				onChangeCompanyRole={handleChangeCompanyRole}
				onChangeDetails={handleChangeDetails}
				onChangeReqDetection={onReqDetection}
				onClose={() => {
					setSelectedProject(null);
				}}
				onUpdateStatus={handleUpdateUserStatusInProject}
			/>
			<AddUserDialog
				open={openAddUserDialog}
				roles={userRolesInProject}
				users={selectedCompany && selectedCompany.users}
				onClose={handleOnCloseAddUserDialog}
				onSubmit={handleAddUser}
			/>
			<AddPartnerDialog
				companies={(selectedCompany && companies.filter((cny) => cny.id !== selectedCompany.id)) || []}
				open={openAddPartnerDialog}
				onClose={handleOnCloseAddPartnerDialog}
				onSubmit={handleAddPartner}
			/>
			<CustomDialog
				loader
				maxWidth="xs"
				open={downloading}
				subTitle={translate("dsi.project-list.action.download-project-docs-loading")}
				title={translate("dsi.project-list.action.download-project-docs-process")}
			/>
			<CustomDialog
				open={openRelaunchDialog}
				subTitle={translate("dsi.project-list.action.restart-project-document-parsing.confirm", {
					project: projectAction?.name,
				})}
				title={translate("dsi.project-list.action.restart-project-document-parsing")}
				onClose={handleOnCloseRelaunchDialog}
				onSubmit={handleRelaunchDocs}
			/>
			<CustomDialog
				open={openReqDetectDialog}
				subTitle={translate("dsi.project-details.req-detection-dialog-confirm")}
				title={translate("dsi.project-details.req-detection-dialog")}
				onClose={handleOnCloseReqDetectionDialog}
				onSubmit={handleReqDetection}
			/>
		</>
	);
}
