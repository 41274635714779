import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Divider } from "@mui/material";
import {
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
	IconComponent,
	TextMidCut,
	VerticalDivider,
	icon,
} from "../..";
import styles from "./CustomDialog.module.css";
import { translate } from "../../../providers";
import { CustomButton, CustomIconButton } from "../../buttons";
import Loader from "../../../../navigation/routes/components/Loader";

export default function CustomDialog({
	children,
	checkboxLabel,
	checked = false,
	className = "",
	classNameDialog,
	closeLabel,
	disableBackdropClick = false,
	disableEscapeKeyDown = false,
	displayCloseCross = false,
	hideCloseButton = false,
	iconColor = "",
	iconModel = null,
	isForm = false,
	loader = false,
	maxWidth = "sm",
	onChangeCheckbox,
	onClose,
	onExited,
	onSkip,
	onSubmit,
	open = false,
	submitDisabled = false,
	submitLabel,
	subTitle,
	title,
	"data-testid": dataTestId = null,
}) {
	return (
		<GenericDialog
			fullWidth
			className={classNameDialog}
			disableBackdropClick={disableBackdropClick}
			disableEscapeKeyDown={disableEscapeKeyDown}
			maxWidth={maxWidth}
			open={open}
			onClose={onClose}
			onExited={onExited}
		>
			<div className={styles.customDialog}>
				{(iconModel || loader) && (
					<div className={styles.customDialog__icon}>
						{(loader && <Loader />) || <IconComponent color={iconColor} icon={iconModel} size="5xl" />}
						<VerticalDivider className={styles.divider} />
					</div>
				)}
				<div className={styles.customDialog__root}>
					<GenericDialogHeader
						className={isForm ? styles.customDialog__titleForm : styles.customDialog__titleInfo}
					>
						<TextMidCut text={title} tooltip={title} />
						{displayCloseCross && (
							<CustomIconButton
								className={styles.customDialog__closeCross}
								icon={icon.faTimes}
								onClick={onClose}
							/>
						)}
						{isForm && <Divider className={styles.customDialog__dividerForm} />}
					</GenericDialogHeader>
					<GenericDialogContent
						className={`${styles.customDialog__container} ${className}`}
						data-testid={dataTestId}
					>
						{subTitle && <div className={styles.customDialog__subTitle}>{subTitle}</div>}
						<div className={styles.customDialog__content}>{children}</div>
					</GenericDialogContent>
					<GenericDialogFooter className={styles.customDialog__actions}>
						{typeof onChangeCheckbox === "function" && (
							<div className={styles.checkbox}>
								<Checkbox checked={checked} color="primary" onChange={onChangeCheckbox} />
								{checkboxLabel || translate("common:dialog.no-show-choice")}
							</div>
						)}
						{onClose && !hideCloseButton && (
							<CustomButton color="secondary" variant="outlined" onClick={onSkip || onClose}>
								{closeLabel || translate("common:btn.cancel")}
							</CustomButton>
						)}
						{onSubmit && (
							<CustomButton
								color="primary"
								data-testid={dataTestId}
								disabled={submitDisabled}
								variant="contained"
								onClick={onSubmit}
							>
								{submitLabel || translate("common:btn.submit")}
							</CustomButton>
						)}
					</GenericDialogFooter>
				</div>
			</div>
		</GenericDialog>
	);
}
CustomDialog.propTypes = {
	maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
CustomDialog.defaultProps = {
	maxWidth: "sm",
};
