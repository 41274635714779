import client from "../client/client";

class AdminService {
	getCompanyTree(cancelToken) {
		return client.get("/admin/companies/", { config: { cancelToken } });
	}

	getCompanyProjects({ companyId }, cancelToken) {
		return client.get(`/admin/companies/${companyId}/projects`, { config: { cancelToken } });
	}

	getCompanyUsers({ companyId }, cancelToken) {
		return client.get(`/admin/companies/${companyId}/users`, { config: { cancelToken } });
	}

	createCompanyUser({ firstname, lastname, email, role }, { companyId }, cancelToken) {
		return client.post(`/admin/companies/${companyId}/users`, {
			payload: { firstname, lastname, email, role },
			config: { cancelToken },
		});
	}

	lockUser({ companyId, userId }, reason, cancelToken) {
		return client.post(`/admin/companies/${companyId}/users/${userId}/lock`, {
			payload: { value: reason },
			config: { cancelToken },
		});
	}

	unlockUser({ companyId, userId }, cancelToken) {
		return client.delete(`/admin/companies/${companyId}/users/${userId}/unlock`, { config: { cancelToken } });
	}

	updateProjectStatus({ companyId, projectId }, { name, currentStatus }, cancelToken) {
		return client.patch(`/admin/companies/${companyId}/projects/${projectId}`, {
			payload: { name, currentStatus },
			config: { cancelToken },
		});
	}

	updateRoleCompany({ companyId, userId }, role, cancelToken) {
		return client.patch(`/admin/companies/${companyId}/users/${userId}/roles`, {
			payload: role,
			config: { cancelToken },
		});
	}

	updateProjectName({ companyId, projectId }, { name, currentStatus }, cancelToken) {
		return client.patch(`/admin/companies/${companyId}/projects/${projectId}/rename`, {
			payload: { name, currentStatus },
			config: { cancelToken },
		});
	}
}
export default new AdminService();
