import { Checkbox } from "@mui/material";
import React from "react";
import { CustomTable, CustomTableHead, CustomTableRow, CustomTableCell, CustomTableBody } from "..";
import Loader from "../../../../navigation/routes/components/Loader";
import { translate } from "../../../providers";
import { CustomButton } from "../../buttons";
import { I18nTranslate } from "../../i18n";
import { icon } from "../../icon-component";
import styles from "./UsersTable.module.css";
import { EmptyState } from "../../empties";

export default function UsersTable({
	checkedUsers,
	className = "",
	emptyStateButton,
	isLoading,
	onCheckAll,
	onCheckUser,
	onOpenDialog,
	searchText = "",
	users,
}) {
	return (
		<div className={className}>
			<CustomTable>
				<CustomTableHead>
					<CustomTableRow className={styles.row}>
						<CustomTableCell>
							<Checkbox
								checked={checkedUsers.length === users.length && users.length !== 0}
								color="primary"
								onChange={onCheckAll}
							/>
							{translate("common:components.users-table.header.all")}
						</CustomTableCell>
						<CustomTableCell>{translate("common:components.users-table.header.email")}</CustomTableCell>
						<CustomTableCell>{translate("common:components.users-table.header.role")}</CustomTableCell>
						<CustomTableCell>
							{translate("common:components.users-table.header.last-activity")}
						</CustomTableCell>
					</CustomTableRow>
				</CustomTableHead>
				<CustomTableBody>
					{(Array.isArray(users) &&
						!isLoading &&
						users.length > 0 &&
						users.map((user) => (
							<CustomTableRow key={user.email} className={styles.row}>
								<CustomTableCell className={styles["name-cell"]}>
									<Checkbox
										checked={checkedUsers.some((u) => u.email === user.email)}
										color="primary"
										onChange={() => onCheckUser(user)}
									/>
								</CustomTableCell>
								<CustomTableCell autoTranslate="no" className={styles["name-cell"]}>
									{user.email}
								</CustomTableCell>
								<CustomTableCell autoTranslate="no" className={styles["role-cell"]}>
									{user.role}
								</CustomTableCell>
								<CustomTableCell className={styles["last-activity-cell"]}>
									{user.lastActivity}
								</CustomTableCell>
							</CustomTableRow>
						))) ||
						(searchText && !isLoading && (
							<CustomTableRow>
								<CustomTableCell align="center" className={styles["empty-row"]} colSpan={4}>
									<I18nTranslate
										className={styles["empty-row__text"]}
										param={{ searchText }}
										translationKey="common:components.users-table.empty"
									/>
									<CustomButton startIcon={icon.faPlus} variant="outlined" onClick={onOpenDialog}>
										{translate("common:components.users-table.empty.add-button")}
									</CustomButton>
								</CustomTableCell>
							</CustomTableRow>
						))}
				</CustomTableBody>
			</CustomTable>
			{!isLoading && Array.isArray(users) && users.length === 0 && !searchText && (
				<EmptyState
					btn1={emptyStateButton()}
					className={styles["empty-state"]}
					title={translate("team-management.members.side-panel.empty-state.title")}
				/>
			)}
			{isLoading && (
				<div className={styles["loader-container"]}>
					<Loader />
				</div>
			)}
		</div>
	);
}
