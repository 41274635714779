import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import styles from "./RequirementList.module.css";
import { I18nTranslate, InfiniteList2, generateFilters } from "../../../../../common/components";
import { RequirementMatrixService } from "../../../../../api";
import RequirementRow from "./RequirementRow";
import { useApi } from "../../../../../common/hooks";

const RequirementList = ({
	allChecked,
	checkedRows = [],
	disabledCheckBox = false,
	filters,
	onCheck,
	onCheckAll,
	onSetSelectedCount,
	selectedCount,
}) => {
	const { call: searchRequirementsInProject } = useApi(RequirementMatrixService.searchRequirementsInProject);
	const { call: getRequirementsInProjectCount } = useApi(RequirementMatrixService.getRequirementsInProjectCount);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [totalResults, setTotalResults] = useState(0);
	const [totalMatches, setTotalMatches] = useState(0);

	useEffect(() => {
		if (projectId) {
			getRequirementsInProjectCount({ projectId }, {}).then((data) => setTotalResults(data));
		}
	}, [getRequirementsInProjectCount, projectId]);
	useEffect(() => {
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					searchRequirementsInProject({ projectId }, generateFilters(filters), { page, limit })
		);
	}, [searchRequirementsInProject, filters, projectId]);
	useEffect(() => {
		onSetSelectedCount(allChecked ? totalMatches - checkedRows.length : checkedRows.length);
	}, [onSetSelectedCount, checkedRows.length, allChecked, totalMatches]);

	const rowRenderer = (row) => (
		<RequirementRow
			key={row.id}
			allChecked={allChecked}
			checkedRows={checkedRows}
			disabledCheckBox={disabledCheckBox}
			row={row}
			onCheck={onCheck}
		/>
	);
	const handleResponse = (data) => {
		setTotalMatches(data.totalElements);
	};
	const handlePlural = (elements, string) => {
		const translateType = "common:results.count-";
		if (elements > 1) {
			return translateType.concat(string);
		}
		return translateType.concat("single-", string);
	};
	return (
		<div className={styles.container}>
			<div>
				<Checkbox
					checked={selectedCount !== 0}
					disabled={disabledCheckBox}
					indeterminate={selectedCount !== 0 && selectedCount < totalMatches}
					onChange={onCheckAll}
				/>
				{(selectedCount === 0 && (
					<I18nTranslate
						param={{ totalMatches, totalResults }}
						translationKey={handlePlural(totalMatches, "match")}
					/>
				)) || (
					<I18nTranslate
						param={{
							totalMatches,
							nbSelected: selectedCount,
						}}
						translationKey={handlePlural(selectedCount, "selected-match")}
					/>
				)}
			</div>
			<InfiniteList2
				callOnResponse={handleResponse}
				className={styles.infiniteList}
				request={infiniteListRequest}
				rowRenderer={rowRenderer}
				scrollContainerClassName={styles.scrollContainer}
			/>
		</div>
	);
};

export default RequirementList;
