import client from "../client/client";

class SearchCardTemplateService {
	createTemplate(payload, cancelToken) {
		return client.post("/search-templates", {
			payload: JSON.stringify(payload),
			config: { cancelToken },
		});
	}

	getTemplates({ page = 0, limit = 25 }, { filters }, cancelToken) {
		return client.post("/search-templates/all", {
			payload: filters,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	addTemplatesToProject({ projectId }, payload, cancelToken) {
		return client.post(`/search-templates/projects/${projectId}`, {
			config: { cancelToken },
			payload,
		});
	}

	addTemplatesToProjectByFilter({ projectId }, payload, cancelToken) {
		return client.patch(`/search-templates/projects/${projectId}`, {
			config: { cancelToken },
			payload,
		});
	}

	countTemplateResults({ projectId, templateId }, payload, cancelToken) {
		return client.get(`/search-templates/${templateId}/projects/${projectId}/count`, {
			config: { cancelToken },
			payload,
		});
	}

	countResults({ projectId }, payload, cancelToken) {
		return client.post(`/search-templates/projects/${projectId}/count`, {
			config: { cancelToken },
			payload,
		});
	}

	templateSearch({ projectId }, filters, { page = 0, limit = 25 }, cancelToken) {
		return client.post(`/search-templates/projects/${projectId}/search`, {
			config: { cancelToken },
			payload: filters,
			params: { page, limit },
		});
	}

	getTemplateDetails({ searchTemplateId }, cancelToken) {
		return client.get(`/search-templates/${searchTemplateId}`, {
			config: { cancelToken },
		});
	}

	deleteTemplate({ templateId }, cancelToken) {
		return client.delete(`/search-templates/${templateId}`, {
			config: { cancelToken },
		});
	}

	updateTemplateDetails({ searchTemplateId }, payload, cancelToken) {
		return client.patch(`/search-templates/${searchTemplateId}`, {
			config: { cancelToken },
			payload,
		});
	}

	getTemplateFilters({ id }, cancelToken) {
		return client.get(`/search-templates/${id}/filters`, {
			config: { cancelToken },
		});
	}

	updateTemplateFilters({ searchTemplateId }, payload, cancelToken) {
		return client.patch(`/search-templates/${searchTemplateId}/filters`, {
			config: { cancelToken },
			payload,
		});
	}

	getProjects(cancelToken, { page = 0, limit = 25 }) {
		return client.get("/search-templates/projects", {
			config: { cancelToken },
			params: { page, limit },
		});
	}

	getAuthors({ companyId }, { page = 0, limit = 25, filter = "" }, cancelToken) {
		return client.get(`/search-templates/companies/${companyId}/users/search`, {
			config: { cancelToken },
			params: { page, limit, filter },
		});
	}

	getCountOfAllSearchTemplateAutomation(cancelToken) {
		return client.get("/search-templates/count/automation", {
			config: { cancelToken },
		});
	}
}
export default new SearchCardTemplateService();
