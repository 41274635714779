import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavUtils, exportView } from "../../../navigation";
import { AnalyticsProvider, Flags, SegFlags, translate } from "../../../common/providers";
import {
	CustomButton,
	CustomButtonLink,
	HorizontalDivider,
	ViewBanner,
	cleanFilters,
	icon,
} from "../../../common/components";
import styles from "./Coverage.module.css";
import { PhasesTree, RequirementList, ExportDialog, CoverageFilters } from "./components";
import { isNonEmptyArray } from "../../../common/utils";
import { useApi } from "../../../common/hooks";
import { DocumentOutCoverageService } from "../../../api";

const NewCoverage = () => {
	const { call: getDeliverableCount } = useApi(DocumentOutCoverageService.getDeliverableCount);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [openExportDialog, setOpenExportDialog] = useState(false);
	const [filters, setFilters] = useState({ isExcluded: false, isToBeTested: false, isNotAllocated: false });
	const [checkedRows, setCheckedRows] = useState([]);
	const [allChecked, setAllChecked] = useState(false);
	const [selectedCount, setSelectedCount] = useState(0);
	const [deliverablesCount, setDeliverablesCount] = useState(0);
	const informationFilters = useMemo(() => {
		if (isNonEmptyArray(checkedRows)) {
			return { informationIds: { matchAll: false, is: !allChecked, elements: checkedRows } };
		}
		return {};
	}, [allChecked, checkedRows]);
	useEffect(() => {
		const documentTitle = translate("coverage.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Assign to deliverables" });
	}, []);
	useEffect(() => {
		if (projectId) {
			getDeliverableCount({ projectId })
				.then((data) => setDeliverablesCount(data))
				.catch(console.error);
		}
	}, [getDeliverableCount, projectId]);
	const handleSearch = (payload) => {
		setFilters(cleanFilters(payload));
		setAllChecked(false);
		setCheckedRows([]);
	};
	const handleOpenExportDialog = () => {
		setOpenExportDialog((prev) => !prev);
	};
	const handleCheck = (id) => {
		if (checkedRows.includes(id)) {
			setCheckedRows((prev) => prev.filter((p) => p !== id));
		} else {
			setCheckedRows((prev) => [...prev, id]);
		}
	};
	const handleChekAll = () => {
		setCheckedRows([]);
		setAllChecked((prev) => !prev);
	};
	const exportButton = (
		<CustomButton color="primary" startIcon={icon.faUpload} variant="contained" onClick={handleOpenExportDialog}>
			{translate("common:btn.export")}
		</CustomButton>
	);
	return (
		<>
			<ViewBanner
				options={[exportButton]}
				titles={[
					{ title: translate("navigation:project.follow-up"), key: "followUp" },
					{ title: translate("navigation:project.deliverables"), key: "deliverables" },
					{ title: translate("navigation:project.assign-to-deliverables") },
				]}
			/>
			<CoverageFilters defaultFilters={filters} onClickSearch={handleSearch} />
			<HorizontalDivider className={styles.divider} />
			<div className={styles.container} data-caution-banner-disabled={cautionBannerDisabled}>
				<div className={styles.leftSide__container}>
					<RequirementList
						allChecked={allChecked}
						checkedRows={checkedRows}
						disabledCheckBox={deliverablesCount === 0}
						filters={filters}
						selectedCount={selectedCount}
						onCheck={handleCheck}
						onCheckAll={handleChekAll}
						onSetSelectedCount={setSelectedCount}
					/>
				</div>
				<div className={styles.rightSide__container}>
					{(deliverablesCount > 0 && (
						<PhasesTree filters={{ ...filters, ...informationFilters }} selectedCount={selectedCount} />
					)) || (
						<div className={styles.noDeliverables__container}>
							<div>{translate("coverage.no-deliverable.empty-state")}</div>
							<CustomButtonLink to={NavUtils.goToDeliverablesToBeProduced(projectId)} variant="contained">
								{translate("navigation:project.deliverables-to-be-produced")}
							</CustomButtonLink>
						</div>
					)}
				</div>
			</div>
			<ExportDialog filters={filters} open={openExportDialog} onClose={handleOpenExportDialog} />
		</>
	);
};

export default exportView({
	path: "/projects/:projectId/follow-up/deliverables/assign-to-deliverables",
	localesPath: "/produce/coverage/locales",
	component: NewCoverage,
	flag: Flags.COVERAGE,
	segFlag: SegFlags.DELIVERABLES,
});
