import React, { useEffect, useState } from "react";
import { exportView } from "../../navigation";
import { ViewBanner } from "../../common/components";
import { AnalyticsProvider, Flags, translate } from "../../common/providers";
import styles from "./MultiProjectSearch.module.css";
import { Search, IdentifiedContents } from "./components";
import { SimilarContentService } from "../../api";
import { useApi } from "../../common/hooks";

const MultiProjectSearch = () => {
	const { call: onGetMostRelevantDeliverables } = useApi(SimilarContentService.getMostRelevantDeliverables);
	const [filters, setFilters] = useState({ value: "" });
	const [deliverables, setDeliverables] = useState([]);
	const [loadingDeliverables, setLoadingDeliverables] = useState(false);
	useEffect(() => {
		const documentTitle = translate("multi-project-search.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Multi project search (Deliverables)" });
	}, []);
	const handleApplyFilter = (newFilters) => {
		setFilters(newFilters);
	};
	useEffect(() => {
		if (filters?.value?.length > 0) {
			setLoadingDeliverables(true);
			onGetMostRelevantDeliverables({ topK: 50 }, filters.value)
				.then((data) => setDeliverables(data))
				.catch((err) => console.error(err))
				.finally(() => setLoadingDeliverables(false));
		}
	}, [filters, onGetMostRelevantDeliverables]);

	return (
		<>
			<ViewBanner titles={[{ title: translate("navigation:common.multi-project-search") }]} />
			<div className={styles.mainContainer}>
				<div className={styles.innerContainer}>
					<Search filters={filters} onApplyFilter={handleApplyFilter} />
					<IdentifiedContents
						deliverables={deliverables}
						filters={filters}
						loadingDeliverables={loadingDeliverables}
					/>
				</div>
			</div>
		</>
	);
};

export default exportView({
	path: "/multi-project-search",
	localesPath: "/multi-project-search/locales",
	component: MultiProjectSearch,
	flag: Flags.SPP,
});
