import { Checkbox } from "@mui/material";
import React, { useMemo } from "react";
import {
	CustomButton,
	CustomIconButton,
	CustomTableCell,
	CustomTableRow,
	I18nTranslate,
	icon,
	KnowledgeBasePopOver,
	VerticalDivider,
} from "../../../../common/components";
import { Flags, isFeatureEnabled, isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import styles from "./DocumentCenterTable.module.css";

const DocumentTableHeader = ({
	activeSort,
	direction,
	documentInPageCount,
	dragOver,
	hasActionPermission,
	hasInProgress,
	hasUnsupported,
	totalElements,
	onDownload,
	onOpenMoveDialog,
	onOpenDeleteDialog,
	onSelectAll,
	onSort,
	onOpenTypeDialog,
	selectionCount,
}) => {
	const columns = useMemo(
		() => [
			{
				dataKey: "FILENAME",
				label: "document-center.table.column.name",
				className: "table__cell--50",
				disableSort: false,
			},
			{
				dataKey: "VERSION",
				label: "document-center.table.column.version",
				className: "table__cell--10",
				disableSort: false,
			},
			{
				dataKey: "UPDATED",
				label: "document-center.table.column.updated",
				className: "table__cell--10",
				disableSort: false,
			},
			{
				dataKey: "PAGES",
				label: "document-center.table.column.pages",
				className: "table__cell--10",
				disableSort: false,
			},
			{
				dataKey: "REQUIREMENT",
				label: "document-center.table.column.requirements",
				className: "table__cell--10",
				disableSort: false,
			},
			{
				dataKey: "QUALITY",
				label: "document-center.table.column.quality",
				className: "table__cell--10",
				disabled: !isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS),
				additional: {
					display: () => (
						<KnowledgeBasePopOver
							label={translate("document-center.table.column.quality.pop-up.information")}
							link={translate("document-center.knowledge-base.link.quality-score")}
						/>
					),
				},
			},
		],
		[]
	);
	return (
		<CustomTableRow>
			{hasActionPermission && (
				<CustomTableCell
					className={styles.table__headerCell}
					colSpan={selectionCount ? 8 : 1}
					data-dragover={dragOver}
				>
					<Checkbox
						checked={selectionCount === documentInPageCount}
						data-testId="documentCenter.selectAllBtn"
						indeterminate={selectionCount > 0 && selectionCount < documentInPageCount}
						onChange={onSelectAll}
					/>
					{selectionCount > 0 && (
						<>
							<span className={styles.actions__selection}>
								<I18nTranslate
									param={{ numberOfCheckedElements: selectionCount, totalElements }}
									translationKey={
										(selectionCount > 1 && "document-center.table.selection") ||
										"document-center.table.single-selection"
									}
								/>
							</span>
							<VerticalDivider className={styles.actions__divider} />
							<span className={styles.actions__label}>{translate("document-center.table.actions")}</span>
							<span className={styles.actions__icons}>
								<CustomIconButton
									color="secondary"
									data-testid="documentCenter.downloadSelectionBtn"
									disabled={selectionCount === 0}
									icon={icon.faDownload}
									size="sm"
									tooltip={translate("document-center.table.actions.download")}
									variant="outlined"
									onClick={onDownload}
								/>
								{!hasInProgress && (
									<CustomIconButton
										color="secondary"
										data-testid="documentCenter.moveSelectionBtn"
										disabled={selectionCount === 0}
										icon={icon.faArrowRight}
										size="sm"
										tooltip={translate("document-center.table.actions.move")}
										variant="outlined"
										onClick={onOpenMoveDialog}
									/>
								)}
								<CustomIconButton
									color="secondary"
									data-testid="documentCenter.deleteSelectionBtn"
									disabled={selectionCount === 0}
									icon={icon.faTrash}
									size="sm"
									tooltip={translate("document-center.table.actions.delete")}
									variant="outlined"
									onClick={onOpenDeleteDialog}
								/>
								{isFeatureEnabled(Flags.SPP) && !hasUnsupported && (
									<CustomButton
										color="secondary"
										data-testid="documentCenter.addTypeSelectionBtn"
										startIcon={icon.faPlus}
										variant="outlined"
										onClick={onOpenTypeDialog}
									>
										{translate("document-center.table.actions.add-type")}
									</CustomButton>
								)}
							</span>
						</>
					)}
				</CustomTableCell>
			)}
			{selectionCount === 0 && (
				<>
					{Array.isArray(columns) &&
						columns.map((column) => (
							<CustomTableCell
								key={column.dataKey}
								active={activeSort === column.dataKey}
								className={`${styles.table__headerCell} ${styles[column.className]}`}
								data-dragover={dragOver}
								direction={direction?.toLowerCase()}
								sortKey={column.disableSort ? null : column.dataKey}
								onSort={() => onSort(column.dataKey)}
							>
								<div className={styles.innerCell}>
									{translate(column.label, { totalElements })}
									{column.additional && column.additional.display()}
								</div>
							</CustomTableCell>
						))}
					<CustomTableCell className={styles["table__cell--32px"]} data-dragover={dragOver} />
				</>
			)}
		</CustomTableRow>
	);
};

export default DocumentTableHeader;
