import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../../../../hooks";
import { DeliverablesService } from "../../../../../api";
import { CircularLoader } from "../../../loaders";
import { DeliverablesPhasesList } from "./components";
import styles from "./DeliverablesFilter.module.css";
import { isNonEmptyObject } from "../../../../utils";

const DeliverablesFilter = ({ search, value, onChange, readOnly }) => {
	const { call: getAllPhasesByProject } = useApi(DeliverablesService.getAllPhasesByProject);
	const { call: getAllDeliverablesByProjectAndPhaseForFilter } = useApi(
		DeliverablesService.getAllDeliverablesByProjectAndPhaseForFilter
	);
	const { call: getPhasesTreeForFilter } = useApi(DeliverablesService.getPhasesTreeForFilter);
	const [phasesTree, setPhasesTree] = useState([]);
	const [isLoadingDeliverables, setIsLoadingDeliverables] = useState(false);
	const projectId = useSelector(({ context }) => context.project.id);

	const fetchPhases = useCallback(() => {
		setIsLoadingDeliverables(true);
		getAllPhasesByProject({ projectId })
			.then((data) => setPhasesTree(data))
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoadingDeliverables(false));
	}, [getAllPhasesByProject, projectId]);
	const fetchPhasesTree = useCallback(() => {
		setIsLoadingDeliverables(true);
		getPhasesTreeForFilter({ projectId }, { name: search })
			.then((data) => setPhasesTree(data))
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoadingDeliverables(false));
	}, [getPhasesTreeForFilter, projectId, search]);

	useEffect(() => {
		if (!search) {
			fetchPhases();
		} else {
			fetchPhasesTree();
		}
	}, [search, fetchPhases, fetchPhasesTree]);

	const handleExpandPhase = ({ expandablePhase, expandablePhaseId }) => {
		if (!expandablePhaseId && expandablePhase.deliverablesCount === expandablePhase.deliverables?.length) {
			return;
		}
		const phaseId = expandablePhaseId || expandablePhase.phaseId;
		getAllDeliverablesByProjectAndPhaseForFilter({ projectId, phaseId })
			.then((data) => {
				const newPhases = phasesTree.map((phase) => {
					if (phase.phaseId === phaseId) {
						return { ...phase, defaultExpanded: true, deliverables: data };
					}
					return phase;
				});
				setPhasesTree(newPhases);
			})
			.catch(console.error);
	};
	const handleChange = (newValue, isChecked) => {
		const tempValue =
			isNonEmptyObject(value) && Object.keys(value)?.some((v) => v !== "is" && v !== "matchAll")
				? { ...value }
				: { elements: [], is: value?.is, matchAll: value?.matchAll };
		if (isChecked) {
			tempValue.elements = tempValue.elements.filter((element) => element !== newValue);
		} else {
			tempValue.elements = [...tempValue.elements, newValue];
		}
		onChange(tempValue);
	};
	return (
		<div className={styles.container}>
			{(isLoadingDeliverables && <CircularLoader />) || (
				<DeliverablesPhasesList
					hasFilters={search?.length > 0}
					phases={phasesTree}
					readOnly={readOnly}
					selection={value?.elements}
					onChange={handleChange}
					onExpandPhase={handleExpandPhase}
				/>
			)}
		</div>
	);
};

export default DeliverablesFilter;
