import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequirementParameterService, SearchCardTemplateService } from "../../../../api";
import backofficeService from "../../../../api/services/back-office/backoffice-service";
import searchCardTemplateService from "../../../../api/services/search-card-template-service";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";

function getProjectId(getState) {
	const {
		mySearchTemplate: { selectedProject: project },
	} = getState();
	return project.projectId || project.id;
}

export const getTypeParameters = createAsyncThunk(
	"mySearchTemplate/getTypeParameters",
	(token) => RequirementParameterService.getReqTypes(token),
	{
		condition: (_, { getState }) => {
			const {
				mySearchTemplate: { types },
			} = getState();
			return !Array.isArray(types) || types.length === 0;
		},
	}
);

export const getCriticalityParameters = createAsyncThunk(
	"mySearchTemplate/getCriticalityParameters",
	(token) => RequirementParameterService.getCriticality(token),
	{
		condition: (_, { getState }) => {
			const {
				mySearchTemplate: { criticalities },
			} = getState();
			return !Array.isArray(criticalities) || criticalities.length === 0;
		},
	}
);

export const getNegotiabilityParameters = createAsyncThunk(
	"mySearchTemplate/getNegotiabilityParameters",
	(token) => RequirementParameterService.getNegotiability(token),
	{
		condition: (_, { getState }) => {
			const {
				mySearchTemplate: { negotiabilities },
			} = getState();
			return !Array.isArray(negotiabilities) || negotiabilities.length === 0;
		},
	}
);

export const search = createAsyncThunk("mySearchTemplate/search", ({ filters, page, limit, token }, { getState }) => {
	const {
		mySearchTemplate: { isFilterTocOut },
		isDsi,
	} = getState();
	const projectId = getProjectId(getState);
	if (isDsi) {
		return backofficeService.templateSearch(
			{ projectId },
			{ Type: SEARCH_TYPES.template, ...(filters || {}), isFilterTocOut },
			{ page, limit },
			token
		);
	}
	return SearchCardTemplateService.templateSearch(
		{ projectId },
		{ Type: SEARCH_TYPES.template, ...(filters || {}), isFilterTocOut },
		{ page, limit },
		token
	);
});

export const getTotalResults = createAsyncThunk("mySearchTemplate/getTotalResults", (token, { getState }) => {
	const {
		mySearchTemplate: { isFilterTocOut },
		isDsi,
	} = getState();
	const projectId = getProjectId(getState);
	if (isDsi) {
		return backofficeService.countTemplateResults(
			{ projectId },
			{ Type: SEARCH_TYPES.template, isFilterTocOut },
			token
		);
	}
	return SearchCardTemplateService.countResults(
		{ projectId },
		{ Type: SEARCH_TYPES.template, isFilterTocOut },
		token
	);
});

export const countResults = createAsyncThunk("mySearchTemplate/countResults", ({ filters, token }, { getState }) => {
	const projectId = getProjectId(getState);
	const {
		mySearchTemplate: { separator, isFilterTocOut },
		isDsi,
	} = getState();
	if (isDsi) {
		return backofficeService.countTemplateResults(
			{ projectId },
			{ Type: SEARCH_TYPES.template, ...(filters || {}), separator, isFilterTocOut },
			token
		);
	}
	return searchCardTemplateService.countResults(
		{ projectId },
		{ Type: SEARCH_TYPES.template, ...(filters || {}), separator, isFilterTocOut },
		token
	);
});
