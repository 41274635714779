import React, { useState } from "react";
import CanvasContainer from "../../../canvas/CanvasContainer";
import styles from "./DSICanvas.module.css";
import { CompaniesList, ProjectsList } from "../../components";
import { isNonEmptyArray } from "../../../../common/utils";
import { useEffectOnce } from "../../../../common/hooks";

export default function DSICanvas({ companies, onSelectCompany }) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);

	const handleSelectCompany = (cmp) => {
		onSelectCompany(cmp);
		setSelectedCompany(cmp);
		setSelectedProject(null);
	};

	useEffectOnce(
		() => {
			handleSelectCompany(companies[0]);
		},
		[],
		() => selectedCompany === null && isNonEmptyArray(companies)
	);

	const handleSelectProject = (pr) => {
		setSelectedProject(pr);
	};
	return (
		<>
			{(!selectedCompany || !selectedProject) && (
				<>
					<div className={styles["left-panel"]}>
						<CompaniesList
							companies={companies || []}
							selectedCompany={selectedCompany}
							onClickRow={handleSelectCompany}
						/>
					</div>
					<div className={styles["middle-panel"]}>
						<ProjectsList
							isCanvas
							projects={(selectedCompany && selectedCompany.projects) || []}
							selectedCompany={selectedCompany}
							onClickRow={handleSelectProject}
						/>
					</div>
				</>
			)}
			{selectedCompany && selectedProject && (
				<CanvasContainer
					companies={companies}
					companyName={selectedCompany?.companyName}
					getDocumentMethod="getCompanyProjectDocuments"
					getUserMethod="getCompanyProjectUsers"
					projectId={selectedProject.id}
					selectedCompany={selectedCompany}
					selectedProject={selectedProject}
					onSelectCompany={handleSelectCompany}
					onSelectProject={handleSelectProject}
				/>
			)}
		</>
	);
}
