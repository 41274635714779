import React, { useState, useRef, useEffect } from "react";
import { Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToggleButtons, SearchInput, icon, ViewTab } from "../../../../../../common/components";
import styles from "./Header.module.css";
import { translate } from "../../../../../../common/providers";
import { setSelectedMode, setSearch } from "../../../../slice/gonogo-slice";
import { ApiService } from "../../../../../../api";
import { NavUtils } from "../../../../../../navigation";

const displayTabs = false;

export default function Header() {
	const [selectedTab, setSelectedTab] = useState(0);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const search = useSelector(({ gonogo }) => gonogo.search);
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const totalElements = useSelector(({ gonogo }) => gonogo.results?.totalElements);
	const navigate = useNavigate();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const selectedMode = useSelector(({ gonogo }) => gonogo.selectedMode);
	const handleChangeMode = (_, newMode) => {
		if (newMode !== selectedMode && newMode !== null) {
			dispatch(setSelectedMode(newMode));
			navigate(NavUtils.goToReviewByCategory({ projectId, categoryId: currentCategory.id, attached: newMode }));
		}
	};
	const handleChangeSearch = (e) => {
		dispatch(setSearch(e.target.value));
	};
	const handleClearSearch = () => {
		dispatch(setSearch(""));
	};
	const handleChangeSelectedTab = (_, tab) => {
		if (tab === selectedTab) {
			return;
		}
		setSelectedTab(tab);
	};
	return (
		<div className={styles.header}>
			<div>
				<ToggleButtons
					exclusive
					labelized
					btnClassName={styles.toggleBtns__btn}
					content={[
						{ value: 0, icon: "faAlignLeft", label: translate("gonogo.content.header.toggle.all") },
						{ value: 1, icon: "faFlag", label: translate("gonogo.content.header.toggle.important") },
						{ value: 2, icon: "faTrash", label: translate("gonogo.content.header.toggle.deleted") },
					]}
					data-testid="gonogo.header.toggle"
					disabled={!currentCategory}
					value={selectedMode}
					onChange={handleChangeMode}
				/>
				<SearchInput
					disabled={!currentCategory}
					label={translate("gonogo.content.header.toggle.search-placeholder")}
					value={search}
					onChange={handleChangeSearch}
					onClearSearch={handleClearSearch}
				/>
			</div>
			<div />
			{displayTabs && (
				<div>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						value={selectedTab}
						onChange={handleChangeSelectedTab}
					>
						<ViewTab iconName={icon.faList} label="Contents" value={0} />
						<ViewTab iconName={icon.faFile} label="Documents" value={1} />
					</Tabs>
				</div>
			)}
			<span>
				{search && totalElements && translate("gonogo.content.search-results.counter", { totalElements })}
			</span>
		</div>
	);
}
