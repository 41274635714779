import React, { useState } from "react";
import { useSelector } from "react-redux";
import InformationsDeletedOverlay from "./deleted-infos/InformationsDeletedOverlay";

export default function DeletedVersionLayer({ deletedData, height, priority, onSetPriority }) {
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const showVersioning = useSelector(({ srDocument }) => srDocument.showVersioning);
	const [hoveredWarning, setHoveredWarning] = useState(null);
	return (
		<>
			{showVersioning &&
				!redressingInformation &&
				Array.isArray(deletedData) &&
				deletedData
					.filter((sentence) => sentence?.informationId && sentence.deletedInfosIds.length > 0)
					.map((x) => (
						<InformationsDeletedOverlay
							key={x.informationId}
							deletedData={x}
							height={height}
							hoveredWarning={hoveredWarning}
							priority={priority}
							setHoveredWarning={setHoveredWarning}
							onSetPriority={onSetPriority}
						/>
					))}
		</>
	);
}
