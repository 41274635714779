import React, { useState } from "react";
import { Collapse, Paper } from "@mui/material";
import styles from "./Section.module.css";
import { CustomIconButton, icon } from "../../../../../../../common/components";

export default function Section({ title, children }) {
	const [collapsed, setCollapsed] = useState(true);
	const handleCollaspe = () => setCollapsed((prev) => !prev);
	return (
		<section className={styles.section}>
			<span className={styles.section__title}>
				<CustomIconButton
					icon={collapsed ? icon.faChevronDown : icon.faChevronRight}
					onClick={handleCollaspe}
				/>
				{title}
			</span>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<Paper square className={styles.section__content}>
					{children}
				</Paper>
			</Collapse>
		</section>
	);
}
