import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./HistoryTabView.module.css";
import { InfiniteList2, Selector } from "../../../../../../../../../common/components";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../../../../../common/providers";
import { ApiService, SmartViewService } from "../../../../../../../../../api";
import HistoryRow from "../history-row/HistoryRow";
import { HISTORY_ITEM_TYPE } from "../../constants/constants";

const HistoryTabView = ({ informationId, isRequirement }) => {
	const [infiniteRequest, setInfiniteRequest] = useState(null);
	const [filter, setFilter] = useState("");
	const filters = useMemo(
		() => [
			{ label: HISTORY_ITEM_TYPE.COMMENT },
			{ label: HISTORY_ITEM_TYPE.REQ_STATUS, segFlag: SegFlags.PROJECT_REQUIREMENTS },
			{ label: HISTORY_ITEM_TYPE.REV_COMMENT, segFlag: SegFlags.PROJECT_REQUIREMENTS },
			{ label: HISTORY_ITEM_TYPE.REV_STATUS, segFlag: SegFlags.PROJECT_REQUIREMENTS },
			{ label: HISTORY_ITEM_TYPE.CATEGORY },
			{ label: HISTORY_ITEM_TYPE.VERSION_STATUS },
			{ label: HISTORY_ITEM_TYPE.CONTENT_TYPE, segFlag: SegFlags.PROJECT_REQUIREMENTS },
			{ label: HISTORY_ITEM_TYPE.NEGOTIABILITY },
			{ label: HISTORY_ITEM_TYPE.CRITICALITY },
			{ label: HISTORY_ITEM_TYPE.TYPE },
		],
		[]
	);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setInfiniteRequest(
			() =>
				({ page, limit }) =>
					SmartViewService.getHistory(
						{ informationId },
						{ page, limit, filter: filter.label || undefined },
						cancelTokenSourceRef.current.token
					)
		);
	}, [informationId, filter]);
	const rowRenderer = (row) => (
		<HistoryRow key={row.id} history={row} informationId={informationId} isRequirement={isRequirement} />
	);
	const handleChangeFilter = (e) => {
		setFilter(e.target.value);
	};
	return (
		<div className={styles.container}>
			<Selector
				fullWidth
				withNone
				autoTranslate="no"
				className={styles.filter}
				field="label"
				itemRenderer={(label) =>
					translate(
						`smart-review.cmp-details.option-panel.history.enum.${label.toLowerCase().replaceAll("_", "-")}`
					)
				}
				items={filters.filter((f) => !f.segFlag || isSegFeatureEnabled(f.segFlag))}
				label={translate("smart-review.cmp-details.option-panel.history.filter-by")}
				labelClassName={styles.filterLabel}
				textFieldClassName={styles.filterTextField}
				value={
					filter
						? translate(
								`smart-review.cmp-details.option-panel.history.enum.${filter.label
									.toLowerCase()
									.replaceAll("_", "-")}`
						  )
						: ""
				}
				onChange={handleChangeFilter}
			/>
			<InfiniteList2
				className={styles.listContainer}
				request={infiniteRequest}
				rowRenderer={rowRenderer}
				scrollContainerClassName={styles.listScrollContainer}
			/>
		</div>
	);
};

export default HistoryTabView;
