import React, { useState } from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
	IconComponent,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./DSICompanyTable.module.css";

export default function DSICompanyTable({ companies, selectedCompany, onSelectRow }) {
	const [headers] = useState([
		translate("dsi.dsi-company.th.shortname"),
		translate("dsi.dsi-company.th.name"),
		translate("dsi.dsi-company.th.type"),
	]);
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header} className={styles.header}>
							{header}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("dsi.dsi-company.empty")}>
				{Array.isArray(companies) &&
					companies.map((row) => (
						<CustomTableRow
							key={row.id}
							hover
							className={selectedCompany && selectedCompany.id === row.id ? styles["row--selected"] : ""}
							onClick={() => onSelectRow(row)}
						>
							<CustomTableCell autoTranslate="no">
								{selectedCompany && selectedCompany.id === row.id && (
									<IconComponent
										className={styles.collapseRightButton__icon}
										icon={icon.faChevronRight}
										size="xs"
									/>
								)}
								{row.shortName}
							</CustomTableCell>
							<CustomTableCell autoTranslate="no">{row.companyName}</CustomTableCell>
							<CustomTableCell>{row.type}</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
