import client from "../client/client";

class PrecedenceService {
	getPrecedences({ projectId }, cancelToken) {
		return client.get(`/precedences/projects/${projectId}`, {
			config: { cancelToken },
		});
	}

	getDocuments({ projectId }, { filter }, cancelToken) {
		return client.get(`/precedences/projects/${projectId}/documents`, {
			params: { filter },
			config: { cancelToken },
		});
	}

	updateDocOrder({ docPrecedenceId }, { precedenceId, previousId }, cancelToken) {
		return client.patch(`/precedences/doc-precedence/${docPrecedenceId}`, {
			payload: { precedenceId, previousId },
			config: { cancelToken },
		});
	}

	updatePrecedenceOrder({ precedenceId }, { previousId }, cancelToken) {
		return client.patch(`/precedences/${precedenceId}/order`, { payload: { previousId }, config: { cancelToken } });
	}

	deletePrecedence({ precedenceId }, cancelToken) {
		return client.delete(`/precedences/${precedenceId}`, {
			config: { cancelToken },
		});
	}

	createPrecedence({ projectId }, precedence, cancelToken) {
		return client.post(`/precedences/projects/${projectId}`, {
			payload: precedence,
			config: { cancelToken },
		});
	}

	updatePrecedence({ precedenceId }, precedence, cancelToken) {
		return client.patch(`/precedences/${precedenceId}`, {
			payload: precedence,
			config: { cancelToken },
		});
	}
}

export default new PrecedenceService();
