import React from "react";
import { batch, connect } from "react-redux";
import { ApiService } from "../../../api";
import { AnalyticsProvider, Flags, SegFlags, translate } from "../../../common/providers";
import { exportView } from "../../../navigation";
import { HorizontalDivider, SideDrawerMain, ViewBanner, generateFilters } from "../../../common/components";
import { SmartSearchDrawer } from "./components";
import { SmartSearchResult, SmartSearchFilters } from "../components";
import {
	getAllSearches,
	search,
	setFilters,
	setIsLoadingSearch,
	countResults,
	createSearch,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTypeParameters,
	updateSearchFilters,
	setTotalResults,
	setIsFilterTocOut,
} from "./slice/my-search-slice";
import { setCrossFilters } from "../../../common/slice";
import { removeNullFilters } from "./slice/my-search-utils";
import styles from "./MySearch.module.css";
import { isNonEmptyObject } from "../../../common/utils";

const mapStateToProps = ({ context, mySearch }) => ({
	crossFilters: context.crossFilters,
	filters: mySearch.filters,
	limit: mySearch.limit,
	projectId: context.project.id,
	selectedSearch: mySearch.currentSearch.id,
	modified: mySearch.modified,
	hasFilters: mySearch.hasFilters,
});

const mapDispatchToProps = (dispatch) => ({
	onGetAllSearches: (token) => dispatch(getAllSearches(token)),
	onSearch: ({ filters, page, limit, token }) => dispatch(search({ filters, page, limit, token })),
	onSetFilters: (filters) => dispatch(setFilters(filters)),
	onSetIsFilterTocOut: (boolean) => dispatch(setIsFilterTocOut(boolean)),
	onSetCrossFilters: (filters) => dispatch(setCrossFilters(filters)),
	onSetLoadingSearch: (isLoading) => dispatch(setIsLoadingSearch(isLoading)),
	onCountResults: ({ filters, token }) => dispatch(countResults({ filters, token })),
	onCreateSearch: ({ token, ...payload }) => dispatch(createSearch({ ...payload, token })),
	onSetIsLoadingSearch: (isLoading) => dispatch(setIsLoadingSearch(isLoading)),
	onUpdateSearchFilters: ({ searchCardId, creatorId, token }) =>
		dispatch(updateSearchFilters({ searchCardId, creatorId, token })),
});

class MySearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openDrawer: true,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
		this.crossFilterTimeout = null;
	}

	componentDidMount() {
		const { onSetFilters, location } = this.props;
		const documentTitle = translate("smart-search.document.my-search-title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "My searches" });
		this.initSearch();
		if (isNonEmptyObject(location?.state?.filters) && typeof onSetFilters === "function") {
			onSetFilters(location.state.filters);
		}
	}

	initSearch = () => {
		const { filters, limit, onSearch, onSetCrossFilters, onSetLoadingSearch, hasFilters } = this.props;
		if (hasFilters) {
			batch(() => {
				onSetLoadingSearch(true);
				onSearch({
					filters: generateFilters(filters),
					page: 0,
					limit,
					token: this.cancelTokenSource.token,
				});
			});
		}
		this.crossFilterTimeout = setTimeout(() => {
			onSetCrossFilters(null);
		}, 1000);
	};

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
		clearInterval(this.crossFilterTimeout);
	}

	handleOpenDrawer = () => this.setState((prev) => ({ openDrawer: !prev.openDrawer }));

	render() {
		const { openDrawer } = this.state;
		const {
			onSearch,
			onSetFilters,
			onCountResults,
			onCreateSearch,
			onSetIsLoadingSearch,
			onUpdateSearchFilters,
			onSetIsFilterTocOut,
		} = this.props;
		return (
			<>
				<ViewBanner titles={[{ title: translate("navigation:project.search") }]} />
				<SideDrawerMain
					isForMySearch
					className={styles.sideDrawer__main}
					contentClassName={styles.sideDrawer__content}
					DrawerComponent={SmartSearchDrawer}
					openDrawer={openDrawer}
					onOpenDrawer={this.handleOpenDrawer}
				>
					<SmartSearchFilters
						getCriticalityParameters={getCriticalityParameters}
						getNegotiabilityParameters={getNegotiabilityParameters}
						getTypeParameters={getTypeParameters}
						setTotalResults={setTotalResults}
						slice="mySearch"
						onCountResults={onCountResults}
						onCreateSearch={onCreateSearch}
						onRemoveNullFilters={removeNullFilters}
						onSearch={onSearch}
						onSetFilters={onSetFilters}
						onSetIsFilterTocOut={onSetIsFilterTocOut}
						onSetIsLoadingSearch={onSetIsLoadingSearch}
						onUpdateSearchFilters={onUpdateSearchFilters}
					/>
					<HorizontalDivider />
					<SmartSearchResult slice="mySearch" onReset={this.initSearch} onSearch={onSearch} />
				</SideDrawerMain>
			</>
		);
	}
}

export { default as mySearchSlice } from "./slice/my-search-slice";
export default exportView({
	path: "/projects/:projectId/search/my-search",
	component: connect(mapStateToProps, mapDispatchToProps)(MySearch),
	localesPath: "/smart-search/locales",
	flag: Flags.SEARCHCARD,
	segFlag: SegFlags.SMART_SEARCH,
});
