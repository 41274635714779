import client from "../../client/client";

class ContentService {
	getImage({ contentId }, cancelToken) {
		return client.get(`/contents/${contentId}/image`, {
			config: { responseType: "arraybuffer", cancelToken },
		});
	}

	getTable({ contentId }, cancelToken) {
		return client.get(`/contents/${contentId}/table`, {
			config: { responseType: "arraybuffer", cancelToken },
		});
	}

	getDocumentClauses({ docId }, cancelToken) {
		return client.get(`/contents/documents/${docId}/clauses`, { config: { cancelToken } });
	}

	getTableByInformationId({ informationId }, cancelToken) {
		return client.get(`/contents/information/${informationId}/table`, {
			config: { responseType: "arraybuffer", cancelToken },
		});
	}

	getAllContentLines({ contentId }, cancelToken) {
		return client.get(`/contents/${contentId}/lines`, { config: { cancelToken } });
	}

	getContentCoordinates({ docId, contentId }, cancelToken) {
		return client.get(`/contents/documents/${docId}/rectangle/${contentId}`, { config: { cancelToken } });
	}

	getContentCoordinatesFromInformation({ docId, informationId }, cancelToken) {
		return client.get(`/contents/documents/${docId}/information/${informationId}`, { config: { cancelToken } });
	}

	getInformationIdFromContent({ docId, contentId }, cancelToken) {
		return client.get(`/contents/documents/${docId}/content/${contentId}`, { config: { cancelToken } });
	}
}

export default new ContentService();
