export const HISTORY_ITEM_TYPE = {
	REQ_STATUS: "REQ_STATUS",
	REV_STATUS: "REV_STATUS",
	REV_COMMENT: "REV_COMMENT",
	COMMENT: "COMMENT",
	CATEGORY: "CATEGORY",
	VERSION_STATUS: "VERSION_STATUS",
	CONTENT_TYPE: "CONTENT_TYPE",
	NEGOTIABILITY: "NEGOTIABILITY",
	CRITICALITY: "CRITICALITY",
	TYPE: "TYPE",
	LINK: "LINK",
};
