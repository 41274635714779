import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./SearchTextField.module.css";
import { CustomIconButton, IconComponent, icon } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";
import { debounce } from "../../../../../../../../../common/utils";

const debouncedFunction = debounce((func) => func(), 500);

const SearchTextField = ({ isLoadingDeliverables, onChange, initValue }) => {
	const [localValue, setLocalValue] = useState(initValue);
	const dispatch = useDispatch();

	const handleChangeSearchText = (e) => {
		const { value } = e.target;
		setLocalValue(value);
		debouncedFunction(() => {
			onChange(value);
		});
	};
	const handleResetSearchText = () => {
		setLocalValue("");
		onChange("");
	};
	return (
		<TextField
			autoFocus
			className={styles.search}
			InputProps={{
				className: styles.textField,
				endAdornment: (
					<InputAdornment position="end">
						{isLoadingDeliverables && <CircularProgress size={20} thickness={3} />}
						{!isLoadingDeliverables && !localValue && <IconComponent icon={icon.faSearch} />}
						{!isLoadingDeliverables && localValue && (
							<CustomIconButton icon={icon.faTimesCircleRegular} onClick={handleResetSearchText} />
						)}
					</InputAdornment>
				),
			}}
			placeholder={translate(
				"smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.search"
			)}
			size="small"
			value={localValue}
			variant="outlined"
			onBlur={() => dispatch(setDisableKeyEvents(false))}
			onChange={handleChangeSearchText}
			onFocus={() => dispatch(setDisableKeyEvents(true))}
		/>
	);
};

export default SearchTextField;
