import React from "react";
import CompanyRow from "./CompanyRow";
import { translate } from "../../../../common/providers";
import { BlockTitle } from "../../../../common/components";

export default function CompaniesList({ companies = [], onClickRow, selectedCompany = null }) {
	return (
		<div>
			<BlockTitle>{translate("dsi.company-list.title")}</BlockTitle>
			<div style={{ height: "100%" }}>
				{Array.isArray(companies) &&
					companies.map((company, index) => (
						<CompanyRow
							key={company.id}
							company={company}
							displayParentIcon={index === 0}
							isSelected={selectedCompany && selectedCompany.id === company.id}
							onClick={() => onClickRow(company)}
						/>
					))}
			</div>
		</div>
	);
}
