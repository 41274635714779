import { createSlice } from "@reduxjs/toolkit";
import { KEYWORD_FILTER_TYPE, OPERATORS, VISIBILITY } from "../../../../common/constants";
import { translateEnumParam, translateEnumParamTooltip } from "../../../../common/providers";
import { CRITICALITY, NEGOTIABILITY, TYPE } from "../../../smart-search/utils/utils";
import {
	countResults,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTotalResults,
	getTypeParameters,
	search,
} from "./my-search-template-thunks";
import { computeKeywordFiltersExistence, hasBeenModified, removeNullFilters } from "./my-search-template-utils";

const initFilter = { separator: OPERATORS.AND, Type: "TemplateSearchFilterDTO" };
const initialState = {
	currentSearch: {
		isNew: true,
		id: 0,
		creatorId: 0,
		title: "",
		description: "",
		visibility: VISIBILITY.PRIVATE,
		listKey: "", // When currentSearch is not new, it identifies from which list it came from
	},
	isFilterTocOut: false,
	previousIsFilterTocOut: false,
	modified: false,
	hasFilters: false,
	hasNegativeKeywordFilters: { [KEYWORD_FILTER_TYPE.PARAGRAPH]: false, [KEYWORD_FILTER_TYPE.TOC]: false },
	numberOfPositiveKeywordFilters: { [KEYWORD_FILTER_TYPE.PARAGRAPH]: 0, [KEYWORD_FILTER_TYPE.TOC]: 0 },
	totalResults: 0,
	totalMatches: 0,
	results: [],
	filters: initFilter,
	previousFilters: initFilter,
	page: 0,
	limit: 20,
	isLoadingSearch: false,
	isLoadingParameters: false,
	isLoadingCategories: false,
	types: [],
	criticalities: [],
	negotiabilities: [],
	hasMore: false,
	totalPages: 0,
	selectedProject: null,
	isDsi: false,
	isUpdating: false,
};

const mySearchTemplateSlice = createSlice({
	name: "mySearchTemplate",
	initialState,
	reducers: {
		setIsLoadingSearch: (state, { payload }) => ({ ...state, isLoadingSearch: payload }),
		setSelectedProject: (state, { payload }) => ({ ...state, selectedProject: payload }),
		setIsDsi: (state, { payload }) => ({ ...state, isDsi: payload }),
		setIsUpdating: (state, { payload }) => ({ ...state, isUpdating: payload }),
		setTotalResults: (state, { payload }) => ({ ...state, totalResults: payload }),
		setTotalMatches: (state, { payload }) => ({ ...state, totalMatches: payload }),
		setIsFilterTocOut: (state, { payload }) => ({
			...state,
			isFilterTocOut: payload,
			modified: hasBeenModified({ ...state, isFilterTocOut: payload }),
		}),

		setCurrentSearch: (state, { payload }) => {
			const { currentSearch, filters, isFilterTocOut, ...rest } = payload;
			if (filters) {
				const cleanFilters = removeNullFilters(filters);
				const { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters } =
					computeKeywordFiltersExistence(cleanFilters);
				return {
					...state,
					currentSearch,
					modified: false,
					hasFilters: Object.keys(cleanFilters).some((item) => item !== "Type" && item !== "separator"),
					filters: cleanFilters,
					previousFilters: cleanFilters,
					isFilterTocOut,
					previousIsFilterTocOut: isFilterTocOut,
					hasNegativeKeywordFilters,
					numberOfPositiveKeywordFilters,
					...rest,
				};
			}
			return {
				...state,
				currentSearch,
				...rest,
			};
		},
		setFilters: (state, { payload: filters }) => {
			const newFilters = removeNullFilters(filters);
			const { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters } =
				computeKeywordFiltersExistence(newFilters);
			return {
				...state,
				filters: newFilters,
				hasFilters: Object.keys(newFilters).some((item) => item !== "Type" && item !== "separator"),
				modified: hasBeenModified({ ...state, filters: newFilters }),
				hasNegativeKeywordFilters,
				numberOfPositiveKeywordFilters,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getTypeParameters.fulfilled, (state, action) => {
			const types = action.payload.map((i) => ({ name: i, displayName: translateEnumParam(TYPE, i) }));
			return { ...state, isLoadingParameters: false, types };
		});
		builder.addCase(getTypeParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getTypeParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getCriticalityParameters.fulfilled, (state, action) => {
			const criticalities = action.payload.map((i) => ({
				name: i,
				displayName: translateEnumParam(CRITICALITY, i),
			}));
			return { ...state, isLoadingParameters: false, criticalities };
		});
		builder.addCase(getCriticalityParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getCriticalityParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getNegotiabilityParameters.fulfilled, (state, action) => {
			const negotiabilities = action.payload.map((i) => ({
				name: i,
				displayName: translateEnumParam(NEGOTIABILITY, i),
				title: translateEnumParamTooltip(NEGOTIABILITY, i),
			}));
			return { ...state, isLoadingParameters: false, negotiabilities };
		});
		builder.addCase(getNegotiabilityParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getNegotiabilityParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(search.fulfilled, (state, action) => {
			const {
				details: { contents, totalElements, pageNumber, hasMore, totalPages },
			} = action.payload;
			const newState = {
				...state,
				isLoadingSearch: false,
				results: pageNumber === 0 ? contents : [...state.results, ...contents],
				page: pageNumber,
				hasMore,
				totalPages,
			};
			newState.totalMatches = totalElements;

			return newState;
		});
		builder.addCase(search.rejected, (state) => ({
			...state,
			hasMore: false,
			totalPages: 0,
			isLoadingSearch: false,
		}));
		builder.addCase(getTotalResults.fulfilled, (state, action) => {
			const totalElements = action.payload;
			return {
				...state,
				totalResults: totalElements,
			};
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export {
	countResults,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTypeParameters,
	search,
	getTotalResults,
};
export const {
	setIsDsi,
	setFilters,
	setCurrentSearch,
	setIsLoadingSearch,
	setIsFilterTocOut,
	setSelectedProject,
	setIsUpdating,
	setTotalResults,
	setTotalMatches,
} = mySearchTemplateSlice.actions;
export default mySearchTemplateSlice.reducer;
