import React, { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { debounce } from "@mui/material";
import { ApiService, PrecedenceService } from "../../../../api";
import {
	createNotification,
	CustomButton,
	CustomDialog,
	CustomSwitch,
	SearchInput,
	SidePanel,
	SidePanelContainer,
	SidePanelContent,
} from "../../../../common/components";
import { hasPermission, isSegFeatureEnabled, Permissions, SegFlags, translate } from "../../../../common/providers";
import { Column, NewGroup } from "./components";
import styles from "./PrecedenceSidePanel.module.css";
import precedenceService from "../../../../api/services/precedence-service";
import { isNonEmptyArray } from "../../../../common/utils";

const debounceFunc = debounce((func) => func(), 0);

const PrecedenceSidePanel = ({ onClose, open }) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const [precedences, setPrecedences] = useState([]);
	const [precedenceToDelete, setPrecedenceToDelete] = useState(null);
	const [precedenceToEdit, setPrecedenceToEdit] = useState(null);
	const [openCreateNewGroup, setOpenCreateNewGroup] = useState(false);
	const [filter, setFilter] = useState("");
	const [documents, setDocuments] = useState([]);
	const [displayAll, setDisplayAll] = useState(false);
	const bottomEl = useRef(null);
	const filteredPrecedences = useMemo(() => {
		if (displayAll) {
			return precedences;
		}
		return precedences.filter(
			(precedence) =>
				documents.findIndex(
					(doc) => doc.precedence.precedenceId === precedence.precedenceId && doc.documents?.length > 0
				) >= 0
		);
	}, [displayAll, precedences, documents]);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (open) {
			PrecedenceService.getPrecedences({ projectId }, cancelTokenSourceRef.current.token)
				.then((data) => setPrecedences(data))
				.catch((err) => console.error(err));
		}
	}, [projectId, open]);
	useEffect(() => {
		if (hasPermission(Permissions.PROJECT_LEADER) && isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER) && open) {
			precedenceService
				.getDocuments({ projectId }, { filter }, cancelTokenSourceRef.current.token)
				.then((data) => setDocuments(data))
				.catch((err) => console.error(err));
		}
	}, [filter, projectId, open]);
	const handleFilter = (e) => {
		setFilter(e.target.value);
	};
	const handleClose = () => {
		setFilter("");
		onClose();
		setDisplayAll(false);
	};
	const handleReorderDoc = (result) => {
		const { source, destination, draggableId: fullDrgagableId } = result;
		const draggableId = fullDrgagableId.replace("DOC_", "");
		// Failure handling
		if (
			!destination ||
			!source ||
			(destination.index === source.index && destination.droppableId === source.droppableId)
		) {
			return;
		}
		let selectedDocument = null;
		documents.forEach((precedence) =>
			precedence.documents.forEach((doc) => {
				if (doc.id === +draggableId) {
					selectedDocument = doc;
				}
			})
		);
		if (destination.droppableId !== source.droppableId) {
			selectedDocument.source = "MANUAL";
		}
		const exist = documents.some((doc) => doc.precedence.precedenceId === +destination.droppableId);
		documents.forEach((document) => {
			if (document.precedence.precedenceId === +source.droppableId) {
				document.documents.splice(source.index, 1);
				document.docCount -= 1;
			}
			if (exist && document.precedence.precedenceId === +destination.droppableId) {
				document.documents.splice(destination.index, 0, selectedDocument);
			}
		});
		const tempDoc = [...documents];
		const newPrecedence = precedences.find((prec) => prec.precedenceId === +destination.droppableId);
		if (!exist) {
			tempDoc.push({ precedence: newPrecedence, documents: [selectedDocument] });
		}
		setDocuments(tempDoc);
		const documentAbove =
			destination.index === 0
				? null
				: tempDoc.find((doc) => doc.precedence.precedenceId === +destination.droppableId)?.documents[
						destination.index - 1
				  ];
		PrecedenceService.updateDocOrder(
			{ docPrecedenceId: selectedDocument.docPrecedence },
			{
				precedenceId: parseInt(Number(destination.droppableId), 10),
				previousId: documentAbove?.docPrecedence || null,
			},
			cancelTokenSourceRef.current.token
		)
			.then(() =>
				createNotification({
					type: "success",
					message: translate("document-center.side-panel.notif-success-doc-moved"),
				})
			)
			.catch((err) => console.error(err));
	};
	const handleGetPreviousId = (indexToPlace) => {
		const elementAtDestination = filteredPrecedences[indexToPlace];
		if (elementAtDestination === undefined) {
			return {
				indexToPlace: precedences.length - 1,
				previousId: precedences[precedences.length - 2].precedenceId,
			};
		}
		const index = precedences.findIndex((prec) => prec.precedenceId === elementAtDestination.precedenceId);
		return { indexToPlace: index, previousId: precedences[index - 1]?.precedenceId || null };
	};
	const handleReorderPrecedence = (results) => {
		const { destination, source } = results;
		if (!destination || !source || destination.index === source.index) {
			return;
		}
		const elementToMove = filteredPrecedences[source.index];
		const elementIndex = precedences.findIndex((prec) => prec.precedenceId === elementToMove.precedenceId);
		const { previousId, indexToPlace } = handleGetPreviousId(
			source.index > destination.index ? destination.index : destination.index + 1
		);
		const tempPrecedences = [...precedences];
		tempPrecedences.splice(indexToPlace, 0, elementToMove);
		if (source.index > destination.index) {
			tempPrecedences.splice(elementIndex + 1, 1);
		} else {
			tempPrecedences.splice(elementIndex, 1);
		}
		setPrecedences(tempPrecedences);
		PrecedenceService.updatePrecedenceOrder(
			{ precedenceId: elementToMove.precedenceId },
			{ previousId },
			cancelTokenSourceRef.current.token
		);
	};
	const handleDragEnd = (results) => {
		if (results.type === "Document") {
			handleReorderDoc(results);
		} else {
			handleReorderPrecedence(results);
		}
	};
	const handleOpenDeletePanel = (precedence, precDocs) => {
		setPrecedenceToDelete({ ...precedence, precDocs });
	};
	const handleCloseDelete = () => {
		setPrecedenceToDelete(null);
	};
	const handleGetDocListAfterPrecedenceDeletion = () => {
		let tempDocs = [...documents];
		let othersDetected = false;
		tempDocs = tempDocs.map((tempDoc) => {
			if (tempDoc.precedence.precedence === "Autres") {
				othersDetected = true;
				return {
					...tempDoc,
					documents: [
						...tempDoc.documents,
						...precedenceToDelete.precDocs.map((precDoc) => ({
							...precDoc,
							source: "MANUAL",
						})),
					],
					docCount: tempDoc.docCount + precedenceToDelete.precDocs.length,
				};
			}
			return tempDoc;
		});
		if (!othersDetected) {
			const othersPrecedence = precedences[precedences.findIndex((prec) => prec.precedence === "Autres")];
			tempDocs = [
				...tempDocs,
				{
					docCount: precedenceToDelete.precDocs?.length,
					precedence: othersPrecedence,
					documents: [
						...precedenceToDelete.precDocs.map((precDoc) => ({
							...precDoc,
							source: "MANUAL",
						})),
					],
				},
			];
		}
		return tempDocs;
	};
	const handleDeletePrecedence = () => {
		PrecedenceService.deletePrecedence(
			{ precedenceId: precedenceToDelete.precedenceId },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				if (isNonEmptyArray(precedenceToDelete?.precDocs)) {
					setDocuments(handleGetDocListAfterPrecedenceDeletion());
				}
				setPrecedences(precedences.filter((prec) => prec.precedenceId !== precedenceToDelete.precedenceId));
			})
			.catch((err) => console.error(err))
			.finally(() => handleCloseDelete());
	};
	const handleOpenCreateNewGroup = () => {
		setOpenCreateNewGroup(true);
	};
	const handleCloseCreateNewGroup = () => {
		setOpenCreateNewGroup(false);
		setPrecedenceToEdit(null);
	};
	const handleEdit = (prec) => {
		setPrecedenceToEdit(prec);
		handleOpenCreateNewGroup();
	};
	const handleScrollToBottom = () => {
		bottomEl?.current?.scrollIntoView({ behavior: "smooth" });
	};
	const handleConfirmCreateNewGroup = (newPrecedence, isEditing) => {
		if (isEditing) {
			PrecedenceService.updatePrecedence(
				{ precedenceId: newPrecedence.id },
				newPrecedence,
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					const tempPrecedences = [...precedences];
					setPrecedences(
						tempPrecedences.map((tempPrec) => {
							if (tempPrec.precedenceId === data.precedenceId) {
								return { ...tempPrec, precedence: data.precedence, keywords: data.keywords };
							}
							return tempPrec;
						})
					);
				})
				.catch((err) => console.error(err));
		} else {
			PrecedenceService.createPrecedence({ projectId }, newPrecedence, cancelTokenSourceRef.current.token)
				.then((data) => {
					const tempPrecList = [...precedences];
					tempPrecList.splice(tempPrecList.length - 1, 0, data);
					setPrecedences(tempPrecList);
					setDisplayAll(true);
					setFilter("");
					debounceFunc(() => handleScrollToBottom());
				})
				.catch((err) => console.error(err));
		}
		handleCloseCreateNewGroup();
	};
	return (
		<SidePanel disableEscapeKeyDown open={open} size={50} onClose={handleClose}>
			<SidePanelContent
				className={styles.sidePanelContainer}
				title={translate("document-center.side-panel.manage-document-type")}
				onClose={handleClose}
			>
				<SidePanelContainer className={styles.container}>
					<div className={styles.subTitle}>{translate("document-center.side-panel.project-documents")}</div>
					<div className={styles.filters}>
						<div className={styles.filters__left}>
							<SearchInput
								placeholder={translate("common:filters3.search-cards.label")}
								onChange={handleFilter}
							/>
							<CustomSwitch checked={displayAll} onChange={() => setDisplayAll((prev) => !prev)} />
							{translate("document-center.side-panel.switch.display-all")}
						</div>
						<div>
							<CustomButton
								className={styles.createNewGroup__btn}
								variant="contained"
								onClick={handleOpenCreateNewGroup}
							>
								{translate("document-center.side-panel.precedences.create-new-group")}
							</CustomButton>
						</div>
					</div>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="Prec" type="Precedence">
							{(dropProvided, dropSnapshot) => (
								<div
									ref={dropProvided.innerRef}
									style={{
										backgroundColor: dropSnapshot.isDraggingOver
											? "var(--color-blue)"
											: "var(--color-white)",
									}}
									{...dropProvided.draggableProps}
									className={styles.documentList}
									data-testid="document.list"
								>
									{filteredPrecedences.map((precedence, index) => (
										<Draggable
											key={precedence.precedenceId.toString()}
											draggableId={precedence.precedenceId.toString()}
											index={index}
											type="Precedence"
										>
											{(provided, snapshot) => (
												<div
													key={precedence.precedenceId}
													ref={provided.innerRef}
													style={snapshot.isDragging && { ...provided.draggableProps.style }}
													{...provided.draggableProps}
													className={styles.precedence}
												>
													<Column
														data-testid="document.list.cell"
														documents={documents.filter(
															(doc) =>
																doc.precedence.precedenceId === precedence.precedenceId
														)}
														precedence={precedence}
														providedPrec={provided}
														onDelete={handleOpenDeletePanel}
														onEdit={handleEdit}
													/>
												</div>
											)}
										</Draggable>
									))}
									<span ref={bottomEl} />
									{dropProvided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<CustomDialog
						closeLabel={translate("common:btn.cancel")}
						maxWidth="xs"
						open={!!precedenceToDelete}
						submitLabel={translate("common:btn.delete")}
						subTitle={
							<span>
								{precedenceToDelete?.precDocs?.length > 0 && (
									<span>
										{translate("document-center.side-panel.precedences.delete.with-docs")}
										<br />
									</span>
								)}
								{translate("document-center.side-panel.precedences.delete.confirm")}
							</span>
						}
						title={translate("common:btn.delete")}
						onClose={handleCloseDelete}
						onSubmit={handleDeletePrecedence}
					/>
					<NewGroup
						open={openCreateNewGroup}
						precedence={precedenceToEdit}
						precedences={precedences}
						onClose={handleCloseCreateNewGroup}
						onSubmit={handleConfirmCreateNewGroup}
					/>
				</SidePanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
};

export default PrecedenceSidePanel;
