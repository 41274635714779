import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../../../../../common/hooks";
import { DocumentOutCoverageService } from "../../../../../api";
import { isNonEmptyArray } from "../../../../../common/utils";
import styles from "./PhasesTree.module.css";
import {
	CustomAccordion,
	CustomButton,
	CustomDialog,
	CustomTooltip,
	IconComponent,
	generateFilters,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import DeliverableRow from "../deliverable-row/DeliverableRow";

const PhasesTree = ({ filters, selectedCount }) => {
	const { call: getPhasesWithCounters } = useApi(DocumentOutCoverageService.getPhasesWithCounters);
	const { call: getAllDeliverablesWithCounters } = useApi(DocumentOutCoverageService.getAllDeliverablesWithCounters);
	const { call: reassignDeliverables } = useApi(DocumentOutCoverageService.reassignDeliverables);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [phases, setPhases] = useState([]);
	const [closeExpansion, setCloseExpansion] = useState(false);
	const [loadingChanges, setLoadingChanges] = useState(false);
	const [resetDeliverable, setResetDeliverable] = useState(false);
	const [openCancelDialog, setOpenCancelDialog] = useState(false);
	const [removeDeliverables, setRemoveDeliverables] = useState([]);
	const [addDeliverables, setAddDeliverables] = useState([]);

	const handleResetSelection = (toDefault = true) => {
		if (!toDefault) {
			setPhases((prev) =>
				prev.map((phase) => ({
					...phase,
					deliverables: phase.deliverables?.map((deliverable) => {
						if (addDeliverables.includes(deliverable.id)) {
							return { ...deliverable, count: selectedCount };
						}
						if (removeDeliverables.includes(deliverable.id)) {
							return { ...deliverable, count: 0 };
						}
						return deliverable;
					}),
				}))
			);
		}
		setResetDeliverable(true);
		setAddDeliverables([]);
		setRemoveDeliverables([]);
	};
	useEffect(() => {
		if (projectId) {
			getPhasesWithCounters({ projectId })
				.then((data) => setPhases(data))
				.catch(console.error);
		}
	}, [getPhasesWithCounters, projectId]);
	useEffect(() => {
		if (selectedCount > 0) {
			setPhases((prev) => prev.map((p) => ({ ...p, deliverables: [] })));
			setCloseExpansion(true);
			setAddDeliverables([]);
			setRemoveDeliverables([]);
		}
	}, [selectedCount]);
	const handleExpandPhase = (expandablePhase) => {
		if (expandablePhase.deliverables?.length === expandablePhase.count) {
			return;
		}
		getAllDeliverablesWithCounters({ projectId, phaseId: expandablePhase.phaseId }, generateFilters(filters))
			.then((data) => {
				const newPhases = phases.map((phase) => {
					if (phase.phaseId === expandablePhase.phaseId) {
						return { ...phase, deliverables: data };
					}
					return phase;
				});
				setPhases(newPhases);
			})
			.catch(console.error);
	};
	const handleSelect = ({ deliverable, action }) => {
		if (action === "toAdd") {
			setAddDeliverables((prev) => [...prev, deliverable.id]);
			setRemoveDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
		} else if (action === "toRemove") {
			setRemoveDeliverables((prev) => [...prev, deliverable.id]);
			setAddDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
		} else if (action === "fullRemove") {
			setRemoveDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
			setAddDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
		}
	};
	const handleValidate = () => {
		setLoadingChanges(true);
		reassignDeliverables(
			{ projectId },
			{
				filters: generateFilters(filters),
				add: isNonEmptyArray(addDeliverables) ? addDeliverables : undefined,
				remove: isNonEmptyArray(removeDeliverables) ? removeDeliverables : undefined,
			}
		)
			.then(() => {
				handleResetSelection(false);
			})
			.catch(console.error)
			.finally(() => {
				setLoadingChanges(false);
			});
	};
	const handleOpenCancelDialog = () => {
		setOpenCancelDialog(true);
	};
	const handleCloseCancelDialog = () => {
		setOpenCancelDialog(false);
	};
	const handleConfirmCancel = () => {
		handleResetSelection();
		handleCloseCancelDialog();
	};

	return (
		<>
			{(selectedCount > 0 && (
				<>
					<div className={styles.phasesContainer}>
						{isNonEmptyArray(phases) &&
							phases.map((phase) => (
								<CustomAccordion
									key={phase.phaseId}
									className={styles.accordionContent}
									closeExpansion={closeExpansion}
									header={
										<CustomTooltip showWhenOverflow title={phase?.name}>
											<div className={styles.phaseName}>{phase?.name}</div>
										</CustomTooltip>
									}
									iconColor="var(--color-light-grey-1)"
									onExpand={() => handleExpandPhase(phase)}
									onSetCloseExpansion={setCloseExpansion}
								>
									{(phase.count > 0 && (
										<div>
											{isNonEmptyArray(phase.deliverables) &&
												phase.deliverables.map((deliverable) => (
													<DeliverableRow
														key={deliverable.id}
														deliverable={deliverable}
														loadingChanges={loadingChanges}
														resetDeliverable={resetDeliverable}
														selectedCount={selectedCount}
														onSelect={handleSelect}
														onSetResetDeliverable={setResetDeliverable}
													/>
												))}
										</div>
									)) ||
										null}
								</CustomAccordion>
							))}
					</div>
					<div className={styles.actionContainer}>
						<CustomButton
							color="secondary"
							disabled={
								(!isNonEmptyArray(removeDeliverables) && !isNonEmptyArray(addDeliverables)) ||
								loadingChanges
							}
							variant="outlined"
							onClick={handleOpenCancelDialog}
						>
							{translate("common:btn.cancel")}
						</CustomButton>
						<CustomButton
							disabled={
								(!isNonEmptyArray(removeDeliverables) && !isNonEmptyArray(addDeliverables)) ||
								loadingChanges
							}
							variant="contained"
							onClick={handleValidate}
						>
							{translate("common:btn.validate")}
						</CustomButton>
					</div>
				</>
			)) || (
				<div className={styles.emptyState__container}>
					<IconComponent
						className={styles.emptyState__icon}
						color="var(--color-blue)"
						icon={icon.faInfoCircle}
					/>
					{translate("coverage.document-list.select-some-requirement-affilitate")}
				</div>
			)}
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openCancelDialog}
				subTitle={translate("common:close-warning.are-you-sure-to-cancel-changes")}
				onClose={handleCloseCancelDialog}
				onSubmit={handleConfirmCancel}
			/>
		</>
	);
};

export default PhasesTree;
