import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PdfViewService } from "../../../../../api";
import { IconComponent, SearchInput, icon } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./SearchRightPanel.module.css";
import { useApiDebounced } from "../../../../../common/hooks";
import { SearchResult } from "./components";
import { setSelectedSearch as handleSelectedSearch, setHoveredBlock } from "../../../slice/document/document-slice";
import { setScrollTo } from "../../../slice/pdf/pdf-slice";
import { setDisableKeyEvents } from "../../../../../common/slice";

export default function SearchRightPanel() {
	const [search, setSearch] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [results, setResults] = useState(null);
	const [selectedSearch, setSelectedSearch] = useState(null);
	const dispatch = useDispatch();
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const searchInputRef = useRef();
	const { call: debouncedSearch } = useApiDebounced(PdfViewService.search, 1000);
	useEffect(
		() => () => {
			if (disableKeyEvents) {
				dispatch(setDisableKeyEvents(false));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleLoading = (loading) => {
		setIsLoading(loading);
	};
	const handleChangeSearch = useCallback(
		(e) => {
			const text = e?.target?.value || "";
			setSearch(text);
			if (text.length > 0) {
				handleLoading(true);
				const handleResponse = (data) => {
					handleLoading(false);
					setResults(data);
				};
				const handleError = (err) => console.error(err);
				debouncedSearch(handleResponse, handleError, { documentId }, text);
			}
			if (text.length === 0) {
				handleLoading(true);
				const handleResponse = () => {
					handleLoading(false);
					setResults(null);
				};
				const handleError = (err) => console.error(err);
				debouncedSearch(handleResponse, handleError, { documentId }, "");
			}
		},
		[debouncedSearch, documentId]
	);
	const handleClearSearch = () => {
		searchInputRef.current.focus();
		setSearch("");
		setResults(null);
		setSelectedSearch(null);
	};
	const handleClick = (data, index) => {
		setSelectedSearch(index);
		dispatch(setHoveredBlock(null));
		dispatch(handleSelectedSearch({ ...data, fromUrl: true }));
		dispatch(setScrollTo({ page: data.pageStart, position: data.y1 }));
	};
	return (
		<div className={styles.main}>
			<div className={styles.filters__container}>
				<SearchInput
					ref={searchInputRef}
					autoFocus
					fullWidth
					className={styles.filters__search}
					id="search_in_pdf"
					isLoading={isLoading}
					label={translate("smart-review.search-space.search")}
					value={search}
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onChange={handleChangeSearch}
					onClearSearch={handleClearSearch}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
			</div>
			<div className={styles.totalResult}>
				{results?.totalResult &&
					translate("smart-review.search-space.search-results", { totalResult: results?.totalResult })}
			</div>
			<div className={styles.result__container}>
				{(!isLoading &&
					Array.isArray(results?.contentsPosition) &&
					results.totalResult > 0 &&
					results?.contentsPosition.map((x, index) => (
						<div
							key={x.infoId}
							role="presentation"
							onClick={() => {
								handleClick(x, index);
							}}
						>
							<SearchResult
								result={x}
								search={search}
								searchNumber={index}
								selectedSearch={selectedSearch}
							/>
						</div>
					))) || (
					<div className={styles.emptyStateContainer}>
						<IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} size="md" />
						{translate("common:empty-state.no-results")}
					</div>
				)}
			</div>
		</div>
	);
}
