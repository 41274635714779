import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	CustomButtonLink,
	CustomIconButton,
	CustomTooltip,
	I18nTranslate,
	icon,
	IconComponent,
	Selector,
} from "../../../../common/components";
import {
	isSegFeatureEnabled,
	SegFlags,
	translate,
	translateEnumProjectRoleDescription,
} from "../../../../common/providers";
import { NavUtils } from "../../../../navigation";
import { STATUSES } from "../../constants/constants";
import styles from "./MembersListRow.module.css";

export default function MembersListRow({
	allowAddRights = false,
	allowChangeRole = false,
	allowSendInvitation,
	isCurrentUser = false,
	onChangeLock,
	onChangeRole,
	onSendInvitation,
	roles = [],
	selectedCompanyIsMine,
	style,
	user,
}) {
	const params = useParams();
	const selectedRole = roles[roles.findIndex((r) => r.name === user.projectRole)] || "";
	const companyId = useSelector(({ context }) => context.company.id);
	const handleChangeRole = (e) => {
		onChangeRole({ user, role: e.target.value.name });
	};
	const tooltipTitle =
		(isCurrentUser && translate("team-management.members.selector.tooltip.own-role")) ||
		(!allowChangeRole &&
			((selectedCompanyIsMine && translate("team-management.members.selector.tooltip.not-allowed")) ||
				translate("team-management.members.selector.tooltip.must-be-in-company"))) ||
		"";
	return (
		<div className={styles["members-row"]} style={style}>
			<div className={styles["members-row__name-cell"]}>
				<CustomTooltip showWhenOverflow title={user.displayName}>
					<span
						className={`${isCurrentUser ? styles["members-row__name-cell--bold"] : ""} ${
							styles["members-row__name-cell--cut"]
						}`}
						translate="no"
					>
						{user.displayName}
					</span>
				</CustomTooltip>
				<CustomTooltip showWhenOverflow title={user.email}>
					<span
						className={`${styles["members-row__name-cell--small"]} ${styles["members-row__name-cell--cut"]}`}
						translate="no"
					>
						{user.email}
					</span>
				</CustomTooltip>
			</div>
			<div className={styles["members-row__select-cell"]}>
				<CustomTooltip title={tooltipTitle}>
					<div className={styles["select-cell__selector"]}>
						{Array.isArray(roles) && roles.length > 0 && (
							<Selector
								fullWidth
								autoTranslate="no"
								defaultValue=""
								disabled={!allowChangeRole}
								field="displayName"
								items={roles}
								value={selectedRole}
								onChange={handleChangeRole}
							/>
						)}
					</div>
				</CustomTooltip>
				<CustomTooltip
					autoTranslate="no"
					title={
						(user.projectRole && translateEnumProjectRoleDescription(user.projectRole)) ||
						translate("team-management.members.no-description")
					}
				>
					<IconComponent
						className={styles["select-cell__info-icon"]}
						color="var(--color-light-grey-1)"
						icon={icon.faInfoCircle}
						size="lg"
					/>
				</CustomTooltip>
			</div>
			<div className={styles["members-row__rights-on-documents"]}>
				{user.companyId === companyId && !user.blocked && isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && (
					<CustomButtonLink
						fullWidth
						buttonClassName={styles["rights-on-documents-cell__btn"]}
						color={user.counters.rolesCount ? "secondary" : "primary"}
						disabled={!allowAddRights}
						endIcon={user.counters.rolesCount ? icon.faPen : null}
						to={NavUtils.goToRightsOnDocuments(params.projectId)}
						variant="outlined"
					>
						{user.counters.rolesCount ? (
							<>
								<I18nTranslate
									param={{
										textClassName: styles.btn__label,
										numberClassName: (allowAddRights && styles["btn__label--number"]) || "",
										rolesCount: user.counters.rolesCount,
										documentsCount: user.counters.documentsCount,
									}}
									translationKey="team-management.members.btn.rights-on-documents"
								/>
							</>
						) : (
							<span>{translate("team-management.members.btn.add-right")}</span>
						)}
					</CustomButtonLink>
				)}
			</div>
			<div className={styles["members-row__button-cell"]}>
				{allowSendInvitation && user.status === STATUSES.pending && (
					<CustomIconButton
						icon={icon.faPaperPlane}
						tooltip={translate("team-management.members.re-send-invitation.tooltip")}
						onClick={() => onSendInvitation(user.id)}
					/>
				)}
			</div>
			<div className={styles["members-row__button-cell"]}>
				{!isCurrentUser &&
					allowChangeRole &&
					((user.status !== STATUSES.pending && (
						<CustomIconButton
							icon={user.blocked ? icon.faLock : icon.faLockOpen}
							tooltip={
								user.blocked
									? translate("team-management.members.tooltip.unlock")
									: translate("team-management.members.tooltip.lock")
							}
							variant="contained"
							onClick={() => onChangeLock(user)}
						/>
					)) || (
						<CustomIconButton
							icon={icon.faTimes}
							tooltip={translate("team-management.members.revoke-invitation.tooltip")}
							variant="contained"
							onClick={() => onChangeLock(user)}
						/>
					))}
			</div>
		</div>
	);
}
