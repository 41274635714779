import { ListItem } from "@mui/material";
import React from "react";
import { CustomButton, icon, IconComponent } from "../../../../common/components";

export default function CompanyRow({ company, onClick, isSelected, displayParentIcon }) {
	return (
		<ListItem>
			<CustomButton color="secondary" variant={isSelected ? "contained" : "text"} onClick={onClick}>
				<span
					style={{
						width: "100%",
						display: "flex",
						paddingLeft: "16px",
						justifyContent: "start",
						alignItems: "center",
					}}
					translate="no"
				>
					{company.companyName || company.shortName || "UNKNOWN"}
				</span>
				{displayParentIcon ? <IconComponent icon={icon.faStar} /> : null}
			</CustomButton>
		</ListItem>
	);
}
