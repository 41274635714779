import React from "react";
import AdminUsers from "../panels/admin-users/AdminUsers";
import AdminProject from "../panels/admin-projects/AdminProject";
import AdminCompany from "../panels/admin-company/AdminCompany";
import { Flags } from "../../../common/providers";
import { icon } from "../../../common/components";

const TABS = [
	{
		tab: { icon: icon.faBuilding, title: "admin.tab.title.company-management" },
		panel: ({ companies, createBuisnessUnit }) => (
			<AdminCompany companies={companies} onCreate={createBuisnessUnit} />
		),
		flag: Flags.ADMIN_COMPANY,
	},
	{
		tab: { icon: icon.faUserFriends, title: "admin.tab.title.members-management" },
		panel: ({
			companies,
			getCompanyUsers,
			companyRoles,
			onAddUser,
			onChangeRole,
			onChangeLockCompany,
			companyIdToUser,
		}) => (
			<AdminUsers
				companies={companies}
				COMPANY_ROLES={companyRoles}
				companyIdToUser={companyIdToUser}
				getCompanyUsers={getCompanyUsers}
				onAddUser={onAddUser}
				onChangeLock={onChangeLockCompany}
				onChangeRole={onChangeRole}
			/>
		),
	},
	{
		tab: { icon: icon.faCompassDrafting, title: "admin.tab.title.projects-management" },
		panel: ({ companies, getProjects, onChangeArchive, onChangeName, companyIdToProject }) => (
			<AdminProject
				companies={companies}
				companyIdToProject={companyIdToProject}
				getCompanyProjects={getProjects}
				onChangeArchive={onChangeArchive}
				onChangeName={onChangeName}
			/>
		),
	},
];

export default TABS;
