import React, { useState } from "react";
import { TextField } from "@mui/material";
import { BlockTitle, CustomButton, createNotification, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import DSICompanyTable from "./DSICompanyTable";
import styles from "./DSICompany.module.css";
import { BackOfficeService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { downloadFile } from "../../../../common/utils";

export default function DSICompany({ companies = [], onCreate, onUpdate }) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [payload, setPayload] = useState({ shortName: "", name: "", type: "COMPANY" });
	const [errors, setErrors] = useState({ shortName: false, name: false, type: false });
	const { call: importTemplateThematicsFromCompany } = useApi(BackOfficeService.importTemplateThematicsFromCompany);
	const { call: exportTemplateThematicsFromCompany } = useApi(BackOfficeService.exportTemplateThematicsFromCompany);

	const onChange = (value, field) => {
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
			setPayload((prev) => ({ ...prev, [field]: value }));
		}
	};

	const reset = () => {
		setPayload({ shortName: "", name: "", type: "COMPANY" });
		setErrors({ shortName: false, name: false, type: false });
	};

	const onSubmit = () => {
		const errs = {
			name: !payload.name,
			shortName: !payload.shortName,
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (hasError) {
			setErrors(errs);
			return;
		}
		if (selectedCompany) {
			onUpdate(selectedCompany.id, payload);
			setSelectedCompany(null);
		} else {
			onCreate(payload);
		}
		reset();
	};
	const handleChangeCompany = (company) => {
		if (selectedCompany && selectedCompany.id === company.id) {
			setSelectedCompany(null);
			reset();
		} else {
			setSelectedCompany(company);
			setPayload({ shortName: company.shortName, name: company.companyName, type: "COMPANY" });
			setErrors({ shortName: false, name: false, type: false });
		}
	};
	const handleImportTemplates = (event) => {
		const targetFiles = event.target.files;
		const files = Object.values(targetFiles);
		if (selectedCompany?.id && files.length > 0) {
			importTemplateThematicsFromCompany({ companyId: selectedCompany?.id }, { file: files[0] })
				.then(() =>
					createNotification({
						type: "success",
						message: translate("dsi.dsi-company.category-templating.import.success-notification"),
					})
				)
				.catch(console.error);
		}
	};
	const handleExportTemplates = () => {
		if (selectedCompany?.id) {
			exportTemplateThematicsFromCompany({ companyId: selectedCompany?.id })
				.then(({ data, filename }) => {
					downloadFile({ data, filename, filetype: "json" });
					createNotification({
						type: "success",
						message: translate("dsi.dsi-company.category-templating.download.success-notification"),
					});
				})
				.catch(console.error);
		}
	};
	return (
		<>
			<div
				style={{
					width: "50%",
					borderRight: "1px solid var(--color-light-grey-3)",
					display: "flex",
					flexDirection: "column",
				}}
				translate="no"
			>
				<BlockTitle>{translate("dsi.dsi-company.title")}</BlockTitle>
				<div style={{ overflowY: "auto", height: "inherit" }}>
					<DSICompanyTable
						companies={companies}
						selectedCompany={selectedCompany}
						onSelectRow={handleChangeCompany}
					/>
				</div>
			</div>
			<div style={{ width: "50%", borderLeft: "1px solid var(--color-light-grey-3)" }} translate="no">
				<BlockTitle autoTranslate="no">
					{translate(
						(selectedCompany && "dsi.dsi-company.company.update") || "dsi.dsi-company.company.create",
						{ companyName: selectedCompany?.companyName }
					)}
				</BlockTitle>
				<div style={{ display: "flex", flexDirection: "column", margin: "16px" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							flexGrow: 1,
							margin: "16px auto",
							width: "80%",
						}}
					>
						<TextField
							required
							error={errors && errors.shortName}
							helperText={errors && errors.shortName && "Required"}
							label={translate("dsi.dsi-company.th.shortname")}
							translate="no"
							value={payload.shortName || ""}
							variant="outlined"
							onChange={(e) => onChange(e.target.value, "shortName")}
						/>
						<span style={{ margin: "8px" }} />
						<TextField
							required
							error={errors && errors.name}
							helperText={errors && errors.name && "Required"}
							label={translate("dsi.dsi-company.th.name")}
							value={payload.name || ""}
							variant="outlined"
							onChange={(e) => onChange(e.target.value, "name")}
						/>
						<span style={{ margin: "8px" }} />
						<CustomButton fullWidth color="primary" variant="contained" onClick={onSubmit}>
							{translate("common:btn.submit")}
						</CustomButton>
						{selectedCompany && (
							<div className={styles.templating}>
								<div className={styles.textTitle}>
									<span>{translate("dsi.dsi-company.category-templating")}</span>
								</div>
								<div>
									<CustomButton
										color="primary"
										component="label"
										size="md"
										startIcon={icon.faFileImport}
										variant="outlined"
									>
										<input hidden accept=".json" type="file" onChange={handleImportTemplates} />
										{translate("dsi.dsi-company.category-templating.import")}
									</CustomButton>
								</div>
								<div>
									<CustomButton
										color="primary"
										size="md"
										startIcon={icon.faDownload}
										variant="outlined"
										onClick={handleExportTemplates}
									>
										{translate("dsi.dsi-company.category-templating.download")}
									</CustomButton>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
