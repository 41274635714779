import client from "../../client/client";
import { encodeParam } from "../../utils/utils";

class PdfViewService {
	search({ documentId }, search, cancelToken) {
		return client.get(`/smartview/pdf/documents/${documentId}`, {
			params: { filter: encodeParam(search) },
			config: { cancelToken },
		});
	}

	searchInWholeProject({ projectId }, search, cancelToken) {
		return client.get(`/smartview/pdf/projects/${projectId}`, {
			params: { filter: encodeParam(search) },
			config: { cancelToken },
		});
	}

	autoComplete({ projectId }, input, cancelToken) {
		return client.get(`/searchcard/projects/${projectId}/auto-complete`, {
			params: { input: encodeParam(input) },
			config: { cancelToken },
		});
	}
}

export default new PdfViewService();
