import { createSlice } from "@reduxjs/toolkit";
import { attachInformationCategory, getCategoryInformation, getCategoriesTree } from "./gonogo-thunks";
import { updateInformationCounter, updateNode } from "./gonogo-utils";

const initialState = {
	commentCount: 0,
	categories: [],
	currentCategory: null,
	defaultCurrentCategory: null,
	selectedMode: 0,
	isLoadingCategories: true,
	isLoadingContent: false,
	isAnalysisInProgress: false,
	results: {},
	search: "",
};
const gonogoSlice = createSlice({
	name: "gonogo",
	initialState,
	reducers: {
		setCategoryNote: (state, { payload }) => ({
			...state,
			currentCategory: { ...state.currentCategory, note: payload },
		}),
		setOwnComment: (state, { payload }) => ({ ...state, ownComment: payload }),
		setIsAnalysisInProgress: (state, { payload }) => ({ ...state, isAnalysisInProgress: payload }),
		setCommentCount: (state, { payload }) => ({ ...state, commentCount: payload }),
		setIsLoadingContent: (state, { payload }) => ({ ...state, isLoadingContent: payload }),
		setSelectedMode: (state, { payload }) => ({ ...state, selectedMode: payload, results: {} }),
		setCurrentCategory: (state, { payload }) => ({
			...state,
			results: {},
			currentCategory: payload,
			selectedMode: 0,
			search: "",
		}),
		setSearch: (state, { payload }) => ({ ...state, search: payload, results: {} }),
		updateTreeNode: (state, { payload }) => {
			const { id, updates } = payload;
			return {
				...state,
				categories:
					(id && updateNode({ id, root: state.categories, updates })) ||
					updateNode({ root: state.categories, updateArray: payload }),
			};
		},
		updateCommentsCounter: (state, { payload }) => ({
			...state,
			results: {
				...state.results,
				contents: updateInformationCounter({
					informationId: payload.informationId,
					informations: state.results.contents,
				}),
			},
		}),
		deleteCategoryInformation: (state, { payload }) => ({
			...state,
			results: {
				...state.results,
				contents: state.results.contents.filter((ct) => ct.informationId !== payload.informationId),
			},
		}),

		updateFlag: (state, { payload }) => {
			state.results.contents.find((t) => t.informationId === payload.informationId).attached = payload.flag;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCategoriesTree.pending, (state) => ({ ...state, isLoadingCategories: true }));
		builder.addCase(getCategoriesTree.fulfilled, (state, action) => {
			const { payload } = action;
			return {
				...state,
				categories: payload,
				defaultCurrentCategory: payload[0],
				isLoadingCategories: false,
			};
		});
		builder.addCase(getCategoriesTree.rejected, (state) => ({ ...state, isLoadingCategories: false }));
		builder.addCase(getCategoryInformation.fulfilled, (state, action) => {
			const { contents, hasMore, pageNumber, totalPages, totalElements } = action.payload;
			const newContents = state.results?.contents || [];
			return {
				...state,
				results: {
					contents: !pageNumber ? contents : [...newContents, ...contents],
					hasMore,
					pageNumber,
					totalPages,
					totalElements,
				},
				isLoadingContent: false,
			};
		});
		builder.addCase(getCategoryInformation.pending, (state) => ({ ...state, isLoadingContent: true }));
		builder.addCase(getCategoryInformation.rejected, (state) => ({
			...state,
			isLoadingContent: false,
		}));
		builder.addCase(attachInformationCategory.fulfilled, (state, action) => {
			const { payload } = action.meta.arg;
			return {
				...state,
				currentCategory: {
					...state.currentCategory,
					attachedContentsCount: state.currentCategory.attachedContentsCount + (payload ? 1 : -1),
				},
			};
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export { attachInformationCategory, getCategoryInformation, getCategoriesTree };
export const {
	setCategoryNote,
	deleteCategoryInformation,
	updateCommentsCounter,
	setCommentCount,
	setCurrentCategory,
	setSelectedMode,
	setIsLoadingCategories,
	setIsLoadingContent,
	setOwnComment,
	setSearch,
	setIsAnalysisInProgress,
	updateTreeNode,
	updateFlag,
} = gonogoSlice.actions;
export default gonogoSlice.reducer;
