import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from "@mui/lab";
import React, { useEffect, useRef, useState } from "react";
import { ApiService, DocumentService, ExportService } from "../../../../../../api";
import {
	CircularLoader,
	createNotification,
	CustomButton,
	icon,
	IconComponent,
	SidePanelContainer,
} from "../../../../../../common/components";
import { useApi } from "../../../../../../common/hooks";
import { hasPermission, translate, translateDate } from "../../../../../../common/providers";
import permissions from "../../../../../../common/providers/permissions/permissions";
import { downloadFile, getIconFromFiletype } from "../../../../../../common/utils";
import styles from "./DocumentCenterVersionTab.module.css";

export default function DocumentCenterVersionTab({ id: docId, onAddVersion, type, isOngoing }) {
	const { call, cancel, isLoading } = useApi(DocumentService.getVersions);
	const [versions, setVersions] = useState([]);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		if (!docId) {
			return null;
		}
		call({ docId }).then(setVersions).catch(console.error);
		return () => {
			cancel();
		};
	}, [docId, call, cancel]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleExport = (documentId) => {
		createNotification({ message: translate("document-center.side-panel.versions.notif-exporting") });
		ExportService.exportVersioning({ docId: documentId }, cancelTokenSourceRef.current.token)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "xlsx" }))
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<SidePanelContainer className={styles.tab}>
			<div className={styles.tab__header}>
				<span className={styles.tab__title}>{translate("document-center.side-panel.versions")}</span>
				{hasPermission(permissions.PROJECT_LEADER, permissions.PROJECT_MANAGER) && (
					<span>
						<input
							hidden
							accept=".doc,.docx,.odt,.pdf,.rtf,.xls,.xlsm,.xlsx,.zip"
							disabled={isOngoing}
							id="import"
							name="import"
							type="file"
							onChange={onAddVersion}
						/>
						<label htmlFor="import">
							<CustomButton
								color={!isOngoing && "primary"}
								component="span"
								disabled={isOngoing}
								variant="outlined"
							>
								{translate("document-center.side-panel.versions.add")}
							</CustomButton>
						</label>
					</span>
				)}
			</div>
			{isLoading && (
				<div className={styles.tab__content}>
					<CircularLoader />
				</div>
			)}
			{!isLoading && (
				<Timeline align="left" className={styles.timeline}>
					{Array.isArray(versions) &&
						versions.map((version, index) => (
							<TimelineItem key={version.id}>
								<TimelineOppositeContent>
									<span
										className={`${styles.timeline_date} ${
											(index === 0 && styles["timeline_date--current"]) || ""
										}`}
									>
										{translateDate(version.uploaded)}
									</span>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.timeline__dot} />
									<TimelineConnector className={styles.timeline__connector} />
								</TimelineSeparator>
								<TimelineContent>
									<span className={styles.content__main}>
										<IconComponent
											color="var(--color-light-grey-2)"
											icon={icon[getIconFromFiletype(type)]}
											size="md"
										/>
										<span
											className={`${styles.content__name} ${
												(index === 0 && styles["content__name--current"]) || ""
											}`}
										>
											{version.name}
										</span>
									</span>
									<span className={styles.content__filename}>{version.filename}</span>
									{versions.length > 1 && index < versions.length - 1 && (
										<CustomButton
											className={styles.button}
											color="secondary"
											iconColor="var(--color-dark-grey-1)"
											startIcon={icon.faFileExport}
											variant="outlined"
											onClick={() => handleExport(version.id)}
										>
											{translate("document-center.side-panel.versions.export")}
										</CustomButton>
									)}
								</TimelineContent>
							</TimelineItem>
						))}
				</Timeline>
			)}
		</SidePanelContainer>
	);
}
