import { createSlice } from "@reduxjs/toolkit";
import { getPage, getMetadata, getDeletedData } from "./pdf-thunks";
import { checksForCounterUpdate, updateMetadataNode } from "../utils";

const initialState = {
	content: [],
	page: 0,
	zoom: 0,
	position: null,
	scrollTo: 0,
	selectedMenu: 0,
	pagesUpdated: [],
	isScrolling: true,
	firstPage: 0,
	tables: [],
};

const srPdfSlice = createSlice({
	name: "srPdf",
	initialState,
	reducers: {
		setFirstPage: (state, { payload }) => ({ ...state, firstPage: payload }),
		setIsScrolling: (state, { payload }) => ({ ...state, isScrolling: payload }),
		resetPdf: (state, { payload }) => {
			const { page, position } = payload || {};
			const additional =
				(page &&
					position && {
						page: page - 1,
						position: state.position === position ? position + 0.0001 : position,
						positionPage: page - 1,
						scrollTo: page - 1,
					}) ||
				{};
			return {
				...state,
				...initialState,
				zoom: state.zoom,
				selectedMenu: state.selectedMenu,
				...additional,
			};
		},
		resetContent: (state, { payload }) => ({
			...state,
			reloadingPages: payload.pages,
			hasCounterUpdate: true,
			content: state.content.map((c) =>
				payload.pages.some((x) => x === c.page) ? { ...c, [payload.mode]: [] } : c
			),
		}),
		setPosition: (state, { payload }) => ({ ...state, position: payload }),
		setPage: (state, { payload }) => ({ ...state, page: payload }),
		setScrollTo: (state, { payload }) => {
			const { page, position } = payload;
			if (position) {
				return {
					...state,
					page: page - 1,
					position: state.position === position ? position + 0.0001 : position,
					positionPage: page - 1,
					isScrolling: false,
				};
			}
			return { ...state, page: page - 1, scrollTo: page - 1, isScrolling: false };
		},
		setSelectedMenu: (state, { payload }) => ({ ...state, selectedMenu: payload }),
		setZoom: (state, { payload }) => ({ ...state, zoom: payload }),
		updateNode: (state, { payload }) => ({
			...state,
			content: updateMetadataNode(state.content, payload),
			hasCounterUpdate: checksForCounterUpdate(payload?.updates),
		}),
		setHasCounterUpdate: (state, { payload }) => ({ ...state, hasCounterUpdate: payload }),
		setHasQuickAccessUpdate: (state, { payload }) => ({ ...state, hasQuickAccessUpdate: payload }),
		setHasVersioningUpdate: (state, { payload }) => ({ ...state, hasVersioningUpdate: payload }),
		addPageToPagesUpdated: (state, { payload }) => {
			if (!state.pagesUpdated.includes(payload)) {
				return { ...state, pagesUpdated: [...state.pagesUpdated, payload] };
			}
			return state;
		},
		resetPagesUpdated: (state) => ({ ...state, pagesUpdated: [] }),
	},
	extraReducers: (builder) => {
		builder.addCase(getPage.pending, (state, action) => {
			const { page } = action.meta.arg;
			return {
				...state,
				content: [...state.content, { page, isLoading: true }],
			};
		});
		builder.addCase(getPage.fulfilled, (state, action) => {
			const { page } = action.meta.arg;
			const { payload } = action;
			const blobImage = URL.createObjectURL(payload);
			return {
				...state,
				content: state.content.map((c) =>
					c.page === page ? { ...c, page, image: blobImage, isLoading: false } : c
				),
			};
		});
		builder.addCase(getPage.rejected, (state, action) => {
			const { page } = action.meta.arg;
			return {
				...state,
				content: state.content.map((c) =>
					c.page === page ? { ...c, page, image: null, isLoading: false } : c
				),
			};
		});
		builder.addCase(getMetadata.fulfilled, (state, action) => {
			const { page, mode } = action.meta.arg;
			const { payload } = action;
			const updatedTables = payload.tables.map((table) => ({
				...table,
				page,
			}));
			return {
				...state,
				reloadingPages: [],
				content: state.content.map((c) => (c.page === page ? { ...c, [mode]: payload.informations } : c)),
				tables: [...state.tables, ...updatedTables],
			};
		});
		builder.addCase(getMetadata.rejected, (state) => ({
			...state,
		}));
		builder.addCase(getDeletedData.fulfilled, (state, action) => {
			const { page } = action.meta.arg;
			const { payload } = action;
			const deletedList = payload.map((x) => ({ deletedInfosIds: x.deletedInfosIds, ...x.infoDTO }));
			return {
				...state,
				reloadingPages: [],
				content: state.content.map((c) => (c.page === page ? { ...c, deletedData: deletedList } : c)),
			};
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export { getPage, getMetadata, getDeletedData };
export const {
	setFirstPage,
	resetContent,
	resetPdf,
	setHasCounterUpdate,
	setHasQuickAccessUpdate,
	setHasVersioningUpdate,
	addPageToPagesUpdated,
	resetPagesUpdated,
	setPage,
	setScrollTo,
	setSelectedMenu,
	setZoom,
	updateNode,
	setPosition,
	setIsScrolling,
} = srPdfSlice.actions;
export default srPdfSlice.reducer;
