import React, { useEffect, useState } from "react";
import { DocumentService } from "../../../../../../api";
import { CircularLoader } from "../../../../../../common/components";
import { useApi } from "../../../../../../common/hooks";
import { translate, translateDate } from "../../../../../../common/providers";
import styles from "./FileInformation.module.css";
import { FILE_TYPES } from "../../../../../../common/constants/file-types";

const rows = [
	{ label: "document-center.side-panel.information.file.document", valueRenderer: ({ name }) => name },
	{ label: "document-center.side-panel.information.file.filename", valueRenderer: ({ filename }) => filename },
	{
		label: "document-center.side-panel.information.file.type",
		valueRenderer: ({ fileType, name }) => {
			if (fileType !== FILE_TYPES.UNSUPPORTED) {
				return fileType;
			}
			return name?.split(".").pop() || "";
		},
	},
	{ label: "document-center.side-panel.information.file.location", valueRenderer: ({ location }) => location },
	{ label: "document-center.side-panel.information.file.pages", valueRenderer: ({ totalPages }) => totalPages },
	{ label: "document-center.side-panel.information.file.requirements", valueRenderer: ({ totalReqs }) => totalReqs },
	{ label: "document-center.side-panel.information.file.version", valueRenderer: ({ version }) => version },
	{
		label: "document-center.side-panel.information.file.previous-version",
		valueRenderer: ({ previousVersionName }) => previousVersionName || "-",
	},
	{
		label: "document-center.side-panel.information.file.uploaded",
		valueRenderer: ({ uploaded }) => translateDate(uploaded),
	},
	{ label: "document-center.side-panel.information.file.uploaded-by", valueRenderer: ({ username }) => username },
];
const PREVIEW_HEIGHT = 300;

export default function FileInformation({ docId, isOngoing = false, unsupported = false }) {
	const {
		call: getInformation,
		cancel: cancelGetInformation,
		isLoading: isLoadingInformation,
	} = useApi(DocumentService.getInformation);
	const { call: getPage, cancel: cancelGetPage, isLoading: isLoadingPage } = useApi(DocumentService.getPage);
	const [information, setInformation] = useState(null);
	const [image, setImage] = useState(null);

	useEffect(() => {
		if (!docId) {
			return null;
		}
		getInformation({ docId }).then(setInformation).catch(console.error);
		return () => {
			cancelGetInformation();
		};
	}, [docId, getInformation, cancelGetInformation]);

	useEffect(() => {
		if (!docId || unsupported || isOngoing) {
			return null;
		}
		getPage({ docId }, { page: 1 })
			.then((data) => {
				if (!data) {
					return;
				}
				const im = new Image();
				const blobImage = URL.createObjectURL(data);
				im.src = blobImage;
				setImage(im.src);
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			cancelGetPage();
		};
	}, [docId, isOngoing, unsupported, getPage, cancelGetPage]);

	const isLoading = isLoadingInformation || isLoadingPage;
	return (
		(!isLoading && (
			<div className={styles.file__container}>
				<div className={styles.file__information}>
					{information &&
						rows.map((row) => {
							if (unsupported && (row.label.includes("pages") || row.label.includes("requirements"))) {
								return null;
							}
							return (
								<div key={row.label} className={styles.file__row}>
									<span className={styles.row__label}>{translate(row.label)}</span>
									<div className={styles.row_value_column}>
										<span className={styles.row__value}>{row.valueRenderer(information)}</span>
									</div>
								</div>
							);
						})}
				</div>
				<div className={styles.file__iconContainer}>
					{image && <img alt={`img_${1}`} height={PREVIEW_HEIGHT} src={image} />}
				</div>
			</div>
		)) || <CircularLoader />
	);
}
