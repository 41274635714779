import React from "react";
import { CellMeasurer } from "react-virtualized";
import { SearchRow } from "../../../../../common/components";
import {
	Flags,
	hasCompanyPermission,
	hasPermission,
	isFeatureEnabled,
	Permissions,
} from "../../../../../common/providers";
import styles from "./ResultRow.module.css";

export default function ResultRow({
	cache,
	checkedRows,
	disableActions,
	index,
	onCheckRow,
	onDisplay,
	onRefreshHeight,
	parent,
	result,
	style,
}) {
	const {
		attributes,
		thematic,
		clause,
		content,
		documentId,
		documentName,
		informationId,
		page,
		reqId,
		type,
		location,
		documentFilename,
	} = result;
	return (
		<CellMeasurer cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
			{({ registerChild }) => (
				<div ref={registerChild} style={style}>
					<div className={styles.result__row}>
						<SearchRow
							attributes={attributes}
							categories={thematic}
							clause={clause}
							content={content}
							documentFilename={documentFilename}
							documentId={documentId}
							documentName={documentName}
							informationId={informationId}
							informationType={type}
							isCheckboxDisplayed={
								!disableActions &&
								isFeatureEnabled(Flags.SEARCHCARD_ACTIONS) &&
								hasPermission(Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER) &&
								hasCompanyPermission(Permissions.PROJECT_LEADER)
							}
							isChecked={checkedRows.some((row) => row.informationId === informationId)}
							location={location}
							page={page}
							reqId={reqId}
							row={result}
							onCheck={onCheckRow}
							onDisplay={onDisplay}
							onRefreshHeight={() => onRefreshHeight(index)}
						/>
					</div>
				</div>
			)}
		</CellMeasurer>
	);
}
