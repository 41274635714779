import React from "react";
import {
	icon,
	CustomButton,
	CustomTooltip,
	MultipleProgressBar,
	CustomIconButton,
} from "../../../../../../common/components";
import { Flags, isFeatureEnabled, isSegFeatureEnabled, SegFlags, translate } from "../../../../../../common/providers";
import { FLAGS } from "../../../../../../common/providers/feature-flag-provider";
import styles from "./SentenceManagement.module.css";
import { SOURCE_TYPES } from "../../../../../../common/constants";
import { isNonEmptyObject } from "../../../../../../common/utils";

const SentenceManagementButton = ({ count, "data-testid": dataTestId = null, iconName, onClick, tooltip }) => (
	<CustomTooltip placement="top" title={tooltip}>
		<CustomIconButton
			btnClassName={styles.button}
			color={count > 0 ? "primary" : "secondary"}
			data-testid={dataTestId}
			icon={iconName}
			size="sm"
			onClick={onClick}
		/>
	</CustomTooltip>
);

export default function SentenceManagement({
	categoriesCount,
	commentsCount,
	deliverablesCount,
	float,
	hasCommentsOrCategoriesOrLinks,
	isHovered,
	isRequirement,
	onOpenSidePanel,
	questionAnswer,
	reviewCounters = {},
	tasksCount,
	linkTypeCount,
}) {
	return (
		<div style={{ float }}>
			{(isRequirement && (
				<>
					{isFeatureEnabled(FLAGS.SR_PANEL_POSITIONS) &&
						isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && (
							<CustomTooltip
								placement="top"
								title={translate("common:filters.select-filter.menus.positions")}
							>
								<CustomButton
									className={`${styles.button} ${styles.positionButton}`}
									color="secondary"
									data-testid={`smart-review.${SOURCE_TYPES.POSITIONS}.btn`}
									variant="outlined"
									onClick={() => onOpenSidePanel(SOURCE_TYPES.POSITIONS)}
								>
									<span>
										<div className={styles.figure}>
											{(isNonEmptyObject(reviewCounters) && reviewCounters.total) || 1}
										</div>
										<MultipleProgressBar
											hideFigures
											className={styles.positionsCounter}
											colors={[
												"var(--color-light-grey-1)",
												"var(--color-green)",
												"var(--color-orange)",
												"var(--color-red)",
												"var(--color-black)",
											]}
											progresses={[
												(isNonEmptyObject(reviewCounters) && reviewCounters.unreviewed) || 1,
												reviewCounters.yes,
												reviewCounters.pending,
												reviewCounters.no,
												reviewCounters.notMe,
											]}
										/>
									</span>
								</CustomButton>
							</CustomTooltip>
						)}
					<SentenceManagementButton
						count={commentsCount}
						data-testid={`smart-review.${SOURCE_TYPES.COMMENTS}.btn`}
						iconName={icon.faComments}
						tooltip={translate("smart-review.cmp-details.option-panel.comments")}
						onClick={() => onOpenSidePanel(SOURCE_TYPES.COMMENTS)}
					/>
					{isSegFeatureEnabled(SegFlags.CATEGORY) && (
						<SentenceManagementButton
							count={categoriesCount}
							data-testid={`smart-review.${SOURCE_TYPES.CATEGORY}.btn`}
							iconName={icon.faTag}
							tooltip={translate("smart-review.cmp-details.option-panel.categories")}
							onClick={() => onOpenSidePanel(SOURCE_TYPES.CATEGORY)}
						/>
					)}
					{isSegFeatureEnabled(SegFlags.TASK) && (
						<SentenceManagementButton
							count={tasksCount}
							data-testid={`smart-review.${SOURCE_TYPES.TASKS}.btn`}
							iconName={icon.faClipboardList}
							tooltip={translate("smart-review.cmp-details.option-panel.tasks")}
							onClick={() => onOpenSidePanel(SOURCE_TYPES.TASKS)}
						/>
					)}
					{isSegFeatureEnabled(SegFlags.QUESTION_ANSWER) && (
						<SentenceManagementButton
							count={questionAnswer}
							data-testid={`smart-review.${SOURCE_TYPES.QUESTIONS}.btn`}
							iconName={icon.faHandPaper}
							tooltip={translate("smart-review.cmp-details.option-panel.questions-tab.title")}
							onClick={() => onOpenSidePanel(SOURCE_TYPES.QUESTIONS)}
						/>
					)}
					{isSegFeatureEnabled(SegFlags.DELIVERABLES) && (
						<SentenceManagementButton
							count={deliverablesCount}
							data-testid={`smart-review.${SOURCE_TYPES.DELIVERABLES}.btn`}
							iconName={icon.faFileSignature}
							tooltip={translate("smart-review.cmp-details.option-panel.deliverables")}
							onClick={() => onOpenSidePanel(SOURCE_TYPES.DELIVERABLES)}
						/>
					)}
					{isFeatureEnabled(Flags.INFORMATION_LINKS) && (
						<SentenceManagementButton
							count={isNonEmptyObject(linkTypeCount)}
							data-testid={`smart-review.${SOURCE_TYPES.LINK_BETWEEN_CONTENTS}.btn`}
							iconName={icon.faLink}
							tooltip={translate("common:filters.select-filter.menus.link-types")}
							onClick={() => onOpenSidePanel(SOURCE_TYPES.LINK_BETWEEN_CONTENTS)}
						/>
					)}
				</>
			)) ||
				((isHovered || hasCommentsOrCategoriesOrLinks) && (
					<>
						<SentenceManagementButton
							count={commentsCount}
							data-testid={`smart-review.${SOURCE_TYPES.COMMENTS}.btn`}
							iconName={icon.faComments}
							tooltip={translate("smart-review.cmp-details.option-panel.comments")}
							onClick={() => onOpenSidePanel(SOURCE_TYPES.COMMENTS)}
						/>
						{isSegFeatureEnabled(SegFlags.CATEGORY) && (
							<SentenceManagementButton
								count={categoriesCount}
								data-testid={`smart-review.${SOURCE_TYPES.CATEGORY}.btn`}
								iconName={icon.faTag}
								tooltip={translate("smart-review.cmp-details.option-panel.categories")}
								onClick={() => onOpenSidePanel(SOURCE_TYPES.CATEGORY)}
							/>
						)}
						{isSegFeatureEnabled(SegFlags.QUESTION_ANSWER) && (
							<SentenceManagementButton
								count={questionAnswer}
								data-testid={`smart-review.${SOURCE_TYPES.QUESTIONS}.btn`}
								iconName={icon.faHandPaper}
								tooltip={translate("smart-review.cmp-details.option-panel.questions-tab.title")}
								onClick={() => onOpenSidePanel(SOURCE_TYPES.QUESTIONS)}
							/>
						)}
						{isFeatureEnabled(Flags.INFORMATION_LINKS) && (
							<SentenceManagementButton
								count={isNonEmptyObject(linkTypeCount)}
								data-testid={`smart-review.${SOURCE_TYPES.LINK_BETWEEN_CONTENTS}.btn`}
								iconName={icon.faLink}
								tooltip={translate("common:filters.select-filter.menus.link-types")}
								onClick={() => onOpenSidePanel(SOURCE_TYPES.LINK_BETWEEN_CONTENTS)}
							/>
						)}
					</>
				))}
		</div>
	);
}
